import React from 'react';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../../constants/colors';
import { Iconly } from 'react-iconly';

interface IProps {
  rating?: number | null;
  onChange?: (rating: number) => void;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}

const useStyles = makeStyles((theme) => ({
  star: {
    cursor: 'pointer',
    height: 'fit-content',
  },
  block: {
    display: 'flex',
  },
}));

const Rating: React.FC<IProps> = ({ rating, onChange, size }) => {
  const classes = useStyles();

  return (
    <div className={classes.block}>
      {Array(5)
        .fill('')
        .map((_, index) => (
          <div
            key={index}
            className={onChange ? classes.star : ''}
            onClick={onChange ? () => onChange(index + 1) : undefined}
          >
            <Iconly
              key={index}
              name={'Star'}
              set={rating && rating >= index + 1 ? 'bold' : 'light'}
              primaryColor={colors.primary.yellow}
              size={size || 'medium'}
            />
          </div>
        ))}
    </div>
  );
};

export default Rating;
