import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as Yup from 'yup';

import { Button } from '../../../../components/base/Button';
import { colors } from '../../../../constants/colors';

import { actions as authActions } from '../../../../store/ducks/auth.duck';
import { IAppState } from '../../../../store/rootDuck';
import { SmsInput } from '../../../../components/base/SmsInput';

const ForgotPassword: React.FC<WrappedComponentProps & TPropsFromRedux> = ({
  intl,
  loginEmailError,
  loginEmailLoading,
  loginByEmail,
  loginEmailSuccess,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (loginEmailSuccess) {
      sessionStorage.removeItem('__email__');
      history.replace('/');
    }
  }, [loginEmailSuccess]);
  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.EMAIL.CONFIRMATION.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{ code: '' }}
          validationSchema={Yup.object().shape({
            code: Yup.string(),
          })}
          onSubmit={(values) => {
            loginByEmail({ email: sessionStorage.getItem('__email__') || '', code: values.code });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="kt-form">
              <div style={{ margin: '35px 10px 10px' }}>
                <div style={{ color: colors.primary.green, fontWeight: 600 }}>
                  {intl.formatMessage({
                    id: 'AUTH.EMAIL.CONFIRMATION',
                  })}
                </div>
              </div>

              <div className="form-group" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <SmsInput
                  style={{ marginTop: 16 }}
                  name={'code'}
                  value={values.code}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value && e.target.value.length === 4) {
                      handleSubmit();
                    }
                  }}
                />
              </div>
              <div className="kt-login__actions">
                <Grid container direction="row" justify="space-between" alignItems="stretch">
                  <Button
                    type="button"
                    outline
                    onClick={() => history.goBack()}
                    text={intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                  />
                </Grid>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    recoveryPasswordLoading: state.auth.recoveryPasswordLoading,
    recoveryPasswordSuccess: state.auth.recoveryPasswordSuccess,
    recoveryPasswordErrors: state.auth.recoveryPasswordErrors,
    loginEmailSuccess: state.auth.loginEmailSuccess,
    loginEmailError: state.auth.loginEmailErrors,
    loginEmailLoading: state.auth.loginEmailLoading,
  }),
  {
    clearRecoveryPassword: authActions.clearRecoveryPassword,
    recoveryPasswordRequest: authActions.recoveryPasswordRequest,
    loginByEmail: authActions.loginEmail,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(ForgotPassword));
