import axios from 'axios';
import {ICollectionPlace} from '../interfaces/collections';

export function createCollection(data: FormData) {
  return axios.post('/api/shop/collection', data);
}
export function editCollection(id: number | string, data: FormData) {
  return axios.post(`/api/shop/collection/${id}`, data);
}
export function deleteCollection(id: number | string) {
  return axios.delete(`/api/shop/collection/${id}`);
}
export function getCollections() {
  return axios.get('/api/shop/collections');
}
export function geteCollectionsByPlace(
  place: ICollectionPlace
) {
  return axios.get(`/api/shop/collections?${place}=true`);
}
export function getCollection(id: number | string) {
  return axios.get(`/api/shop/collection/${id}`);
}
