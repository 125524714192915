import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { Tab, Tabs } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { actions as authActions } from '../../../../store/ducks/auth.duck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { a11yProps, TabPanel } from '../../../../components/old/ui/Tab/TabPanel';
import { countries, phoneCountryCodes } from '../../components/phoneCountryCodes';
import { IAppState } from '../../../../store/rootDuck';
import { LoginType, UserRoles } from '../../../../interfaces/user';
import { useSnackbar } from 'notistack';
import { Button } from '../../../../components/base/Button';
import { Input } from '../../../../components/base/Input';
import { Text, TextType } from '../../../../components/base/Text';

const CheckInSystem: React.FC<TPropsFromRedux & WrappedComponentProps> = ({
  findInSystem,
  findInSystemLoading,
  findInSystemSuccess,
  findInSystemError,
  clearFindInSystem,
  authData,
  sendCodeConfirm,
  clearSendCode,
  sendCodeSuccess,
  sendCodeError,
  login,
  clearLogin,
  loginLoading,
  loginSuccess,
  loginError,
  sendEmailConfirmCode,
  confirmCodeError,
  confirmCodeLoading,
  confirmCodeSuccess,
  clearConfirmation,
  clearMe,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [valueTabs, setValueTabs] = useState(0);
  const [countryCode] = useState(countries[0].code);
  const [countryName] = useState(phoneCountryCodes[0]);
  const { enqueueSnackbar } = useSnackbar();
  const handleTabsChange = (e: any, newValue: number) => {
    setValueTabs(newValue);
  };

  let validationSchema = {};

  if (valueTabs === 0) {
    validationSchema = {
      email: Yup.string()
        .email(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_INCORRECT' }))
        .required(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_REQUIRED' })),
    };
  }
  if (valueTabs === 1) {
    validationSchema = {
      phone: Yup.string().required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
    };
  }

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      phone: `${countryCode}`,
      codeConfirm: '',
    },
    onSubmit: (values) => {
      localStorage.setItem('partnerEmail', values.email);
      if (valueTabs === 0) {
        // findInSystem({ value: values.email, type: LoginType.email });
        sendEmailConfirmCode({ email: values.email });
      }
      if (valueTabs === 1) {
        findInSystem({
          value: values.phone,
          type: LoginType.phone,
          country_name: countryName,
          country_code: countryCode,
        });
      }
    },
    validationSchema: Yup.object().shape(validationSchema),
  });

  useEffect(() => {
    if (sendCodeSuccess || sendCodeError) {
      enqueueSnackbar(
        sendCodeSuccess
          ? 'A confirmation code has been sent to the number you specified'
          : sendCodeError,
        {
          variant: sendCodeSuccess ? 'success' : 'error',
        }
      );
      clearSendCode();
    }
  }, [enqueueSnackbar, sendCodeSuccess, sendCodeError, clearSendCode]);

  useEffect(() => {
    if (findInSystemSuccess) {
      if (authData && authData.type === LoginType.phone && valueTabs === 1) {
        sendCodeConfirm({ phone: authData.value, roles: [UserRoles.ROLE_VENDOR] });
      }
    }
  }, [findInSystemSuccess]);
  useEffect(() => {
    if (confirmCodeSuccess) {
      clearConfirmation();
      sessionStorage.setItem('__email__', values.email);
      history.push('/auth/verify-code');
    }
  }, [confirmCodeSuccess]);
  useEffect(() => {
    if (loginSuccess) {
      clearLogin();
      clearMe();
      history.replace('/');
    }
  }, [clearLogin, loginSuccess]);

  useEffect(() => {
    if (loginError) {
      enqueueSnackbar(loginError, {
        variant: 'error',
      });
      clearLogin();
    }
  }, [clearLogin, enqueueSnackbar, loginError]);

  useEffect(() => {
    if (sendCodeSuccess) {
      history.push('/auth/login');
    }
  }, [sendCodeSuccess]);

  useEffect(() => {
    if (findInSystemError || confirmCodeError) {
      clearConfirmation();
      history.push('/auth/registration');
    }
  }, [findInSystemError, confirmCodeError]);

  useEffect(() => {
    localStorage.removeItem('partnerEmail');
    return () => {
      clearFindInSystem();
    };
  }, []);

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <Text type={TextType.semiboldH5} margin={{ bottom: 24 }}>
            Sign in or Sign up in the partner's personal account
          </Text>

          <form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
            <Tabs
              value={valueTabs}
              onChange={handleTabsChange}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              aria-label="tabs"
            >
              <Tab label="BY EMAIL" {...a11yProps(0)} />
              <Tab label="BY PHONE" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={valueTabs} index={0} style={{ marginBottom: 24 }}>
              <div className="form-group">
                <Input
                  placeholder="Enter your e-mail..."
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  error={Boolean(touched.email && errors.email)}
                  errorHelper={(touched.email && errors.email) as string}
                />
                {/* <Input
                  type="login"
                  placeholder={intl.formatMessage({
                    id: 'AUTH.INPUT.LOGIN',
                  })}
                  name="login"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.login}
                  error={touched.login && errors.login ? errors.login.toString() : ''}
                />

                <Input
                  type="password"
                  placeholder={intl.formatMessage({
                    id: 'AUTH.INPUT.PASSWORD',
                  })}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  error={touched.password && errors.password ? errors.password.toString() : ''}
                /> */}
              </div>
              {/* 
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Text
                  onClick={() => history.push('/auth/forgot-password')}
                  style={{ cursor: 'pointer' }}
                  type={TextType.semiboldSubtitle2}
                  margin={{ bottom: 24 }}
                >
                  {intl.formatMessage({ id: 'AUTH.GENERAL.FORGOT_BUTTON' })}
                </Text>
              </div> */}

              <Button
                type="submit"
                style={{ marginTop: 24 }}
                text={'Next'}
                full
                disabled={findInSystemLoading || loginLoading}
                loading={confirmCodeLoading || loginLoading}
              />
            </TabPanel>

            <TabPanel value={valueTabs} index={1} style={{ marginBottom: 24 }}>
              <div className="form-group">
                <>
                  <Input
                    phone
                    name={'phone'}
                    type={'phone'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    error={touched.phone && errors.phone ? errors.phone : ''}
                    placeholder={'+61'}
                  />

                  <Button
                    style={{ marginTop: 24 }}
                    text={'Next'}
                    full
                    disabled={findInSystemLoading || loginLoading}
                    loading={findInSystemLoading || loginLoading}
                  />
                </>
              </div>
            </TabPanel>
          </form>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    loginLoading: state.auth.loginLoading,
    loginSuccess: state.auth.loginSuccess,
    loginError: state.auth.loginErrors,

    findInSystemLoading: state.auth.findInSystemLoading,
    findInSystemSuccess: state.auth.findInSystemSuccess,
    findInSystemError: state.auth.findInSystemError,

    authData: state.auth.authData,
    sendCodeSuccess: state.auth.sendCodeConfirmSuccess,
    sendCodeError: state.auth.sendCodeConfirmError,

    confirmCodeLoading: state.auth.sendCodeLoading,
    confirmCodeError: state.auth.sendCodeError,
    confirmCodeSuccess: state.auth.sendCodeSuccess,
  }),
  {
    findInSystem: authActions.findInSystemRequest,
    clearFindInSystem: authActions.clearFindInSystem,
    sendCodeConfirm: authActions.sendCodeRequest,
    clearSendCode: authActions.clearSendCode,
    login: authActions.loginRequest,
    clearLogin: authActions.clearLogin,
    sendEmailConfirmCode: authActions.sendEmailCode,
    clearConfirmation: authActions.clearConfirmCode,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CheckInSystem);
