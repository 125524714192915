import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ICategoryItem } from '../../pages/home/categories/interfaces';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  createCategory,
  deleteCategory,
  editCategory,
  getCategoriesWithChildren,
  getCategoriesWithParent,
  getCategoryById,
  getFullCategories,
  getRootCategories,
  setCategoriesPositions,
} from '../../crud/categories.crud';

const CLEAR_CATALOG_CATEGORIES = 'categories/CLEAR_CATALOG_CATEGORIES';
const FETCH_CATALOG_CATEGORIES_REQUEST = 'categories/FETCH_CATALOG_CATEGORIES_REQUEST';
const FETCH_CATALOG_CATEGORIES_SUCCESS = 'categories/FETCH_CATALOG_CATEGORIES_SUCCESS';
const FETCH_CATALOG_CATEGORIES_FAIL = 'categories/FETCH_CATALOG_CATEGORIES_FAIL';

const FETCH_ROOT_REQUEST = 'categories/FETCH_ROOT_REQUEST';
const FETCH_ROOT_SUCCESS = 'categories/FETCH_ROOT_SUCCESS';
const FETCH_ROOT_FAIL = 'categories/FETCH_ROOT_FAIL';

const FETCH_CHILDREN_REQUEST = 'categories/FETCH_CHILDREN_REQUEST';
const FETCH_CHILDREN_SUCCESS = 'categories/FETCH_CHILDREN_SUCCESS';
const FETCH_CHILDREN_FAIL = 'categories/FETCH_CHILDREN_FAIL';

const FETCH_PARENT_REQUEST = 'categories/FETCH_PARENT_REQUEST';
const FETCH_PARENT_SUCCESS = 'categories/FETCH_PARENT_SUCCESS';
const FETCH_PARENT_FAIL = 'categories/FETCH_PARENT_FAIL';

const FETCH_FULL_REQUEST = 'categories/FETCH_FULL_REQUEST';
const FETCH_FULL_SUCCESS = 'categories/FETCH_FULL_SUCCESS';
const FETCH_FULL_FAIL = 'categories/FETCH_FULL_FAIL';

const FETCH_BY_ID_REQUEST = 'categories/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'categories/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'categories/FETCH_BY_ID_FAIL';

const SET_POSITIONS_REQUEST = 'categories/SET_POSITIONS_REQUEST';
const SET_POSITIONS_SUCCESS = 'categories/SET_POSITIONS_SUCCESS';
const SET_POSITIONS_FAIL = 'categories/SET_POSITIONS_FAIL';

const CLEAR_EDIT = 'categories/CLEAR_EDIT';
const ADD_REQUEST = 'categories/ADD_REQUEST';
const EDIT_REQUEST = 'categories/EDIT_REQUEST';
const EDIT_SUCCESS = 'categories/EDIT_SUCCESS';
const EDIT_FAIL = 'categories/EDIT_FAIL';
const SET_MENU_INDEX='menu/SET_MENU_INDEX';
const DELETE_REQUEST = 'categories/DELETE_REQUEST';
const DELETE_SUCCESS = 'categories/DELETE_SUCCESS';
const DELETE_FAIL = 'categories/DELETE_FAIL';

const CLEAR_ERRORS = 'categories/CLEAR_ERRORS';

const SET_HEADER_STICKY = 'header/SET_HEADER_STICKY';
export interface IInitialState {
  menuItem:string,
  page: number;
  per_page: number;
  total: number;
  categories: ICategoryItem[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  catalogCategories: ICategoryItem[];
  catalogLoading: boolean;
  catalogSuccess: boolean;
  catalogError: string | null;

  category: ICategoryItem | undefined;

  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  parent: ICategoryItem | undefined;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  deleteError: boolean;

  headerSticky?: boolean;
}

const initialState: IInitialState = {
  menuItem:'',
  page: 1,
  per_page: 20,
  total: 0,
  categories: undefined,
  loading: false,
  success: false,
  error: null,

  catalogCategories: [],
  catalogLoading: false,
  catalogSuccess: false,
  catalogError: '',

  category: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  parent: undefined,

  editLoading: false,
  editSuccess: false,
  editError: null,

  deleteError: false,

  headerSticky: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'categories', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_MENU_INDEX:{
        return{
          ...state,
          menuItem:action.payload
        };
      }
      case CLEAR_CATALOG_CATEGORIES: {
        return {
          ...state,
          catalogCategories: [],
          catalogLoading: false,
          catalogSuccess: false,
          catalogError: null,
        };
      }
      case FETCH_CATALOG_CATEGORIES_REQUEST: {
        return {
          ...state,
          catalogCategories: [],
          catalogLoading: true,
          catalogSuccess: false,
          catalogError: null,
        };
      }
      case FETCH_CATALOG_CATEGORIES_FAIL: {
        return {
          ...state,
          catalogCategories: [],
          catalogLoading: false,
          catalogSuccess: false,
          catalogError: action.payload,
        };
      }
      case FETCH_CATALOG_CATEGORIES_SUCCESS: {
        return {
          ...state,
          catalogCategories: action.payload.data,
          catalogLoading: false,
          catalogSuccess: true,
          catalogError: null,
        };
      }

      case FETCH_CHILDREN_REQUEST: {
        return {
          ...state,
          categories: undefined,
          loading: true,
          success: false,
          error: null,
          category: undefined,
          deleteError: false,
        };
      }
      case FETCH_ROOT_REQUEST:
      case FETCH_FULL_REQUEST: {
        return {
          ...state,
          categories: undefined,
          loading: true,
          success: false,
          error: null,
          category: undefined,
          parent: undefined,
          deleteError: false,
        };
      }

      case FETCH_PARENT_REQUEST: {
        return {
          ...state,
          parent: undefined,
          deleteError: false,
        };
      }

      case FETCH_ROOT_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          categories: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FULL_SUCCESS: {
        return {
          ...state,
          categories: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_CHILDREN_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          categories: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_PARENT_SUCCESS: {
        return {
          ...state,
          parent: action.payload.data,
          loading: false,
        };
      }

      case FETCH_CHILDREN_FAIL:
      case FETCH_PARENT_FAIL:
      case FETCH_ROOT_FAIL:
      case FETCH_FULL_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          category: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
          deleteError: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          category: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case SET_POSITIONS_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }

      case SET_POSITIONS_SUCCESS: {
        return {
          ...state,
          categories: action.payload,
          loading: false,
        };
      }

      case SET_POSITIONS_FAIL: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          model: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return {
          ...state,
          editLoading: false,
          editSuccess: true,
        };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case DELETE_FAIL: {
        return { ...state, deleteError: true };
      }

      case CLEAR_ERRORS: {
        return { ...state, deleteError: false, editError: null, byIdError: null, error: null };
      }
      case SET_HEADER_STICKY: {
        return { ...state, headerSticky: action.payload };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setMenuIndex:(payload:string)=>createAction(SET_MENU_INDEX,payload),
  fetchRootRequest: (payload: { page: number; perPage: number; only_with_products?: 1 | 0 }) =>
    createAction(FETCH_ROOT_REQUEST, payload),

  fetchChildrenRequest: (payload: { page: number; perPage: number; parentId: number }) =>
    createAction(FETCH_CHILDREN_REQUEST, payload),

  fetchParentRequest: (payload: { parentId: number }) =>
    createAction(FETCH_PARENT_REQUEST, payload),

  fetchFullRequest: () => createAction(FETCH_FULL_REQUEST),

  fetchRootSuccess: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_ROOT_SUCCESS, payload),
  fetchChildrenSuccess: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_CHILDREN_SUCCESS, payload),
  fetchParentSuccess: (payload: IServerResponse<ICategoryItem>) =>
    createAction(FETCH_PARENT_SUCCESS, payload),
  fetchFullSuccess: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_FULL_SUCCESS, payload),

  fetchRootFail: (payload: string) => createAction(FETCH_ROOT_FAIL, payload),
  fetchChildrenFail: (payload: string) => createAction(FETCH_CHILDREN_FAIL, payload),
  fetchParentFail: (payload: string) => createAction(FETCH_PARENT_FAIL, payload),
  fetchFullFail: (payload: string) => createAction(FETCH_FULL_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<ICategoryItem>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  setPositionsRequest: (payload: { positions: string; categories: ICategoryItem[] }) =>
    createAction(SET_POSITIONS_REQUEST, payload),
  setPositionsSuccess: (payload: ICategoryItem[]) => createAction(SET_POSITIONS_SUCCESS, payload),
  setPositionsFail: (payload: string) => createAction(SET_POSITIONS_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { data: ICategoryItem }) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: ICategoryItem }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  deleteRequest: (payload: {
    id: number | undefined;
    parentId: number;
    page: number;
    perPage: number;
  }) => createAction(DELETE_REQUEST, payload),
  deleteSuccess: () => createAction(DELETE_SUCCESS),
  deleteFail: () => createAction(DELETE_FAIL),

  clearErrors: () => createAction(CLEAR_ERRORS),

  clearCatalogCategories: () => createAction(CLEAR_CATALOG_CATEGORIES),
  fetchCatalogCategories: () => createAction(FETCH_CATALOG_CATEGORIES_REQUEST),
  errorCatalogCategories: (payload: string) => createAction(FETCH_CATALOG_CATEGORIES_FAIL, payload),
  successCatalogCategories: (payload: IServerResponse<ICategoryItem[]>) =>
    createAction(FETCH_CATALOG_CATEGORIES_SUCCESS, payload),
  setHeaderSticky: (payload: boolean) => createAction(SET_HEADER_STICKY, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchRootSaga({ payload }: { payload: { page: number; perPage: number } }) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem[]> } = yield call(() =>
      getRootCategories({
        page: payload.page,
        per_page: payload.perPage,
      })
    );
    yield put(actions.fetchRootSuccess(data));
  } catch (e) {
    yield put(actions.fetchRootFail((e && e?.response?.data?.message) || 'Network error'));
  }
}

function* fetchCatalogSaga() {
  try {
    const { data }: { data: IServerResponse<ICategoryItem[]> } = yield call(() =>
      getFullCategories({
        only_with_products: 1,
        only_active: 1,
      })
    );
    yield put(actions.successCatalogCategories(data));
  } catch (e) {
    yield put(actions.errorCatalogCategories((e && e?.response?.data?.message) || 'Network error'));
  }
}

function* fetchChildrenSaga({
  payload,
}: {
  payload: { page: number; perPage: number; parentId: number };
}) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem[]> } = yield call(() =>
      getCategoriesWithChildren({
        page: payload.page,
        per_page: payload.perPage,
        id: payload.parentId,
      })
    );
    yield put(actions.fetchChildrenSuccess(data));
  } catch (e) {
    yield put(actions.fetchRootFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchParentSaga({ payload }: { payload: { parentId: number } }) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem> } = yield call(() =>
      getCategoriesWithParent({
        id: payload.parentId,
      })
    );
    yield put(actions.fetchParentSuccess(data));
  } catch (e) {
    yield put(actions.fetchParentFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchFullSaga() {
  try {
    const { data }: { data: IServerResponse<ICategoryItem[]> } = yield call(() =>
      getFullCategories()
    );
    yield put(actions.fetchFullSuccess(data));
  } catch (e) {
    yield put(actions.fetchFullFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<ICategoryItem> } = yield call(() =>
      getCategoryById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: { data: ICategoryItem } }) {
  try {
    yield call(() => createCategory(payload.data));
    yield put(actions.fetchCatalogCategories());
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: ICategoryItem } }) {
  try {
    yield call(() => editCategory(payload.id, payload.data));
    yield put(actions.fetchCatalogCategories());
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* deleteSaga({
  payload,
}: {
  payload: { id: number | undefined; parentId: number; page: number; perPage: number };
}) {
  try {
    yield call(() => deleteCategory(payload.id));
    yield put(actions.fetchCatalogCategories());
    if (!payload.parentId) {
      yield put(actions.fetchFullRequest());
    } else if (payload.parentId === -1) {
      yield put(actions.fetchRootRequest({ page: payload.page, perPage: payload.perPage }));
    } else {
      yield put(
        actions.fetchChildrenRequest({
          page: payload.page,
          perPage: payload.perPage,
          parentId: payload.parentId,
        })
      );
    }
  } catch {
    yield put(actions.deleteFail());
  }
}

function* setPositionsSaga({
  payload,
}: {
  payload: { positions: string; categories: ICategoryItem[] };
}) {
  try {
    yield call(() => setCategoriesPositions(payload.positions));
    yield put(actions.setPositionsSuccess(payload.categories));
  } catch (e) {
    yield put(actions.setPositionsFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRootRequest>>(FETCH_ROOT_REQUEST, fetchRootSaga);
  yield takeLatest<ReturnType<typeof actions.fetchCatalogCategories>>(
    FETCH_CATALOG_CATEGORIES_REQUEST,
    fetchCatalogSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchChildrenRequest>>(
    FETCH_CHILDREN_REQUEST,
    fetchChildrenSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchChildrenRequest>>(
    FETCH_PARENT_REQUEST,
    fetchParentSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.deleteRequest>>(DELETE_REQUEST, deleteSaga);
  yield takeLatest<ReturnType<typeof actions.fetchFullRequest>>(FETCH_FULL_REQUEST, fetchFullSaga);
  yield takeLatest<ReturnType<typeof actions.setPositionsRequest>>(
    SET_POSITIONS_REQUEST,
    setPositionsSaga
  );
}
