import { createStyles, makeStyles } from '@material-ui/core';
import { colors } from '../../../constants/colors';
export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 1440,
      width: '95%',
      margin: '0 auto',
      flex: 1,
      [theme.breakpoints.down(500)]: {
        width: '100%',
        margin: 0,
      },
    },
    cartBox: {
      padding: '0 32px',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      [theme.breakpoints.down(500)]: {
        paddingRight: 8,
      },
    },
    authModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      borderRadius: 12,
      padding: 32,
      width: 364,
      maxWidth: '90%',

      '&:focus': {
        outline: 'none',
      },

      '& h2': {
        fontWeight: 600,
        fontSize: 19,
        letterSpacing: 0.15,
        color: '#1A1A1A',
        marginBottom: 16,
      },
    },
    tabs: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,

      '& p': {
        fontWeight: 600,
        fontSize: 16,
        color: '#484848',
        marginRight: 16,
        marginBottom: 0,
        paddingBottom: 4,
        borderBottom: '2px solid transparent',
        cursor: 'pointer',

        '&.active': {
          borderBottomColor: '#1A1A1A',
          color: '#1A1A1A',
        },
      },
    },
    helpText: {
      fontSize: 12,
      letterSpacing: 0.4,
      color: '#1A1A1A',
      margin: '14px 0 16px 0',
    },
    formControlLabel: {
      fontSize: 12,
      letterSpacing: 0.5,
      color: '#1A1A1A',

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
    button: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#7CB142',
      borderRadius: 12,
      fontWeight: 600,
      fontSize: 16,
      color: '#FFFFFF',
      width: '100%',
      border: 'none',
      boxShadow: 'none',
    },
  })
);
