import { toAbsoluteUrl } from '../../../../_metronic';
import { useStyles } from './hooks/useStyles';
import { User, Category } from 'react-iconly';
import { colors } from '../../../constants/colors';
import { useHistory } from 'react-router';
import React, { useEffect, useRef } from 'react';
interface IProps {
  onMenuClick?: any;
  onUserClick?: any;
  leftSection?: any;
  rightSection?: any;
  gradient?: boolean;
  setLeftPosition?: (data: { left: number; top: number }) => void;
  style?: React.CSSProperties;
  leftPosition: {
    left: number;
    top: number;
  };
  setIsShown?: (value: boolean) => void;
}
const Header: React.FC<IProps> = ({
  onMenuClick,
  onUserClick,
  leftSection = null,
  rightSection = null,
  gradient = false,
  setLeftPosition,
  style,
  leftPosition,
  setIsShown,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const leftRef = useRef<any>(null);
  const isCategoryPage = window.location.href.includes('products/catalog');

  const catalogPage = window.location.href.includes('products/catalog');
  const companyCatalogPage = window.location.href.includes('market');
  const reviewPage = window.location.href.includes('review');

  useEffect(() => {
    if (leftRef && leftRef.current && setLeftPosition) {
      const { top, left } = leftRef.current.getBoundingClientRect();
      if (leftPosition.left !== left || leftPosition.top !== top) {
        setLeftPosition({ left, top });
      }
    }
  }, [leftRef, leftSection]);

  return (
    <div className={classes.sticky}>
      <div className={classes.root}>
        <div className={classes.leftSection} ref={leftRef}>
          {(catalogPage || companyCatalogPage || reviewPage) && leftSection}
        </div>
        <img
          onClick={() => history.push('/')}
          src={toAbsoluteUrl('/images/logoVector.svg')}
          className={'big ' + classes.mainLogo}
          alt="logo"
        />
        <img
          onClick={() => history.push('/')}
          src={toAbsoluteUrl('/images/flowerLogo.svg')}
          className={'mini ' + classes.mainLogoMini}
          alt="miniLogo"
        />
        <div className={`${classes.rightSection} ${setIsShown ? classes.headActionsAdmin : ''}` }>

          {rightSection || <div>&nbsp;</div>}
          <div className={classes.headActions} onClick={onMenuClick}>
            <div onClick={() => setIsShown && setIsShown(false)}>
              <Category size="large" set="bold" primaryColor={colors.primary.green} />
            </div>
            <div onClick={() => setIsShown && history.push('/settings/profile')}>
              <User size="large" set="bold" primaryColor={colors.primary.green} />
            </div>
          </div>
        </div>
      </div>
      {isCategoryPage && <div className={classes.miniLeftSection}>{leftSection}</div>}
    </div>
  );
};

export default Header;
