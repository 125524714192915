import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFormatMessage } from '../../../../hooks/useFormatMessage';

export const getInitValues = () => ({
  code: '',
});

const getValidationSchema = (fm: any) => {
  return Yup.object().shape({
    code: Yup.string().required(fm('AUTH.INPUT.EMAIL_REQUIRED')),
  });
};

export const useVerifyCodeFormik = (submit: (code: string) => void) => {
  const fm = useFormatMessage();
  const validationSchema = getValidationSchema(fm);

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(),
    validationSchema,
    onSubmit: (submitValues) => {
      submit(submitValues.code);
    },
  });
};
