import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { getResponseMessage } from '../../utils/utils';
import { IServerResponse } from '../../interfaces/server';
import {
  addProduct,
  cartCheckout,
  cartEdit,
  clearCart,
  createCart,
  getCart,
  setProductCount,
  checkoutStripeReq,
  checkDeliveryCost,
} from '../../crud/cart.crud';
import { ICart, ICartCheckout, IGuestCart } from '../../pages/home/cart/interfaces';
import { IStore } from '../../interfaces/store';
import { getStores } from '../../crud/store.crud';
import { IProduct } from '../../interfaces/product';
import moment from 'moment';

const CLEAR_ADD_PRODUCT = 'orders/CLEAR_ADD_PRODUCT';
const ADD_PRODUCT_REQUEST = 'orders/ADD_PRODUCT_REQUEST';
const ADD_PRODUCT_SUCCESS = 'orders/ADD_PRODUCT_SUCCESS';
const ADD_PRODUCT_FAIL = 'orders/ADD_PRODUCT_FAIL';

const CLEAR_CART_REQUEST = 'orders/CLEAR_CART';
const CLEAR_CART_SUCCESS = 'orders/CLEAR_CART_SUCCESS';
const CLEAR_CART_FAIL = 'orders/CLEAR_CART_FAIL';

const GET_OR_CREATE_CART_REQUEST = 'orders/GET_OR_CREATE_CART_REQUEST';
const GET_OR_CREATE_CART_SUCCESS = 'orders/GET_OR_CREATE_CART_SUCCESS';
const GET_OR_CREATE_CART_FAIL = 'orders/GET_OR_CREATE_CART_FAIL';

const CLEAR_SET_PRODUCT_COUNT = 'orders/CLEAR_SET_PRODUCT_COUNT';
const SET_PRODUCT_COUNT_REQUEST = 'orders/SET_PRODUCT_COUNT_REQUEST';
const SET_PRODUCT_COUNT_SUCCESS = 'orders/SET_PRODUCT_COUNT_SUCCESS';
const SET_PRODUCT_COUNT_FAIL = 'orders/SET_PRODUCT_COUNT_FAIL';

const CLEAR_CHECKOUT = 'orders/CLEAR_CHECKOUT';
const CHECKOUT_REQUEST = 'orders/CHECKOUT_REQUEST';
const CHECKOUT_SUCCESS = 'orders/CHECKOUT_SUCCESS';
const CHECKOUT_FAIL = 'orders/CHECKOUT_FAIL';
const CHECKOUT_STRIPE = 'orders/CHECKOUT_STRIPE';

const CLEAR_STORES = 'orders/CLEAR_STORES';
const STORES_REQUEST = 'orders/STORES_REQUEST';
const STORES_SUCCESS = 'orders/STORES_SUCCESS';
const STORES_FAIL = 'orders/STORES_FAIL';
const SET_PRODUCT_GUEST_CART = 'orders/SET_PRODUCT_GUEST_CART';
const CLEAR_GUEST_CART = 'orders/CLEAR_GUEST_CART';
const CLEAR_AFTER_GUEST_CART_ADD = 'orders/CLEAR_AFTER_GUEST_CART_ADD';
const GET_LOCAL_GUEST_CART = 'orders/GET_LOCAL_GUEST_CART';
const SET_COUNT_GUEST_CART = 'orders/SET_COUNT_GUEST_CART';
const SET_USER_INFO_GUEST_CART = 'orders/SET_USER_INFO_GUEST_CART';
const SET_CART_DATE = 'orders/SET_CART_DATE';
const CHECK_DELIVERY_COST = 'orders/CHECK_DELIVERY_COST';
const CHECKED_DELIVERY_COST = 'orders/CHECKED_DELIVERY_COST';
const CLEAR_DELIVERY_COST = 'orders/CLEAR_DELIVERY_COST';
const CHECKED_DELIVERY_ERR = 'orders/CHECKED_DELIVERY_ERR';

const CART_SIDEBAR_STATE = 'sidebar/CART_SIDEBAR_STATE';

export interface IInitialState {
  cart: ICart | null;
  companyId: number | null;
  stores: IStore[];

  addProductLoading: boolean;
  addProductSuccess: boolean;
  addProductError: string | null;

  getOrCreateCartLoading: boolean;
  getOrCreateCartSuccess: boolean;
  getOrCreateCartError: string | null;

  getCartLoading: boolean;
  getCartSuccess: boolean;
  getCartError: string | null;

  clearCartLoading: boolean;
  clearCartSuccess: boolean;
  clearCartError: string | null;

  setProductCountLoading: boolean;
  setProductCountSuccess: boolean;
  setProductCountError: string | null;

  checkoutLoading: boolean;
  checkoutSuccess: boolean;
  checkoutError: string | null;

  storesLoading: boolean;
  storesSuccess: boolean;
  storesError: string | null;
  guestCart: IGuestCart | null;

  deliveryDate: string;

  cartSidebarState: boolean;
}

const initialState: IInitialState = {
  cart: null,
  companyId: null,
  stores: [],

  addProductLoading: false,
  addProductSuccess: false,
  addProductError: null,

  getOrCreateCartLoading: false,
  getOrCreateCartSuccess: false,
  getOrCreateCartError: null,

  getCartLoading: false,
  getCartSuccess: false,
  getCartError: null,

  clearCartLoading: false,
  clearCartSuccess: false,
  clearCartError: null,

  setProductCountLoading: false,
  setProductCountSuccess: false,
  setProductCountError: null,

  checkoutLoading: false,
  checkoutSuccess: false,
  checkoutError: null,

  storesLoading: false,
  storesSuccess: false,
  storesError: null,
  guestCart: null,

  deliveryDate: moment().format('YYYY-MM-DD'),
  cartSidebarState: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'orders', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CART_SIDEBAR_STATE: {
        return {
          ...state,
          cartSidebarState: action.payload,
        };
      }
      case CLEAR_ADD_PRODUCT: {
        return {
          ...state,
          addProductLoading: false,
          addProductSuccess: false,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_REQUEST: {
        return {
          ...state,
          addProductLoading: true,
          addProductSuccess: false,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_SUCCESS: {
        const cart = action.payload.data;
        const companyId = cart
          ? cart.items.length
            ? cart.items[0].product?.company?.id || null
            : null
          : null;

        return {
          ...state,
          companyId,
          cart,
          addProductLoading: false,
          addProductSuccess: true,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_FAIL: {
        return {
          ...state,
          addProductLoading: false,
          addProductSuccess: false,
          addProductError: action.payload,
        };
      }

      case GET_OR_CREATE_CART_REQUEST: {
        return {
          ...state,
          getOrCreateCartLoading: true,
          getOrCreateCartSuccess: false,
          getOrCreateCartError: null,
        };
      }
      case GET_OR_CREATE_CART_SUCCESS: {
        const cart = action.payload.data;
        const companyId = cart
          ? cart.items.length
            ? cart.items[0].product?.company?.id || null
            : null
          : null;

        return {
          ...state,
          companyId,
          cart,
          getOrCreateCartLoading: false,
          getOrCreateCartSuccess: true,
          getOrCreateCartError: null,
        };
      }
      case GET_OR_CREATE_CART_FAIL: {
        return {
          ...state,
          getOrCreateCartLoading: false,
          getOrCreateCartSuccess: false,
          getOrCreateCartError: action.payload,
        };
      }

      case CLEAR_CART_REQUEST: {
        return {
          ...state,
          clearCartLoading: true,
          clearCartSuccess: false,
          clearCartError: null,
        };
      }
      case CLEAR_CART_SUCCESS: {
        return {
          ...state,
          companyId: null,
          cart: null,
          clearCartLoading: false,
          clearCartSuccess: true,
          clearCartError: null,
        };
      }
      case CLEAR_CART_FAIL: {
        return {
          ...state,
          clearCartLoading: false,
          clearCartSuccess: false,
          clearCartError: action.payload,
        };
      }

      case CLEAR_SET_PRODUCT_COUNT: {
        return {
          ...state,
          setProductCountLoading: false,
          setProductCountSuccess: false,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_REQUEST: {
        return {
          ...state,
          setProductCountLoading: true,
          setProductCountSuccess: false,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_SUCCESS: {
        const cart = action.payload.data;
        const companyId = cart ? (cart.items.length ? state.companyId : null) : null;

        return {
          ...state,
          companyId,
          cart,
          setProductCountLoading: false,
          setProductCountSuccess: true,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_FAIL: {
        return {
          ...state,
          setProductCountLoading: false,
          setProductCountSuccess: false,
          setProductCountError: action.payload,
        };
      }

      case CLEAR_CHECKOUT: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }
      case CHECKOUT_REQUEST: {
        return {
          ...state,
          checkoutLoading: true,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }
      case CHECKOUT_SUCCESS: {
        localStorage.setItem('hash', action.payload.data.cart_hash);

        return {
          ...state,
          cart: action.payload.data,
          companyId: null,
          checkoutLoading: false,
          checkoutSuccess: true,
          checkoutError: null,
        };
      }
      case CHECKOUT_FAIL: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: false,
          checkoutError: action.payload,
        };
      }

      case CLEAR_STORES: {
        return {
          ...state,
          stores: [],
          storesLoading: false,
          storesSuccess: false,
          storesError: null,
        };
      }
      case STORES_REQUEST: {
        return {
          ...state,
          storesLoading: true,
          storesSuccess: false,
          storesError: null,
        };
      }
      case STORES_SUCCESS: {
        return {
          ...state,
          stores: action.payload.data,
          storesLoading: false,
          storesSuccess: true,
          storesError: null,
        };
      }
      case STORES_FAIL: {
        return {
          ...state,
          storesLoading: false,
          storesSuccess: false,
          storesError: action.payload,
        };
      }

      case SET_PRODUCT_GUEST_CART: {
        if (state.guestCart && action.payload.type === 'cart') {
          const newGuestCart = state.guestCart;
          const guestProductIndex = newGuestCart.items.findIndex(
            (item) => item.product.id === action.payload.data.id
          );
          if (guestProductIndex > -1) {
            newGuestCart.items[guestProductIndex].count++;
          } else {
            newGuestCart.items = [
              ...newGuestCart.items,
              { count: 1, product: action.payload.data, product_price: action.payload.data.price },
            ];
          }
          newGuestCart.order_summ += action.payload.data.price;
          newGuestCart.goods_num++;
          localStorage.setItem('guestCart', JSON.stringify(newGuestCart));
          return {
            ...state,
            guestCart: newGuestCart,
            addProductSuccess: true,
          };
        } else {
          const startGuestCart: IGuestCart = {
            order_summ: action.payload.data.price,
            goods_num: 1,
            items: [
              {
                count: 1,
                product: action.payload.data,
                product_price: action.payload.data.price,
              },
            ],
            cartCompanyId: action.payload.data.company?.id,
            fio: '',
            email: '',
            phone: '',
            deliveryDay: '',
            isPickup: false,
            comment: '',
          };
          localStorage.setItem('guestCart', JSON.stringify(startGuestCart));
          return {
            ...state,
            guestCart: startGuestCart,
            addProductSuccess: true,
          };
        }
      }

      case GET_LOCAL_GUEST_CART: {
        if (Boolean(state.guestCart)) {
          return state;
        } else {
          const localCart = localStorage.getItem('guestCart');
          if (localCart) {
            return {
              ...state,
              guestCart: JSON.parse(localCart),
            };
          } else {
            return state;
          }
        }
      }

      case CLEAR_GUEST_CART: {
        localStorage.removeItem('guestCart');
        return {
          ...state,
          guestCart: null,
        };
      }

      case CLEAR_AFTER_GUEST_CART_ADD: {
        return {
          ...state,
          addProductSuccess: false,
        };
      }

      case SET_COUNT_GUEST_CART: {
        const currentGuestCart = state.guestCart;
        if (!!currentGuestCart) {
          const indexCurrentProduct = currentGuestCart.items.findIndex(
            (item) => item.product.id === action.payload.product_id
          );
          const currentProduct = currentGuestCart.items[indexCurrentProduct];
          action.payload.type === 'dec'
            ? currentGuestCart.goods_num--
            : currentGuestCart.goods_num++;
          action.payload.type === 'dec'
            ? (currentGuestCart.order_summ -= currentProduct.product_price)
            : (currentGuestCart.order_summ += currentProduct.product_price);
          action.payload.count <= 0
            ? currentGuestCart.items.splice(indexCurrentProduct, 1)
            : (currentProduct.count = action.payload.count);
          localStorage.setItem('guestCart', JSON.stringify(currentGuestCart));
          return {
            ...state,
            guestCart: { ...currentGuestCart },
          };
        }
        return state;
      }

      case SET_USER_INFO_GUEST_CART: {
        const currentCart = state.guestCart;
        console.log('action.payload', action.payload);

        if (currentCart) {
          const newCurrentCart = {
            ...currentCart,
            fio: action.payload.fio,
            email: action.payload.email,
            phone: action.payload.phone,
            deliveryDay: action.payload.deliveryDay,
            isPickup: action.payload.isPickup,
            pickupStore: action.payload.pickupStore,
            comment: action.payload.comment,
            location: action.payload.location,
          };
          localStorage.setItem('guestCart', JSON.stringify(newCurrentCart));
          return {
            ...state,
            guestCart: newCurrentCart,
          };
        }
        return state;
      }

      case SET_CART_DATE: {
        return {
          ...state,
          deliveryDate: action.payload,
        };
      }
      case CHECKED_DELIVERY_COST: {
        return {
          ...state,
          deliveryInfo: action.payload,
        };
      }
      case CHECKED_DELIVERY_ERR: {
        return {
          ...state,
          deliveryError: action.payload,
        };
      }
      case CLEAR_DELIVERY_COST: {
        return {
          ...state,
          deliveryInfo: null,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setCartSidebarState: (payload: boolean) => createAction(CART_SIDEBAR_STATE, payload),
  clearAddProduct: () => createAction(CLEAR_ADD_PRODUCT),
  addProductRequest: (payload: { product_id: number; count: number; newCart?: boolean }) =>
    createAction(ADD_PRODUCT_REQUEST, payload),
  addProductSuccess: (payload: IServerResponse<ICart>) =>
    createAction(ADD_PRODUCT_SUCCESS, payload),
  addProductFail: (payload: string) => createAction(ADD_PRODUCT_FAIL, payload),

  getOrCreateRequest: () => createAction(GET_OR_CREATE_CART_REQUEST),
  getOrCreateSuccess: (payload: IServerResponse<ICart>) =>
    createAction(GET_OR_CREATE_CART_SUCCESS, payload),
  getOrCreateFail: (payload: string) => createAction(GET_OR_CREATE_CART_FAIL, payload),

  clearCartRequest: () => createAction(CLEAR_CART_REQUEST),
  clearCartSuccess: () => createAction(CLEAR_CART_SUCCESS),
  clearCartFail: (payload: string) => createAction(CLEAR_CART_FAIL, payload),

  clearSetProductCount: () => createAction(CLEAR_SET_PRODUCT_COUNT),
  setProductCountRequest: (payload: { product_id: number; count: number }) =>
    createAction(SET_PRODUCT_COUNT_REQUEST, payload),
  setProductCountSuccess: (payload: IServerResponse<ICart>) =>
    createAction(SET_PRODUCT_COUNT_SUCCESS, payload),
  setProductCountFail: (payload: string) => createAction(SET_PRODUCT_COUNT_FAIL, payload),

  clearCheckout: () => createAction(CLEAR_CHECKOUT),
  checkoutRequest: (payload: { data: ICartCheckout }) => createAction(CHECKOUT_REQUEST, payload),
  checkoutSuccess: (payload: IServerResponse<ICart>) => createAction(CHECKOUT_SUCCESS, payload),
  checkoutFail: (payload: string) => createAction(CHECKOUT_FAIL, payload),

  clearStores: () => createAction(CLEAR_STORES),
  storesRequest: (payload: { companyId: number }) => createAction(STORES_REQUEST, payload),
  storesSuccess: (payload: IServerResponse<IStore[]>) => createAction(STORES_SUCCESS, payload),
  storesFail: (payload: string) => createAction(STORES_FAIL, payload),
  setProductGuestCart: (payload: { data: IProduct; type?: 'new' | 'cart' }) =>
    createAction(SET_PRODUCT_GUEST_CART, payload),
  clearAfterGuestCartAdd: () => createAction(CLEAR_AFTER_GUEST_CART_ADD),
  clearGuestCart: () => createAction(CLEAR_GUEST_CART),
  getLocalGuestCart: () => createAction(GET_LOCAL_GUEST_CART),
  setCountGuestCart: (payload: { product_id: number; count: number; type: 'dec' | 'inc' }) =>
    createAction(SET_COUNT_GUEST_CART, payload),
  setInfoUserGuestCart: (payload: {
    fio: string;
    email: string;
    phone: string;
    deliveryDay: string;
    isPickup: boolean;
    pickupStore?: IStore | null;
    comment?: string;
    unit_office?: any;
    location: any;
  }) => createAction(SET_USER_INFO_GUEST_CART, payload),
  setCartDate: (payload: string) => createAction(SET_CART_DATE, payload),

  checkoutStripeRequest: (payload: { cartId: number }) => createAction(CHECKOUT_STRIPE, payload),

  checkDeliveryCost: (payload: { id: number, lng: string, lat: string }) => createAction(CHECK_DELIVERY_COST, payload),
  clearDeliveryCost: () => createAction(CLEAR_DELIVERY_COST),
  checkedDeliveryCost: (payload: any) => createAction(CHECKED_DELIVERY_COST, payload),
  checkDeliveryErr: (payload: any) => createAction(CHECKED_DELIVERY_ERR, payload),

  checkDeliveryCost: (payload: { id: number; lng: string; lat: string }) =>
    createAction(CHECK_DELIVERY_COST, payload),
  checkedDeliveryCost: (payload: any) => createAction(CHECKED_DELIVERY_COST, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* addProductSaga({
  payload,
}: {
  payload: { product_id: number; count: number; newCart?: boolean };
}) {
  try {
    const hash = localStorage.getItem('hash');
    if (hash) {
      if (payload.newCart) {
        yield call(() => clearCart(hash));
      }

      const { data }: { data: IServerResponse<ICart> } = yield call(() =>
        addProduct({
          product_id: payload.product_id,
          count: payload.count,
          hash,
        })
      );
      yield put(actions.addProductSuccess(data));
      yield put(actions.clearAddProduct());
    }
  } catch (e) {
    yield put(actions.addProductFail(getResponseMessage(e)));
  }
}

function* getOrCreateCartSaga() {
  try {
    const hash = localStorage.getItem('hash');
    if (hash) {
      const guestCart = localStorage.getItem('guestCart');
      const parseGuestCart: IGuestCart | null = guestCart ? JSON.parse(guestCart) : null;
      if (parseGuestCart) {
        yield call(() =>
          cartEdit(
            parseGuestCart.fio,
            parseGuestCart.email,
            parseGuestCart.phone,
            hash,
            parseGuestCart.deliveryDay,
            parseGuestCart.comment
          )
        );
        parseGuestCart.isPickup &&
          parseGuestCart.pickupStore &&
          localStorage.setItem('pickupStore', JSON.stringify(parseGuestCart.pickupStore));
        for (const item of parseGuestCart.items) {
          yield call(() => addProduct({ product_id: item.product.id!, count: item.count, hash }));
        }
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      } else {
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      }
      yield put(actions.clearGuestCart());
    } else {
      const { data }: { data: IServerResponse<any> } = yield call(() => createCart());
      const hash = data.data.cart_hash;
      localStorage.setItem('hash', hash);
      const guestCart = localStorage.getItem('guestCart');
      const parseGuestCart: IGuestCart | null = guestCart ? JSON.parse(guestCart) : null;
      if (parseGuestCart) {
        yield call(() =>
          cartEdit(
            parseGuestCart.fio,
            parseGuestCart.email,
            parseGuestCart.phone,
            hash,
            parseGuestCart.deliveryDay,
            parseGuestCart.comment
          )
        );
        parseGuestCart.isPickup &&
          parseGuestCart.pickupStore &&
          localStorage.setItem('pickupStore', JSON.stringify(parseGuestCart.pickupStore));
        for (const item of parseGuestCart.items) {
          yield call(() => addProduct({ product_id: item.product.id!, count: item.count, hash }));
        }
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      } else {
        yield put(actions.getOrCreateSuccess(data));
      }
      yield put(actions.clearGuestCart());
    }
  } catch (e) {
    yield put(actions.getOrCreateFail(getResponseMessage(e)));
  }
}

function* clearCartSaga() {
  try {
    const hash = localStorage.getItem('hash');
    if (hash) {
      yield call(() => clearCart(hash));
      localStorage.removeItem('hash');
      yield put(actions.clearCartSuccess());
    }
  } catch (e) {
    yield put(actions.clearCartFail(getResponseMessage(e)));
  }
}

function* setProductCountSaga({ payload }: { payload: { product_id: number; count: number } }) {
  try {
    const hash = localStorage.getItem('hash');
    if (hash) {
      const { data }: { data: IServerResponse<ICart> } = yield call(() =>
        setProductCount({ product_id: payload.product_id, count: payload.count, hash })
      );
      yield put(actions.setProductCountSuccess(data));
      yield put(actions.clearSetProductCount());
    }
  } catch (e) {
    yield put(actions.setProductCountFail(getResponseMessage(e)));
  }
}

function* checkoutSaga({ payload }: { payload: { data: ICartCheckout } }) {
  console.log('check 2');

  try {
    const hash = localStorage.getItem('hash');
    if (hash) {
      // const cartId = store.getState().cart.cart.id
      yield call(() => cartCheckout(payload.data, hash));
      const { data }: { data: IServerResponse<any> } = yield call(() => createCart());
      yield put(actions.checkoutSuccess(data));
      // yield put(actions.checkoutStripeRequest({ cartId: cartId }));
    }
  } catch (e) {
    yield put(actions.checkoutFail(getResponseMessage(e)));
  }
}

function* storesFetchSaga({ payload }: { payload: { companyId: number } }) {
  try {
    const { data }: { data: IServerResponse<IStore[]> } = yield call(() =>
      getStores(1, 999, payload.companyId)
    );
    yield put(actions.storesSuccess(data));
  } catch (e) {
    yield put(actions.storesFail(getResponseMessage(e)));
  }
}

function* checkoutStripeSaga({ payload }: { payload: { cartId: number } }) {
  try {
    const { data }: { data: any } = yield call(() => checkoutStripeReq(payload.cartId));
    yield (window.location.href = data.data.url);
  } catch (e) {
    console.log('Error', getResponseMessage(e));
  }
}

function* checkDeliveryCostSaga({
  payload,
}: {
  payload: { id: number; lng: string; lat: string };
}) {
  try {
    const { data }: { data: any } = yield call(() =>
      checkDeliveryCost(payload.id, payload.lng, payload.lat)
    );
    yield put(actions.checkedDeliveryCost(data.data));
  } catch (e) {
    yield;
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.addProductRequest>>(
    ADD_PRODUCT_REQUEST,
    addProductSaga
  );
  yield takeLatest<ReturnType<typeof actions.getOrCreateRequest>>(
    GET_OR_CREATE_CART_REQUEST,
    getOrCreateCartSaga
  );
  yield takeLatest<ReturnType<typeof actions.clearCartRequest>>(CLEAR_CART_REQUEST, clearCartSaga);
  yield takeLatest<ReturnType<typeof actions.setProductCountRequest>>(
    SET_PRODUCT_COUNT_REQUEST,
    setProductCountSaga
  );
  yield takeLatest<ReturnType<typeof actions.checkoutRequest>>(CHECKOUT_REQUEST, checkoutSaga);
  yield takeLatest<ReturnType<typeof actions.storesRequest>>(STORES_REQUEST, storesFetchSaga);
  yield takeLatest<ReturnType<typeof actions.checkoutStripeRequest>>(
    CHECKOUT_STRIPE,
    checkoutStripeSaga
  );
  yield takeLatest<ReturnType<typeof actions.checkDeliveryCost>>(
    CHECK_DELIVERY_COST,
    checkDeliveryCostSaga
  );
}
