import { createStyles, makeStyles } from '@material-ui/core';
import { colors } from '../../../../../constants/colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    compositionSize: {
      [theme.breakpoints.down(1250)]: {
        marginTop: 32,
        display: 'flex',
        '&>div': {
          flex: '1 !important',
        },
      },
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column',
      },
    },
    topSection: {
      display: 'flex',
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column',
      },
    },
    rightDescriptionCart: {
      display: 'none',
      [theme.breakpoints.down(1450)]: {
        display: 'block',
        marginBottom: "30px",
        width: '100%',
        maxWidth: "200px",
      },
      [theme.breakpoints.down(1250)]: {
        display: 'none',
      },
    },
    bottomCart: {
      display: 'none',
      [theme.breakpoints.down(885)]: {
        display: 'block',
        padding: '0 16px',
        marginTop: 30,
        width: '100%',
      },
    },

    rightCart: {
      maxWidth: "200px",
      [theme.breakpoints.down(1450)]: {
        display: 'none',
      },
      [theme.breakpoints.down(1250)]: {
        display: 'block',
        justifyContent: 'flex-start',
        flexDirection: 'none',
        width: '50%',
        marginLeft: 10,
      },
      [theme.breakpoints.down(885)]: {
        display: 'none',
        justifyContent: 'none',
        flexDirection: 'none',
        width: '0%',
        marginLeft: 0,
      },
      [theme.breakpoints.down(500)]: {
        display: 'none',
      },
    },

    descriptionText: {},
    bottomDescription: {
      display: 'none',
      [theme.breakpoints.down(1250)]: {
        display: 'block',
      },
      [theme.breakpoints.down(500)]: {
        display: 'block',
        width: 100,
      },
    },
    rightDescription: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      [theme.breakpoints.down(1250)]: {
        display: 'none',
      },
    },
    dots: {
      flex: 1,
      borderBottom: `1px dashed silver`,
      [theme.breakpoints.down(500)]: {
        display: 'flex',
      },
    },
    container: {
      paddingBottom: 60,
    },
    headerTitle: {
      [theme.breakpoints.down(500)]: {
        display: 'none',
      },
    },
    bottomTitle: {
      display: 'none',
      [theme.breakpoints.down(500)]: {
        display: 'block',
        marginTop: 24,
        fontSize: 23,
        fontWeight: 600,
        lineHeight: '23px',
        padding: '0 16px',
      },
    },
    textInfoCompany: {
      [theme.breakpoints.down(577)]: {
        fontSize: 10,
      },
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    body: {},
    gallery: {
      marginRight: 40,
      width: 600,
      [theme.breakpoints.down(1250)]: {
        marginRight: 50,
        maxWidth: 600,
        width: '100%',
      },
      [theme.breakpoints.down(834)]: {
        marginRight: 50,
        maxWidth: 600,
        width: '446px',
      },
      [theme.breakpoints.down(500)]: {
        width: '90%',
        marginLeft: '4%',
        marginRight: 0,
      },
      '& .image-gallery-slide': {
        borderRadius: 12,
        overflow: 'hidden',
        backgroundColor: colors.primary.lightGrey,

        '& img': {
          objectFit: 'cover',
        },
      },

      '& .image-gallery-thumbnails': {
        marginTop: 16,
      },

      '& .image-gallery-thumbnails-container': {
        width: 'fit-content',
        display: 'grid',
        gridTemplateColumns: 'repeat(6,1fr)',
        gridGap: 9,
        [theme.breakpoints.down(500)]: {
          padding: '0 16px',
        },
      },

      '& .image-gallery-thumbnail': {
        width: 138,
        height: 138,
        border: 'none',
        position: 'relative',

        marginLeft: 0,
        borderRadius: 12,
        overflow: 'hidden',
        backgroundColor: colors.primary.lightGrey,
        [theme.breakpoints.down(500)]: {
          width: 79,
          height: 79,
          objectFit: 'cover',
        },
        '& img': {
          objectFit: 'cover',
        },

        '&::before': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          background: '#1A1A1A',
          zIndex: 2,
          opacity: 0.55,
        },

        '&.active': {
          '&::before': {
            opacity: 0,
          },
        },
      },
    },

    rightNav: {
      position: 'absolute',
      right: 16,
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 2,
    },
    leftNav: {
      position: 'absolute',
      left: 16,
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 2,
    },
    sizeSection: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: 422,
      [theme.breakpoints.down(500)]: {
        width: 'auto',
      },
    },
    description: {
      marginRight: 40,
      border: 'none',
      minWidth: 400,
      [theme.breakpoints.down(500)]: {
        width: '90%',
        margin: 0,
        padding: '0 15px',
      },
    },
    galleryImage: {
      width: 600,
      height: 600,
      [theme.breakpoints.down(1250)]: {
        marginRight: 50,
        maxWidth: 600,
        width: '100%',
      },
      [theme.breakpoints.down(834)]: {
        width: 446,
        height: 446,
      },
      [theme.breakpoints.down(500)]: {
        width: '100%',
        height: 375,
      },
    },
    thumbnailImage: {
      width: 138,
      height: 138,
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',

      '& .span': {
        height: 5,
        width: 5,
        marginRight: 8,
      },

      lines: {
        border: 'none',
        borderBottom: `1px dotted ${colors.primary.lightGrey}`,
        height: '100%',
        width: '100%',
      },
    },
    cart: {
      minWidth: 290,
      background: 'linear-gradient(290.46deg, #FBFAF8 0%, #FFFFFF 100%)',
      border: '1px solid rgba(124, 177, 66, 0.15)',
      boxShadow: '11px 22px 44px -12px rgba(124, 177, 66, 0.25)',
      borderRadius: 12,
      padding: 16,
      position: 'sticky',
      top: 64,
    },
    splitter: {
      background: colors.primary.lightGrey,
      width: 1,
      margin: '0 32px',
      height: 100,
      [theme.breakpoints.down(535)]: {
        width: '100%',
        height: 1,
        margin: '32px 0',
      },
    },
    ratingSection: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      whiteSpace: 'nowrap',
      '& >span': {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 8,
        '&:last-child': {
          marginBottom: 0,
        },
        '& span': {
          marginLeft: 8,
        },
      },
    },
    rating: {
      [theme.breakpoints.down(950)]: {
        display: 'none',
      },
    },
    rating2: {
      display: 'none',
      [theme.breakpoints.down(950)]: {
        display: 'block',
      },
    },
    company: {
      marginTop: 64,
      background: 'linear-gradient(290.46deg, #FBFAF8 0%, #FFFFFF 100%)',
      border: '1px solid rgba(124, 177, 66, 0.15)',
      borderRadius: 12,
      padding: 16,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .divider': {
        height: '100%',
        width: 1,
        background: colors.primary.lightGrey,
      },
      [theme.breakpoints.down(834)]: {
        width: '100%',
      },
      [theme.breakpoints.down(535)]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    companyWrapper: {
      [theme.breakpoints.down(500)]: {
        padding: '0 16px',
      },
    },
    companyInfo: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: 360,
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
    },
    companyAvatar: {
      minWidth: 100,
      width: 100,
      height: 100,
      borderRadius: '50%',
      marginRight: 32,
      overflow: 'hidden',
      backgroundColor: colors.primary.lightGrey,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    companyRating: {},
    reviews: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    reviewsList: {
      width: '70%',
    },
    statistics: {
      width: '30%',
    },
    statisticsHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colors.primary.lightGrey}`,
      paddingBottom: 8,
      marginBottom: 22,
    },
    statisticsList: {
      marginBottom: 8,
    },
    statisticsItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,

      '& p:first-child': {
        width: 50,
      },

      '& p:last-child': {
        width: 30,
      },

      '& .progress': {
        /* Primary colors/Green */
        background: colors.primary.lightGrey,
        borderRadius: 12,
        height: 5,
        width: 296,
        overflow: 'hidden',

        '& span': {
          display: 'inline-block',
          height: '100%',
          background: colors.primary.green,
        },
      },
    },

    point: {
      paddingLeft: '14px !important',
      position: 'relative',

      '&:after': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        width: 5,
        height: 5,
        borderRadius: 5,
        backgroundColor: colors.complementary.gray,
      },
    },
  })
);
