import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { getPublicDoc } from '../../../../crud/docs.crud';
import { useSnackbar } from 'notistack';
// import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { Dialog } from '../../../base/Dialog';
import { CircularProgress } from '@material-ui/core';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  contentType: string;
}
let useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      minWidth: 800,
      maxWidth: 800,
      minHeight: '70%',
      [theme.breakpoints.down(500)]: {
        minWidth: 'unset',
        maxWidth: 'unset',
      },
    },
  })
);
const TermDialog: React.FC<IProps & WrappedComponentProps> = ({
  isOpen,
  handleClose,
  contentType,
  intl,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (contentType !== '') {
      getPublicDoc(contentType)
        .then((res) => {
          setContent(res.data.data.text);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        });
    }
  }, [contentType]);

  return (
    <Dialog
      className={styles.dialog}
      open={isOpen}
      onClose={handleClose}
      title={intl.formatMessage({
        id: contentType === 'user_agreement' ? 'AUTH.GENERAL.LEGAL' : 'SUBMENU.LEGAL_PRIVACY',
      })}
    >
      {loading ? (
        <div style={loaderWrapStyle}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: content }} style={{ color: 'black' }} />
      )}
    </Dialog>
  );
};

const loaderWrapStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default injectIntl(TermDialog);
