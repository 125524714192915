import React, { FC, useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Iconly } from 'react-iconly';
import { colors } from '../../../../../../constants/colors';
import { Cart, ICartProps } from '../Cart';
import { useStyles } from '../../hooks/useStyles';
import { ITypeParameter } from '../../../../../../interfaces/productType';
import { Description } from '../Description';
import { IDeliveryTime } from '../../../../../../interfaces/companies';

interface IProductBodyProps {
  images: ReadonlyArray<ReactImageGalleryItem>;
  parameter_values: ITypeParameter[];
  descriptionHtml: any;
  cartProps: ICartProps;
  title?: string;
  deliveryTimes: IDeliveryTime[];
}

export const ProductBody: FC<IProductBodyProps> = ({
  images,
  parameter_values,
  descriptionHtml,
  cartProps,
  title,
  deliveryTimes,
}) => {
  const classes = useStyles();

  const [counter, setCounter] = useState<number>(0);
  const [leftVisible, setLeftVisible] = useState<boolean>(false);
  const [rightVisible, setRightVisible] = useState<boolean>(true);

  useEffect(() => {
    setLeftVisible(() => (counter > 0 ? true : false));
    if (counter < images.length) {
      setRightVisible(() => true);
    }
    if (counter === images.length - 1) {
      setRightVisible(() => false);
    }
    if (counter > images.length - 1) setCounter(() => 0);
  }, [counter]);
  return (
    <div className={classes.body}>
      <div className={classes.topSection}>
        <div className={classes.gallery}>
          <ImageGallery
            showThumbnails={images.length > 1}
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            showNav
            renderThumbInner={(item) => (
              <img className={classes.thumbnailImage} src={item.thumbnail} alt="thumbnail" />
            )}
            renderLeftNav={(onClick, disabled) => {
              return leftVisible ? (
                <div
                  onClick={(e) => {
                    setCounter(() => counter - 1);
                    onClick(e);
                  }}
                  className={classes.leftNav}
                >
                  <Iconly
                    name={'ChevronLeftCircle'}
                    set="bold"
                    primaryColor={colors.primary.green}
                    size="xlarge"
                  />
                </div>
              ) : null;
            }}
            renderRightNav={(onClick) =>
              rightVisible ? (
                <div
                  onClick={(e) => {
                    setCounter(() => counter + 1);
                    onClick(e);
                  }}
                  className={classes.rightNav}
                >
                  <Iconly
                    name={'ChevronRightCircle'}
                    set="bold"
                    primaryColor={colors.primary.green}
                    size="xlarge"
                  />
                </div>
              ) : null
            }
            thumbnailPosition="bottom"
            onThumbnailClick={(_, index) => setCounter(() => index)}
          />
        </div>
        <div className={classes.rightDescription}>
          <div className={classes.descriptionText}>
            <div className={classes.rightDescriptionCart}>
              <Cart {...cartProps} />
            </div>
            <Description
              periods={deliveryTimes}
              cartProps={cartProps}
              descriptionHtml={descriptionHtml}
              parameter_values={parameter_values}
            />
          </div>
        </div>
        <div className={classes.rightCart}>
          <Cart {...cartProps} />
        </div>
      </div>

      <div className={classes.bottomTitle}>{title}</div>
      <div className={classes.bottomCart}>
        <Cart {...cartProps} />
      </div>
      <div className={classes.bottomDescription}>
        <Description
          periods={deliveryTimes}
          cartProps={cartProps}
          descriptionHtml={descriptionHtml}
          parameter_values={parameter_values}
        />
      </div>
    </div>
  );
};
