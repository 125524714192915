export const space = (
  type: 'margin' | 'padding',
  top: number | string = 0,
  right: number | string = 0,
  bottom: number | string = 0,
  left: number | string = 0
) => ({
  [type + 'Top']: top,
  [type + 'Right']: right,
  [type + 'Bottom']: bottom,
  [type + 'Left']: left,
});
