import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    input: {
      borderRadius: 12,
      transition: '0.2s',
      width: '100%',
      border: 'none',
      outline: 'none',
      boxSizing: 'border-box',
      padding: '13px 50px',
      fontSize: 16,
    },
    clearButton: {
      position: 'absolute',
      top: '50%',
      right: 15,
      transform: 'translateY(-50%)',
    },
    locationIcon: {
      position: 'absolute',
      top: 25,
      transform: 'translateY(-50%)',
      left: 20,
    },
    clearIcon: {
      position: 'absolute',
      top: 25,
      transform: 'translateY(-50%)',
      right: 20,
    },
    pacContainer: {
      color: 'red',
    },
  })
);
