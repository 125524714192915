import { useEffect, useState } from 'react';
import { ICollection, ICollectionPlace } from '../../../../interfaces/collections';
import { makeAxiosGetRequest } from '../../../../utils/utils';

export const useGetCollections = (type: ICollectionPlace): [ICollection[], boolean, string] => {
  const [loading, setLoading] = useState(false);
  const [collections, setCollections] = useState<ICollection[]>([]);
  const [err, setErr] = useState<any>(null);

  useEffect(() => {
    const url = `/api/shop/collections?${type}=true`;
    makeAxiosGetRequest(url, setCollections, setLoading, setErr);
  }, [type]);

  return [collections, loading, err];
};

