export enum SidebarPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum RetailType {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum ButtonedFilterType {
  MULTI = 'multi',
  SINGLE = 'single',
}
const {
  REACT_APP_API_DOMAIN = 'lk-api.flonet.au',
  // REACT_APP_API_DOMAIN = 'flo-apidev.me-interactive.net',
  // REACT_APP_API_DOMAIN = 'flo-api.me-interactive.net',
  REACT_APP_GOOGLE_API_KEY = 'AIzaSyB_rjiR94heML-tlGsexMmxwtpVQ7WlcxI',
} = process.env;

// const { protocol } = window.location;
const protocol = 'https:';

const API_DOMAIN = `${protocol}//${REACT_APP_API_DOMAIN}`;

const API_SEARCH_LOCATION_URI = `https://maps.googleapis.com/maps/api/geocode/json?key=${REACT_APP_GOOGLE_API_KEY}`;

export { API_DOMAIN, API_SEARCH_LOCATION_URI, REACT_APP_GOOGLE_API_KEY };

export enum UserType {
  ADMIN = 'admin',
  PARTNER = 'partner',
  BUYER = 'buyer',
}
export const LOGIN_URL = 'api/user/login';
export const REGISTER_URL = 'api/user/register';
export const ME_URL = 'api/me';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const getRecoveryPasswordUrl = (email: string): string =>
  `/api/_p/reset_password/send_code?email=${email}`;
export const NEW_PASSWORD_URL = '/api/_p/change_password_from_link';
export const sendSmsUrl = (
  country_name: string = 'Russia',
  country_code: string = '7',
  email: string | undefined
) =>
  `/api/users/code_register_sms?country_name=${country_name}&country_code=${country_code}&email=${email}`;
export const GET_USERS_URL = '/api/users';
export const CREATE_USER_URL = '/api/user/create';
export const getUserUrl = (id: number): string => `/api/user/${id}`;
export const editUserUrl = (id: number): string => `/api/user/${id}/edit`;
export const editVendorUrl = (id: number): string => `/api/user/${id}/vendor_edit`;
export const SEND_INVITE_URL = '/api/user_activate/send';
export const delUserUrl = (id: number): string => `/api/user/${id}`;
export const USER_RECIPIENTS_URL = (id: number): string => `/api/user/${id}/recipients`;
export const GET_COUNTRY_ID = '/api/phone_codes';
export const GET_COMPANIES_URL = '/api/companies';
export const CREATE_COMPANY_URL = '/api/company';
export const SEARCH_COMPANY_URL = '/api/companies/search';
export const getCompanyUrl = (id: number): string => `/api/company/${id}`;
export const editCompanyUrl = (id: number): string => `/api/company/${id}`;
export const delCompanyUrl = (id: number): string => `/api/company/${id}`;

export const CREATE_CATEGORY_URL = '/api/shop/category';
export const getCategoryUrl = (id: number | undefined) => `/api/shop/category/${id}`;
export const FULL_CATEGORIES_URL = '/api/shop/categories/full';
export const ROOT_CATEGORIES_URL = '/api/shop/categories/roots';
export const getCategoriesWithChildrenUrl = (id: number) =>
  `/api/shop/category/${id}/children?only_active=${0}`;
export const getCategoriesWithParentUrl = (id: number) => `/api/shop/category/${id}/parents`;
export const getCategoriesPositionsUrl = (positions: string) =>
  `/api/shop/categories/set_positions?positions=${positions}`;
export const All_REVIEWS_URL = '/api/shop/reviews';
export const PRODUCT_URL = '/api/shop/product';
export const PRODUCTS_URL = '/api/shop/products';
export const getProductByIdUrl = (id: number) => `/api/shop/product/${id}`;
export const getProductsByCategory = (id: number) => `/api/shop/category/${id}/products`;
export const delPhotoFromProduct = (id: number) => `/api/shop/product_photo/${id}`;
export const setProductMainPhotoUrl = (id: number): string =>
  `/api/shop/product_photo/${id}/set_main`;
export const getReviewsUrl = (productId: number, perPage: number, page: number) =>
  `/api/shop/product/${productId}/reviews?page=${page}&per_page=${perPage}`;
export const createReviewUrl = (productId: number) => `/api/shop/product/${productId}/review`;
export const delReviewUrl = (reviewId: number) => `/api/shop/review/${reviewId}`;

export const getProductParamsUrl = () => `/api/shop/product_type/parameters`;
export const getProductParamByIdUrl = (id: number) => `/api/shop/parameter/${id}`;
export const putProductParamUrl = (id: number | string) =>
  `/api/shop/pproduct_type_parameter/${id}`;
export const postProductParamUrl = () => `/api/shop/product_type/parameter`;
export const deleteProductParamUrl = (id: number) => `/api/shop/parameter/${id}`;

export const getOrdersByStatus = (statuses: string) => `/api/shop/orders?statuses=${statuses}`;
export const getOrderurl = (id: number) => `/api/shop/order/${id}`;

export const STORES_URL = '/api/shop/stores';
export const CREATE_STORE_URL = '/api/shop/store';
export const getStoreByIdUrl = (id: number) => `api/shop/store/${id}`;

export const uploadLogoCompanyUrl = (id: number) => `/api/company/${id}/upload_logo`;
export const deleteLogoCompanyUrl = (id: number) => `/api/company/${id}/delete_logo`;

export const getManagers = (id: number, page: number, perPage: number) =>
  `/api/company/${id}/managers?page=${page}&per_page=${perPage}`;

export const setWorkingTimeUrl = (id: number) => `/api/shop/store/${id}/working_time`;
export const setDeliveryTimeUrl = (id: number) => `/api/company/${id}/delivery_period`;

export const setDeliveryZoneUrl = (id: number) => `/api/shop/store/${id}`;
export const getDeliveryCostUrl = (id: number, lng: string, lat: string) =>
  `/api/shop/order_delivery_cost?lat=${lat}&lng=${lng}&order_id=${id}`;

export const getCartUrl = (hash: string) => `/api/shop/cart/${hash}`;
export const clearCartUrl = (hash: string) => `/api/shop/cart/${hash}/clear`;
export const createCartUrl = () => `/api/shop/cart`;
export const addProuctToCartUrl = (hash: string, product_id: number, count: number) =>
  `/api/shop/cart/add_product?product_id=${product_id}&count=${count}&hash=${hash}`;
export const setProductCountUrl = (hash: string, product_id: number, count: number) =>
  `/api/shop/cart/${hash}/products_count?product_id=${product_id}&count=${count}`;
export const checkoutCartUrl = (hash: string) => `/api/shop/cart/${hash}/checkout`;

export const stripeReqUrl = (companyId: number, stripeId?: number) => {
  return `/api/stripe/add_company?company_id=${companyId}${
    stripeId ? `&stripe_id=${stripeId}` : ''
  }`;
};
export const stripeCheckoutUrl = (id: number) => `/api/stripe/checkout?order_id=${id}`;
export const stripeStatReqUrl = () => `/api/stripe/login`;
export const getSydneyTime = () => `/api/ping/time`;
