import React, { useEffect, useState } from 'react';
import { Input } from '../../../../../components/base/Input';
import { Text, TextType } from '../../../../../components/base/Text';
import { Button } from '../../../../../components/base/Button';
import { useSigninFormik } from '../../hooks/useSigninFormik';
import { Checkbox } from '../../../../../components/base/Checkbox';
import { LoginType } from '../../../../../interfaces/user';

interface IProps {
  submit: (phone: string, active: boolean) => void;
  verifyType: LoginType;
  onChangeType: (type: LoginType) => void;
}

export const SigninForm: React.FC<IProps> = ({
  submit,
  verifyType = LoginType.phone,
  onChangeType,
}) => {
  const [active, setActive] = useState<boolean>(verifyType === LoginType.email ? true : false);
  useEffect(() => {
    onChangeType(active ? LoginType.email : LoginType.phone);
  }, [active]);
  const formik = useSigninFormik(submit, verifyType === LoginType.email);
  return (
    <form onSubmit={formik.handleSubmit}>
      {verifyType === LoginType.email ? (
        <Input
          placeholder="Enter your e-mail..."
          type="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.email}
          error={Boolean(formik.touched.email && formik.errors.email)}
          errorHelper={(formik.touched.email && formik.errors.email) as string}
        />
      ) : (
        <Input
          phone
          name={'phone'}
          type={'phone'}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.phone}
          error={!!formik.errors.phone}
          placeholder={'+61'}
        />
      )}
      <Checkbox
        checked={verifyType === LoginType.email}
        onPress={() => setActive(!active)}
        Label="I have no phone number"
      />
      <Text type={TextType.caption} margin={{ top: 16, bottom: 16 }}>
        Enter your phone number in international format (+61...). You will receive an SMS with a
        login code
      </Text>

      <Button type={'submit'} text={'Send code'} full />
    </form>
  );
};
