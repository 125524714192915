import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    cart: {
      minWidth: 290,
      background: 'linear-gradient(290.46deg, #FBFAF8 0%, #FFFFFF 100%)',
      border: '1px solid rgba(124, 177, 66, 0.15)',
      boxShadow: '5px 12px 15px -10px rgba(124, 177, 66, 0.25)',
      position: 'sticky',
      borderRadius: 12,
      padding: 16,
      top: 110,

      '& span': {
        width: 50,
        height: 50,
        borderRadius: 12,
        background: '#F9E7E7',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      [theme.breakpoints.down(500)]: {
        margin: `24px 0`,
        width: '100%',
        position: 'static',
      },
      // [theme.breakpoints.down(1366)]: {
      //   marginLeft: '-300px',
      // },
      [theme.breakpoints.down(1251)]: {
        marginLeft: '0px',
      },
    },
  })
);
