import axios from 'axios';
import {
  CREATE_CATEGORY_URL,
  ROOT_CATEGORIES_URL,
  FULL_CATEGORIES_URL,
  getCategoriesWithChildrenUrl,
  getCategoriesWithParentUrl,
  getCategoryUrl,
  getCategoriesPositionsUrl,
} from '../constants';
import { ICategoryItem } from '../pages/home/categories/interfaces';
import { IPaginationProps } from '../interfaces/server';

export function getRootCategories({
  page = 1,
  per_page = 20,
  only_active,
  only_with_products,
}: IPaginationProps & {
  only_active?: 1 | 0;
  only_with_products?: 1 | 0;
}) {
  let url = `${ROOT_CATEGORIES_URL}?page=${page}&per_page=${per_page}`;

  if (only_active !== undefined) {
    url += `&only_active=${only_active}`;
  }

  if (only_with_products !== undefined) {
    url += `&only_with_products=${only_with_products}`;
  }

  return axios.get(url);
}

export function getFullCategories({
  only_active,
  only_with_products,
}: {
  only_active?: 1 | 0;
  only_with_products?: 1 | 0;
} = {}) {
  let url = `${FULL_CATEGORIES_URL}`;

  if (only_active !== undefined) {
    url += `${FULL_CATEGORIES_URL === url ? '?' : '&'}only_active=${only_active}`;
  }

  if (only_with_products !== undefined) {
    url += `${FULL_CATEGORIES_URL === url ? '?' : '&'}only_with_products=${only_with_products}`;
  }

  return axios.get(url);
}

export function getCategoriesWithChildren({ page = 1, per_page = 20, id = 0 }) {
  return axios.get(`${getCategoriesWithChildrenUrl(id)}`);
}

export function getCategoriesWithParent({ id = 0 }) {
  return axios.get(`${getCategoriesWithParentUrl(id)}`);
}

export function getCategoryById(id: number) {
  return axios.get(`${getCategoryUrl(id)}`);
}

export function createCategory(data: ICategoryItem) {
  return axios.post(CREATE_CATEGORY_URL, data);
}

export function editCategory(id: number, data: ICategoryItem) {
  return axios.put(`${getCategoryUrl(id)}`, data);
}

export function deleteCategory(id: number | undefined) {
  return axios.delete(`${getCategoryUrl(id)}`);
}

export function setCategoriesPositions(positions: string) {
  return axios.post(getCategoriesPositionsUrl(positions));
}
