import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { Button } from '../../../../components/base/Button';
import { Input } from '../../../../components/base/Input';
import { colors } from '../../../../constants/colors';

import { actions as authActions } from '../../../../store/ducks/auth.duck';
import { IAppState } from '../../../../store/rootDuck';

const ForgotPassword: React.FC<WrappedComponentProps & TPropsFromRedux> = ({
  intl,
  clearRecoveryPassword,
  recoveryPasswordRequest,
  recoveryPasswordLoading,
  recoveryPasswordSuccess,
  recoveryPasswordErrors,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (recoveryPasswordSuccess || recoveryPasswordErrors) {
      enqueueSnackbar(
        recoveryPasswordSuccess
          ? intl.formatMessage({ id: 'AUTH.FORGOT.EMAIL_SENT' })
          : `${intl.formatMessage({ id: 'ERROR' })} ${recoveryPasswordErrors}`,
        { variant: recoveryPasswordSuccess ? 'success' : 'error' }
      );
    }
    if (recoveryPasswordSuccess) {
      history.push('/auth');
    }
    return () => {
      clearRecoveryPassword();
    };
  }, [
    recoveryPasswordSuccess,
    recoveryPasswordErrors,
    enqueueSnackbar,
    clearRecoveryPassword,
    history,
    intl,
  ]);

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_INCORRECT' }))
              .required(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_REQUIRED' })),
          })}
          onSubmit={(values) => {
            recoveryPasswordRequest({ email: values.email });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="kt-form">
              <div style={{ margin: '35px 10px 10px' }}>
                <div style={{ color: colors.primary.green, fontWeight: 600 }}>
                  {`${intl.formatMessage({
                    id: 'AUTH.FORGOT.INPUT_TEXT1',
                  })} e-mail ${intl.formatMessage({ id: 'AUTH.FORGOT.INPUT_TEXT2' })}`}
                </div>
              </div>

              <div className="form-group">
                <Input
                  placeholder="Enter your e-mail..."
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  errorHelper={(touched.email && errors.email) as string}
                />
                {/* <TextField
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth={true}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={}
                /> */}
              </div>

              <div className="kt-login__actions">
                <Grid container direction="row" justify="space-between" alignItems="stretch">
                  <Button
                    outline
                    onClick={() => history.goBack()}
                    text={intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                  />

                  <Button
                    text={intl.formatMessage({ id: 'AUTH.LOGIN.FORGOT_PASSWORD' })}
                    disabled={recoveryPasswordLoading}
                    loading={recoveryPasswordLoading}
                  />
                </Grid>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    recoveryPasswordLoading: state.auth.recoveryPasswordLoading,
    recoveryPasswordSuccess: state.auth.recoveryPasswordSuccess,
    recoveryPasswordErrors: state.auth.recoveryPasswordErrors,
  }),
  {
    clearRecoveryPassword: authActions.clearRecoveryPassword,
    recoveryPasswordRequest: authActions.recoveryPasswordRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(ForgotPassword));
