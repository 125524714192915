import React, { FC } from 'react';
import { useStyles } from './hooks/useStyles';
import { Text, TextType } from '../Text';
import { colors } from '../../../constants/colors';
import { Iconly } from 'react-iconly';
import { CircularProgress } from '@material-ui/core';

interface IButtonProps {
  light?: boolean;
  text: string;
  text2?: string;
  leftIcon?: string;
  rightIcon?: string;
  full?: boolean;
  onClick?: any;
  loading?: boolean;
  textStyle?: any;
  warning?: boolean;
  outline?: boolean;
}

export const Button: FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const classes = useStyles();
  return (
    <button
      {...props}
      disabled={props.loading || props.disabled}
      type={props.type}
      className={`${classes.button} ${props.className || ''} ${
        props.light
          ? classes.light
          : props.warning
          ? classes.light
          : props.outline
          ? classes.outline
          : classes.default
      }`}
      style={{
        width: props.full ? '100%' : 'initial',

        ...props.style,
      }}
    >
      {props.leftIcon && (
        <Iconly
          name={props.leftIcon}
          set="bold"
          primaryColor={props.light || props.outline ? colors.primary.black : colors.primary.white}
          size="medium"
          style={{ marginRight: 8 }}
        />
      )}

      <div
        style={
          props.text2 ? { display: 'flex', width: '100%', justifyContent: 'space-between' } : {}
        }
      >
        <Text
          type={TextType.semiboldButton}
          color={props.light || props.outline ? colors.primary.black : colors.primary.white}
          style={props.textStyle}
        >
          {props.text}
        </Text>
        {props.text2 && (
          <Text
            type={TextType.semiboldButton}
            color={props.light || props.outline ? colors.primary.black : colors.primary.white}
            style={props.textStyle}
          >
            {props.text2}
          </Text>
        )}
      </div>

      {props.rightIcon && (
        <Iconly
          name={props.rightIcon}
          set="bold"
          primaryColor={props.light || props.outline ? colors.primary.black : colors.primary.white}
          size="medium"
          style={{ marginLeft: 3 }}
        />
      )}

      {props.loading && (
        <CircularProgress
          size={20}
          style={{ color: colors.primary.white, position: 'absolute', right: 16 }}
        />
      )}
    </button>
  );
};
