import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { call, put, takeLatest } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { ITypeParameter } from '../../interfaces/productType';
import {
  deleteProductParam,
  getProductParamById,
  getProductParams,
  postProductParam,
  putProductParam,
} from '../../crud/productType.crud';
import { getResponseMessage } from '../../utils/utils';

const FETCH_PARAMETERS_REQUEST = 'productType/FETCH_PARAMETERS_REQUEST';
const FETCH_PARAMETERS_SUCCESS = 'productType/FETCH_PARAMETERS_SUCCESS';
const FETCH_PARAMETERS_FAIL = 'productType/FETCH_PARAMETERS_FAIL';

const FETCH_PARAMETER_BY_ID_REQUEST = 'productType/FETCH_PARAMETER_BY_ID_REQUEST';
const FETCH_PARAMETER_BY_ID_SUCCESS = 'productType/FETCH_PARAMETER_BY_ID_SUCCESS';
const FETCH_PARAMETER_BY_ID_FAIL = 'productType/FETCH_PARAMETER_BY_ID_FAIL';

const EDIT_ADD_PARAMETER_REQUEST = 'productType/EDIT_ADD_PARAMETER_REQUEST';
const EDIT_ADD_PARAMETER_SUCCESS = 'productType/EDIT_ADD_PARAMETER_SUCCESS';
const EDIT_ADD_PARAMETER_FAIL = 'productType/EDIT_ADD_PARAMETER_FAIL';
const CLEAR_PARAMETER_EDIT_ADD = 'productType/CLEAR_EDIT_ADD';

const DELETE_PARAMETER_REQUEST = 'productType/DELETE_PARAMETER_REQUEST';
const DELETE_PARAMETER_SUCCESS = 'productType/DELETE_PARAMETER_SUCCESS';
const DELETE_PARAMETER_FAIL = 'productType/DELETE_PARAMETER_FAIL';
const CLEAR_PARAMETER_DELETE = 'productType/CLEAR_PARAMETER_DELETE';

export interface IInitialState {
  typeParams: ITypeParameter[];
  paramsLoading: boolean;
  paramsSuccess: boolean;
  paramsError: string | null;

  productParam: ITypeParameter | undefined;
  paramByIdLoading: boolean;
  paramByIdSuccess: boolean;
  paramByIdError: string | null;

  editAddParameterSuccess: boolean;
  editAddParameterLoading: boolean;
  editAddParameterError: string | null;

  deleteParameterSuccess: boolean;
  deleteParameterLoading: boolean;
  deleteParameterError: string | null;
}

const initialState: IInitialState = {
  typeParams: [],
  paramsLoading: false,
  paramsSuccess: false,
  paramsError: null,

  productParam: undefined,
  paramByIdLoading: false,
  paramByIdSuccess: false,
  paramByIdError: null,

  editAddParameterSuccess: false,
  editAddParameterLoading: false,
  editAddParameterError: null,

  deleteParameterSuccess: false,
  deleteParameterLoading: false,
  deleteParameterError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'productTypes', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PARAMETERS_REQUEST: {
        return {
          ...state,
          typeParams: [],
          paramsLoading: true,
          paramsSuccess: false,
          paramsError: null,
        };
      }
      case FETCH_PARAMETERS_SUCCESS: {
        return {
          ...state,
          typeParams: action.payload.data,
          paramsLoading: false,
          paramsSuccess: true,
          paramsError: null,
        };
      }
      case FETCH_PARAMETERS_FAIL: {
        return {
          ...state,
          paramsLoading: false,
          paramsSuccess: false,
          paramsError: action.payload,
        };
      }

      case FETCH_PARAMETER_BY_ID_REQUEST:
        return {
          ...state,
          productParam: undefined,
          paramByIdLoading: true,
          paramByIdSuccess: false,
          paramByIdError: null,
        };
      case FETCH_PARAMETER_BY_ID_SUCCESS:
        return {
          ...state,
          productParam: action.payload.data,
          paramByIdLoading: false,
          paramByIdSuccess: true,
          paramByIdError: null,
        };
      case FETCH_PARAMETER_BY_ID_FAIL:
        return {
          ...state,
          paramByIdLoading: false,
          paramByIdSuccess: false,
          paramByIdError: action.payload,
        };

      case EDIT_ADD_PARAMETER_REQUEST: {
        return {
          ...state,
          editAddParameterSuccess: false,
          editAddParameterLoading: true,
          editAddParameterError: null,
        };
      }
      case EDIT_ADD_PARAMETER_SUCCESS: {
        return {
          ...state,
          productParam: action.payload.data,
          editAddParameterSuccess: true,
          editAddParameterLoading: false,
          editAddParameterError: null,
        };
      }
      case EDIT_ADD_PARAMETER_FAIL: {
        return {
          ...state,
          editAddParameterSuccess: false,
          editAddParameterLoading: false,
          editAddParameterError: action.payload,
        };
      }
      case CLEAR_PARAMETER_EDIT_ADD: {
        return {
          ...state,
          productParam: undefined,
          editAddParameterSuccess: false,
          editAddParameterLoading: false,
          editAddParameterError: null,
        };
      }

      case DELETE_PARAMETER_REQUEST: {
        return {
          ...state,
          deleteParameterSuccess: false,
          deleteParameterLoading: true,
          deleteParameterError: null,
        };
      }
      case DELETE_PARAMETER_SUCCESS: {
        return {
          ...state,
          deleteParameterSuccess: true,
          deleteParameterLoading: false,
          deleteParameterError: null,
        };
      }
      case DELETE_PARAMETER_FAIL: {
        return {
          ...state,
          deleteParameterSuccess: false,
          deleteParameterLoading: false,
          deleteParameterError: action.payload,
        };
      }
      case CLEAR_PARAMETER_DELETE: {
        return {
          ...state,
          deleteParameterSuccess: false,
          deleteParameterLoading: false,
          deleteParameterError: null,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchParameterByIdRequest: (payload: number) =>
    createAction(FETCH_PARAMETER_BY_ID_REQUEST, payload),
  fetchParameterByIdSuccess: (payload: IServerResponse<ITypeParameter>) =>
    createAction(FETCH_PARAMETER_BY_ID_SUCCESS, payload),
  fetchParameterByIdFail: (payload: string) => createAction(FETCH_PARAMETER_BY_ID_FAIL, payload),

  fetchParametersRequest: () => createAction(FETCH_PARAMETERS_REQUEST),
  fetchParametersSuccess: (payload: IServerResponse<ITypeParameter[]>) =>
    createAction(FETCH_PARAMETERS_SUCCESS, payload),
  fetchParametersFail: (payload: string) => createAction(FETCH_PARAMETERS_FAIL, payload),

  fetchEditAddParameterRequest: (payload: { id?: string; data: ITypeParameter }) =>
    createAction(EDIT_ADD_PARAMETER_REQUEST, payload),
  fetchEditAddParameterSuccess: (payload: IServerResponse<ITypeParameter>) =>
    createAction(EDIT_ADD_PARAMETER_SUCCESS, payload),
  fetchEditAddParameterFail: (payload: string) => createAction(EDIT_ADD_PARAMETER_FAIL, payload),
  clearEditAddParameter: () => createAction(CLEAR_PARAMETER_EDIT_ADD),

  fetchDeleteParameterRequest: (payload: number) => createAction(DELETE_PARAMETER_REQUEST, payload),
  fetchDeleteParameterSuccess: () => createAction(DELETE_PARAMETER_SUCCESS),
  fetchDeleteParameterFail: (payload: string) => createAction(DELETE_PARAMETER_FAIL, payload),
  clearDeleteParameter: () => createAction(CLEAR_PARAMETER_DELETE),
};

function* fetchParameterByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<ITypeParameter> } = yield call(() =>
      getProductParamById(payload)
    );
    yield put(actions.fetchParameterByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchParameterByIdFail(getResponseMessage(e)));
  }
}

function* fetchParametersSaga() {
  try {
    const { data }: { data: IServerResponse<ITypeParameter[]> } = yield call(() =>
      getProductParams()
    );
    yield put(actions.fetchParametersSuccess(data));
  } catch (e) {
    yield put(actions.fetchParametersFail(getResponseMessage(e)));
  }
}

function* fetchEditAddParameterSaga({
  payload,
}: {
  payload: { id?: string; data: ITypeParameter };
}) {
  try {
    const { data }: { data: IServerResponse<ITypeParameter> } = yield call(() =>
      payload.id ? putProductParam(payload.id, payload.data) : postProductParam(payload.data)
    );
    yield put(actions.fetchEditAddParameterSuccess(data));
  } catch (e) {
    yield put(actions.fetchEditAddParameterFail(getResponseMessage(e)));
  }
}

function* fetchDeleteParameterSaga({ payload }: { payload: number }) {
  try {
    yield call(() => deleteProductParam(payload));
    yield put(actions.fetchDeleteParameterSuccess());
  } catch (e) {
    yield put(actions.fetchDeleteParameterFail(getResponseMessage(e)));
  }
}

export type TActions = ActionsUnion<typeof actions>;

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchParameterByIdRequest>>(
    FETCH_PARAMETER_BY_ID_REQUEST,
    fetchParameterByIdSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchParametersRequest>>(
    FETCH_PARAMETERS_REQUEST,
    fetchParametersSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchEditAddParameterRequest>>(
    EDIT_ADD_PARAMETER_REQUEST,
    fetchEditAddParameterSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchDeleteParameterRequest>>(
    DELETE_PARAMETER_REQUEST,
    fetchDeleteParameterSaga
  );
}
