import React from 'react';
import { ProductCatalogList } from '../components/old/Menu';

export const menuConfigItems = {
  profile: {
    root: true,
    title: 'SUBMENU.PROFILE',
    translate: 'SUBMENU.PROFILE',
    page: 'settings/profile',
  },

  logout: {
    root: true,
    title: 'MENU.LOGOUT',
    translate: 'MENU.LOGOUT',
    page: 'logout',
    icon: 'flaticon-logout',
  },

  products: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    icon: 'flaticon-list',
    submenu: [
      {
        title: 'Список категорий',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      // {
      //   title: 'Дерево категорий',
      //   page: 'categories/tree',
      //   translate: 'MENU.CATALOG.CATEGORIES.TREE',
      // },
      {
        title: 'Товары компании',
        page: 'products/list',
        translate: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS',
        activePages: ['products/edit', 'products/create', 'products/view'],
      },
      // {
      //   title: 'Торговые точки',
      //   page: 'stores/list',
      //   translate: 'MENU.CATALOG.STORES',
      // },
    ],
  },

  myProducts: {
    title: 'MENU.MY_PRODUCTS',
    translate: 'MENU.MY_PRODUCTS',
    bullet: 'dot',
    root: true,
    icon: 'flaticon-list',
    submenu: [
      {
        title: 'Список категорий',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      // {
      //   title: 'Дерево категорий',
      //   page: 'categories/tree',
      //   translate: 'MENU.CATALOG.CATEGORIES.TREE',
      // },
      {
        title: 'Товары компании',
        page: 'products/list',
        translate: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS',
        activePages: ['products/edit', 'products/create', 'products/view'],
      },
      // {
      //   title: 'Торговые точки',
      //   page: 'stores/list',
      //   translate: 'MENU.CATALOG.STORES',
      // },
    ],
  },

  orders: {
    root: true,
    title: 'Заказы',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    icon: 'flaticon-cart',
    submenu: [
      { translate: 'SUBMENU.ALLORDERS', title: 'SUBMENU.ALLORDERS', page: 'orders/list/full' },
      {
        translate: 'SUBMENU.ABANDONEDCHECKOUTS',
        title: 'UBMENU.ABANDONEDCHECKOUTS',
        page: 'orders/list/abandoned',
      },
    ],
  },

  companies: {
    root: true,
    title: 'MENU.COMPANIES',
    translate: 'MENU.COMPANIES',
    bullet: 'dot',
    submenu: [
      { translate: 'SUBMENU.COMPANIES', title: 'SUBMENU.COMPANIES', page: 'companies/list' },
      {
        translate: 'MENU.CATALOG.STORES',
        title: 'MENU.CATALOG.STORES',
        page: 'stores/list',
      },
    ],
  },

  users: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    icon: 'flaticon-user',
    submenu: [
      // {
      // translate: 'SUBMENU.CUSTOMERS',
      // title: 'SUBMENU.CUSTOMERS',
      // page: 'users/buyers',
      // },
      // {
      //   translate: 'SUBMENU.VENDORS',
      //   title: 'SUBMENU.VENDORS',
      //   page: 'users/vendors',
      //   activePages: ['users/new/vendors', 'users/edit/vendors'],
      // },
      {
        translate: 'SUBMENU.BUYERS',
        title: 'SUBMENU.BUYERS',
        page: 'users/buyers',
        activePages: ['users/new/buyers', 'users/edit/buyers'],
      },
      {
        translate: 'SUBMENU.TEAM',
        title: 'SUBMENU.TEAM',
        page: 'users/admin',
        activePages: ['users/new/admin', 'users/edit/admin'],
      },
      // {
      //   translate: 'SUBMENU.COMPANIES.ADD',
      //   title: 'SUBMENU.COMPANIES.ADD',
      //   page: 'companies/new',
      //   exact: true,
      // },
    ],
  },

  settings: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    icon: 'flaticon-settings',
    submenu: [
      {
        title: 'SUBMENU.PROFILE',
        translate: 'SUBMENU.PROFILE',
        page: 'settings/profile',
      },
      {
        title: 'MENU.PRODUCTS.TYPES',
        translate: 'MENU.PRODUCTS.TYPES',
        page: 'products/params/list',
        activePages: ['products/params/create'],
      },
    ],
  },

  legal: {
    root: true,
    title: 'SUBMENU.LEGAL',
    translate: 'SUBMENU.LEGAL',
    bullet: 'dot',
    icon: 'flaticon-doc',
    submenu: [
      {
        title: 'SUBMENU.LEGAL_TERMS',
        translate: 'SUBMENU.LEGAL_TERMS',
        page: 'documents/legacy',
      },
      {
        title: 'SUBMENU.LEGAL_PRIVACY',
        translate: 'SUBMENU.LEGAL_PRIVACY',
        page: 'documents/privacy',
      },
      {
        title: 'SUBMENU.LEGAL_SHIPPING',
        translate: 'SUBMENU.LEGAL_SHIPPING',
        page: 'documents/shipping',
      },
      {
        title: 'SUBMENU.LEGAL_REFUND',
        translate: 'SUBMENU.LEGAL_REFUND',
        page: 'documents/refund',
      },
    ],
  },

  catalog: {
    root: true,
    title: 'MENU.CATALOG.PRODUCTS',
    translate: 'MENU.CATALOG.PRODUCTS',
    bullet: 'dot',
    icon: 'flaticon-book',
    page: '/products/catalog',
    dynamic: () => <ProductCatalogList />,
    activePages: ['products/catalog'],
  },

  cart: {
    root: true,
    title: 'MENU.CART',
    translate: 'MENU.CART',
    bullet: 'dot',
    icon: 'flaticon-cart',
    page: 'cart',
    activePages: ['/cart'],
  },

  login: {
    root: true,
    title: 'MENU.LOGIN',
    translate: 'MENU.LOGIN',
    bullet: 'dot',
    icon: 'flaticon-login',
    page: 'auth',
  },
};
