import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& label': {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 0.15,
      marginBottom: 8,
    },

    '& div': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      background: '#FFFFFF',
      boxShadow: `0 0 0 1px rgba(124, 177, 66, 0.15)`,
      borderRadius: 12,
      height: 50,
      padding: '0 16px',
      '&:focus-within': {
        borderColor: 'rgba(124, 177, 66, 0.3)',
      },

      '& input': {
        border: 'none',
        boxShadow: 'none',
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: 0.15,

        '&:focus': {
          outline: 'none',
        },
        '&::placeholder': {
          color: '#DED6D6',
        },
      },
    },

    '& p': {
      fontSize: 12,
      letterSpacing: 0.15,
      marginTop: 8,
      color: colors.primary.red,
    },
  },
  actionBtn: {
    backgroundColor: colors.primary.green + ' !important',
    height: '42px !important',
    marginRight: -12,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.primary.greenHover + ' !important',
    },
  },
}));
