import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 1440,
      width: '95%',
      margin: '0 auto',
      marginBottom: 80,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    logo: {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    header: {
      width: '100%',
      marginTop: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& img': {
        cursor: 'pointer',
        width: 187,
      },
      '& .left': {
        width: '20%',
      },
      '& .right': {
        width: '20%',
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    body: {},
    footer: { marginBottom: 36.5 },
  })
);
