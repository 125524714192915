import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './hooks/useStyles';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { connect, ConnectedProps } from 'react-redux';
import { IAppState } from '../../../../store/rootDuck';
import { actions as productActions } from '../../../../store/ducks/product.duck';
import { actions as cartTypesActions } from '../../../../store/ducks/cart.duck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { RouteComponentProps, useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { setLayoutSubheader } from '../../../../utils/layout';
import { API_DOMAIN } from '../../../../constants';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { IProduct } from '../../../../interfaces/product';
import { UserRoles } from '../../../../interfaces/user';
import Preloader from '../../../../components/old/ui/Preloader/Preloader';
import AlertDialog from '../../../../components/old/ui/Dialog/AlertDialog';
import { Header } from './components/Header';
import { CompanyInfo } from './components/CompanyInfo';
import ReviewsList from './components/ReviewList';
import { ProductBody } from './components/ProductBody';
import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { Button } from '../../../../components/base/Button';

const ProductView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  product,
  loading,
  isAuthorized,
  guestCart,
  cart,
  isAdmin,
  me,
  loadingMe,
  editLoading,
  cartCompanyId,
  companyGuestCart,
  countProductsGuest,
  addProductLoading,
  fetch,
  clearProduct,
  setCountGuestCart,
  setProductCount,
  setProductCountLoading,
  fetchMe,
  clearMe,
  addProductToCart,
  setProductGuestCart,
  meAdd,
  addProductError,
  addProductSuccess,
  clearAfterAdd,
  setCartSidebarState,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const [isCartAlertOpen, setCartAlertOpen] = useState<boolean>(false);
  setLayoutSubheader({
    title: intl.formatMessage({ id: 'PRODUCT.TITLE.VIEW' }),
    back: true,
  });

  // const [Left, setLeft] = useState<any>(null);

  useEffect(() => {
    fetch(Number(id));
    return () => {
      clearProduct();
    };
  }, [id]);

  useEffect(() => {
    fetchMe();
    window.scrollTo(0, 0);
    // return () => {
    //   clearMe();
    // };
  }, []);

  const images = useMemo(() => {
    if (product?.photos && product?.photos.length) {
      return product.photos
        .sort((a, b) => (a.main ? -1 : 0))
        .map((el) => ({
          original: API_DOMAIN + el.big,
          thumbnail: API_DOMAIN + el.big,
          renderItem: (item: any) => (
            <img className={classes.galleryImage} src={item.original} alt="photoGallery" />
          ),
        }));
    } else {
      return [
        {
          original: toAbsoluteUrl('/images/placeholder.png'),
          thumbnail: toAbsoluteUrl('/images/placeholder.png'),
          renderItem: (item: any) => (
            <img className={classes.galleryImage} src={item.original} alt="photoGallery" />
          ),
        },
      ];
    }
  }, [product]);
  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      setCartAlertOpen(false);
      clearAfterAdd();
      setCartSidebarState(true);
    },
  });
  const descriptionHtml = useMemo(() => {
    return EditorState.createWithContent(stateFromHTML(product?.description || 'No description'));
  }, [product]);

  // handle add to cart
  const [cartProductId, setCartProductId] = useState<IProduct | null>(null);

  let productCount = useMemo(() => {
    if (!Boolean(isAuthorized) && product && guestCart) {
      const item = guestCart.items.find((item) => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    } else if (Boolean(isAuthorized) && product && cart) {
      const item = cart.items.find((item) => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    }
    return null;
  }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  const isVendor = useMemo(() => me && me.roles.includes(UserRoles.ROLE_VENDOR), [me]);

  const handleCartDialog = useCallback(
    (item) => {
      if (isAuthorized) {
        const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
        if (!newCart) {
          addProductToCart({
            product_id: item.id ? item?.id : 0,
            count: 1,
          });
        } else {
          setCartProductId(item);
          setCartAlertOpen(true);
        }
      } else {
        const newCart = companyGuestCart ? item?.company?.id !== companyGuestCart : false;
        if (!newCart) {
          setProductGuestCart({ data: item, type: 'cart' });
        } else {
          if (countProductsGuest === 0) {
            setProductGuestCart({ data: item, type: 'new' });
          } else {
            setCartProductId(item);
            setCartAlertOpen(true);
          }
        }
      }
      setCartSidebarState(true);
    },
    [addProductToCart, cartCompanyId, companyGuestCart, setProductGuestCart]
  );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading && cartProductId) {
      addProductToCart({
        product_id: cartProductId.id!,
        count: 1,
        newCart: true,
      });
      setCartAlertOpen(false);
      setCartSidebarState(true);
    }
  }, [cartProductId, addProductToCart]);

  const addProductGuestCart = useCallback(() => {
    if (cartProductId) {
      setProductGuestCart({ data: cartProductId, type: 'new' });
      setCartAlertOpen(false);
      setCartSidebarState(true);
    }
  }, [cartProductId, setProductGuestCart]);

  if (loading || loadingMe || !product) return <Preloader />;

  return (
    <div style={{ marginTop: 32 }}>
      {/* <HeaderBase leftSection={Left} /> */}
      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => (isAuthorized ? addCartProductAction() : addProductGuestCart())}
      />

      <div className={classes.container}>
        <div style={{ width: 120 ,marginBottom:20}}>
        <Button
            type={'button'}
            text={'Back'}
            onClick={() => history.goBack()}
            light
            leftIcon={'ChevronLeft'}
            full
        />
        </div>
        <span className={classes.headerTitle}>
          <Header title={`${product?.name}`} />
        </span>
        <ProductBody
          title={product?.name}
          images={images}
          parameter_values={product?.parameter_values || []}
          descriptionHtml={descriptionHtml}
          deliveryTimes={product?.company?.delivery_periods || []}
          cartProps={{
            loading: setProductCountLoading || editLoading || addProductLoading,
            showButton: !Boolean(isAdmin) && !isVendor,
            price: product.price,
            buyClick: () => handleCartDialog(product),
            count: productCount || 0,
            plus: () => {
              isAuthorized
                ? setProductCount({
                  product_id: product.id || 0,
                  count: ++productCount!,
                })
                : setCountGuestCart({
                  product_id: product.id || 0,
                  count: ++productCount!,
                  type: 'inc',
                });
              setCartSidebarState(true);
            },
            minus: () => {
              isAuthorized
                ? setProductCount({
                  product_id: product.id || 0,
                  count: --productCount!,
                })
                : setCountGuestCart({
                  product_id: product.id || 0,
                  count: --productCount!,
                  type: 'dec',
                });
              setCartSidebarState(true);
            },
          }}
        />

        {!!product.company && (
          <div className={classes.companyWrapper}>
            <CompanyInfo
              img={
                product.company.logo
                  ? `${API_DOMAIN}/${product.company.logo}`
                  : toAbsoluteUrl('/images/placeholder.png')
              }
              name={product.company.name}
              click={() => history.push(`/market/${product?.company?.id}`)}
              rating={product?.company?.rating || 0}
            />
          </div>
        )}

        <ReviewsList product={product} meAdd={meAdd} name={me?.fio || ''} />
      </div>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    product: state.products.product,
    loading: state.products.byIdLoading,
    isAuthorized: state.auth.user != null,
    guestCart: state.cart.guestCart,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    editLoading: state.products.editLoading,
    cartCompanyId: state.cart.companyId,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    countProductsGuest: state.cart.guestCart?.items.length,
    addProductLoading: state.cart.addProductLoading,
    meAdd: state.products.reviewsProduct?.meAdd || false,
    addProductSuccess: state.cart.addProductSuccess,
    addProductError: state.cart.addProductError,
  }),
  {
    fetch: productActions.fetchByIdRequest,
    clearProduct: productActions.clearProduct,
    setProductGuestCart: cartTypesActions.setProductGuestCart,
    setCartSidebarState: cartTypesActions.setCartSidebarState,
    setCountGuestCart: cartTypesActions.setCountGuestCart,
    setProductCount: cartTypesActions.setProductCountRequest,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    addProductToCart: cartTypesActions.addProductRequest,
    clearAfterAdd: cartTypesActions.clearAfterGuestCartAdd,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductView);
