import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  getCompanies,
  createCompany,
  editCompany,
  getCompany,
  delCompany,
  searchCompanies,
  uploadLogo,
  deleteLogo,
  setDeliveryTime,
  unbindManager,
  sendStripeRequest,
  sendStripeStatRequest,
} from '../../crud/companies.crud';
import {
  ICompany,
  ICompanyEditProps,
  ICompanyAddProps,
  IEditDeliveryTime,
} from '../../interfaces/companies';

const CLEAR_FETCH = 'companies/CLEAR_FETCH';
const FETCH_REQUEST = 'companies/FETCH_REQUEST';
const FETCH_SUCCESS = 'companies/FETCH_SUCCESS';
const FETCH_FAIL = 'companies/FETCH_FAIL';
const CLEAR_COMPANIES = 'companies/CLEAR_COMPANIES';

const CLEAR_FETCH_BY_ID = 'companies/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'companies/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'companies/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'companies/FETCH_BY_ID_FAIL';

const CLEAR_UPLOAD_LOGO = 'companies/CLEAR_UPLOAD_LOGO';
const UPLOAD_LOGO_REQUEST = 'companies/UPLOAD_LOGO_REQUEST';
const UPLOAD_LOGO_SUCCESS = 'companies/UPLOAD_LOGO_SUCCESS';
const UPLOAD_LOGO_FAIL = 'companies/UPLOAD_LOGO_FAIL';

const CLEAR_DELETE_LOGO = 'companies/CLEAR_DELETE_LOGO';
const DELETE_LOGO_REQUEST = 'companies/DELETE_LOGO_REQUEST';
const DELETE_LOGO_SUCCESS = 'companies/DELETE_LOGO_SUCCESS';
const DELETE_LOGO_FAIL = 'companies/DELETE_LOGO_FAIL';

const CLEAR_SEARCH = 'companies/CLEAR_SEARCH';
const SEARCH_REQUEST = 'companies/SEARCH_REQUEST';
const SEARCH_SUCCESS = 'companies/SEARCH_SUCCESS';
const SEARCH_FAIL = 'companies/SEARCH_FAIL';

const CLEAR_EDIT = 'companies/CLEAR_EDIT';
const ADD_REQUEST = 'companies/ADD_REQUEST';
const EDIT_REQUEST = 'companies/EDIT_REQUEST';
const EDIT_SUCCESS = 'companies/EDIT_SUCCESS';
const EDIT_FAIL = 'companies/EDIT_FAIL';

const CLEAR_DEL_COMPANY = 'companies/CLEAR_DEL_COMPANY';
const DEL_COMPANY_REQUEST = 'companies/DEL_COMPANY_REQUEST';
const DEL_COMPANY_SUCCESS = 'companies/DEL_COMPANY_SUCCESS';
const DEL_COMPANY_FAIL = 'companies/DEL_COMPANY_FAIL';

const SET_DELIVERY_TIME_REQUEST = 'companies/SET_DELIVERY_TIME_REQUEST';
const SET_DELIVERY_TIME_SUCCESS = 'companies/SET_DELIVERY_TIME_SUCCESS';
const SET_DELIVERY_TIME_FAIL = 'companies/SET_DELIVERY_TIME_FAIL';
const CLEADELIVERY_TIME = 'companies/CLEADELIVERY_TIME';
const UNBIND_MANAGER = 'companies/UNBIND_MANAGER';
const UNBIND_SUCCESS = 'companies/UNBIND_SUCCESS';
const UNBIND_ERROR = 'companies/UNBIND_ERROR';

const STRIPE_STATUS = 'companies/STRIPE_STATUS';
const STRIPE_REQUEST = 'companies/STRIPE_REQUEST';
const STRIPE_REQUEST_SUCCESS = 'companies/STRIPE_REQUEST_SUCCESS';
const STRIPE_REQUEST_ERROR = 'companies/STRIPE_REQUEST_ERROR';
const CLEAR_STRIPE_REQUEST = 'companies/CLEAR_STRIPE_REQUEST';

const STRIPE_STAT_REQUEST = 'companies/STRIPE_STAT_REQUEST';
const STRIPE_STAT_ERROR = 'companies/STRIPE_STAT_ERROR';
const STRIPE_STAT_SUCCESS = 'companies/STRIPE_STAT_SUCCESS';
const CLEAR_STRIPE_STAT = 'companies/CLEAR_STRIPE_STAT';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  companies: ICompany[];
  loading: boolean;
  success: boolean;
  error: string | null;

  company: ICompany | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  searchLoading: boolean;
  searchSuccess: boolean;
  searchError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delCompanyLoading: boolean;
  delCompanySuccess: boolean;
  delCompanyError: string | null;

  logoLoading: boolean;
  logoSuccess: boolean;
  logoError: string | null;

  deleteLogoLoading: boolean;
  deleteLogoSuccess: boolean;
  deleteLogoError: string | null;
  unbindLoading: boolean;
  unbindSuccess: boolean;
  unbindError: string | null;
  setDeliveryTimeLoading: boolean;
  setDeliveryTimeSuccess: boolean;
  setDeliveryTimeError: string | null;

  stripeRequestLoading: boolean;
  stripeRequestError: boolean | string;
  stripeRequestSuccess: boolean | string;
  stripeStatus: any;
}

const defaultPaginatorProps = { page: 1, per_page: 20, total: 0 };

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  companies: [],
  loading: false,
  success: false,
  error: null,

  company: null,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  searchLoading: false,
  searchSuccess: false,
  searchError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delCompanyLoading: false,
  delCompanySuccess: false,
  delCompanyError: null,

  logoLoading: false,
  logoSuccess: false,
  logoError: null,

  unbindLoading: false,
  unbindSuccess: false,
  unbindError: null,

  deleteLogoLoading: false,
  deleteLogoSuccess: false,
  deleteLogoError: null,

  setDeliveryTimeLoading: false,
  setDeliveryTimeSuccess: false,
  setDeliveryTimeError: null,

  stripeRequestLoading: false,
  stripeRequestError: false,
  stripeRequestSuccess: false,
  stripeStatus: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'companies', whitelist: ['companies', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_UPLOAD_LOGO: {
        return { ...state, logoLoading: false, logoSuccess: false, logoError: null };
      }

      case UPLOAD_LOGO_REQUEST: {
        return {
          ...state,
          logoLoading: true,
          logoSuccess: false,
          logoError: null,
        };
      }
      case UNBIND_MANAGER: {
        return {
          ...state,
          unbindLoading: true,
          unbindError: null,
          unbindSuccess: false,
        };
      }
      case UNBIND_ERROR: {
        return {
          ...state,
          unbindError: action.payload,
          unbindLoading: false,
          unbindSuccess: false,
        };
      }
      case UNBIND_SUCCESS: {
        return {
          ...state,
          unbindError: null,
          unbindLoading: false,
          unbindSuccess: true,
        };
      }
      case UPLOAD_LOGO_SUCCESS: {
        return {
          ...state,
          logoLoading: false,
          logoSuccess: true,
        };
      }

      case UPLOAD_LOGO_FAIL: {
        return { ...state, logoLoading: false, logoError: action.payload };
      }

      case CLEAR_DELETE_LOGO: {
        return {
          ...state,
          deleteLogoLoading: false,
          deleteLogoSuccess: false,
          deleteLogoError: null,
        };
      }

      case DELETE_LOGO_REQUEST: {
        return {
          ...state,
          deleteLogoLoading: true,
          deleteLogoSuccess: false,
          deleteLogoError: null,
        };
      }

      case DELETE_LOGO_SUCCESS: {
        return {
          ...state,
          deleteLogoLoading: false,
          deleteLogoSuccess: true,
        };
      }

      case DELETE_LOGO_FAIL: {
        return { ...state, deleteLogoLoading: false, deleteLogoError: action.payload };
      }

      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false };
      }

      case CLEAR_COMPANIES: {
        return { ...state, ...defaultPaginatorProps, companies: [] };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          companies: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          companies: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_SEARCH: {
        return {
          ...state,
          companies: [],
          searchLoading: false,
          searchError: null,
          searchSuccess: false,
        };
      }

      case SEARCH_REQUEST: {
        return {
          ...state,
          companies: [],
          searchLoading: true,
          searchError: null,
          searchSuccess: false,
        };
      }

      case SEARCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          companies: action.payload.data,
          searchLoading: false,
          searchSuccess: true,
        };
      }

      case SEARCH_FAIL: {
        return { ...state, searchLoading: false, searchError: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return { ...state, company: null, byIdLoading: false, byIdError: null, byIdSuccess: false };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          company: null,
          byIdLoading: true,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return { ...state, company: action.payload.data, byIdLoading: false, byIdSuccess: true };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          company: null,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_SUCCESS: {
        return {
          ...state,
          editLoading: false,
          editSuccess: true,
          company: action.payload,
        };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_DEL_COMPANY: {
        return {
          ...state,
          delCompanyLoading: false,
          delCompanyError: null,
          delCompanySuccess: false,
        };
      }

      case DEL_COMPANY_REQUEST: {
        return {
          ...state,
          delCompanyLoading: true,
          delCompanyError: null,
          delCompanySuccess: false,
        };
      }

      case DEL_COMPANY_SUCCESS: {
        return { ...state, delCompanyLoading: false, delCompanySuccess: true };
      }

      case DEL_COMPANY_FAIL: {
        return { ...state, delCompanyLoading: false, delCompanyError: action.payload };
      }

      case CLEADELIVERY_TIME: {
        return {
          ...state,
          setDeliveryTimeLoading: false,
          setDeliveryTimeSuccess: false,
          setDeliveryTimeError: null,
        };
      }

      case SET_DELIVERY_TIME_REQUEST: {
        return {
          ...state,
          setDeliveryTimeLoading: true,
          setDeliveryTimeSuccess: false,
          setDeliveryTimeError: null,
        };
      }

      case SET_DELIVERY_TIME_SUCCESS: {
        return {
          ...state,
          setDeliveryTimeLoading: false,
          setDeliveryTimeSuccess: true,
          company: action.payload.data,
        };
      }

      case SET_DELIVERY_TIME_FAIL: {
        return { ...state, setDeliveryTimeLoading: false, setDeliveryTimeError: action.payload };
      }
      case STRIPE_STATUS: {
        return {
          ...state,
          stripeStatus: action.payload,
          stripeRequestLoading: false,
          stripeRequestError: false,
        };
      }
      case STRIPE_REQUEST: {
        return {
          ...state,
          stripeRequestLoading: true,
          stripeRequestError: false,
          stripeRequestSuccess: false,
        };
      }
      case STRIPE_REQUEST_ERROR: {
        return {
          ...state,
          stripeRequestLoading: false,
          stripeRequestError: action.payload,
          stripeRequestSuccess: false,
        };
      }
      case STRIPE_REQUEST_SUCCESS: {
        return {
          ...state,
          stripeRequestLoading: false,
          stripeRequestError: false,
          stripeRequestSuccess: action.payload,
        };
      }
      case CLEAR_STRIPE_REQUEST: {
        return {
          ...state,
          stripeRequestLoading: false,
          stripeRequestError: false,
          stripeRequestSuccess: false,
        };
      }
      case STRIPE_STAT_REQUEST: {
        return {
          ...state,
          stripeStatLoading: true,
          stripeStatError: false,
          stripeStatSuccess: false,
        };
      }
      case STRIPE_STAT_ERROR: {
        return {
          ...state,
          stripeStatLoading: false,
          stripeStatError: true,
          stripeStatSuccess: false,
        };
      }
      case STRIPE_STAT_SUCCESS: {
        return {
          ...state,
          stripeStatLoading: false,
          stripeStatError: false,
          stripeStatSuccess: action.payload,
        };
      }
      case CLEAR_STRIPE_STAT: {
        return {
          ...state,
          stripeStatLoading: false,
          stripeStatError: false,
          stripeStatSuccess: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: { page: number; perPage: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<ICompany[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  clearCompanies: () => createAction(CLEAR_COMPANIES),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: { id: number; location?: any }) =>
    createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<ICompany>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),
  unbindManager: (payload: { company_id: number; manager_id: number; bind: boolean }) =>
    createAction(UNBIND_MANAGER, payload),
  unbindSuccess: () => createAction(UNBIND_SUCCESS),
  unbindError: (err: string) => createAction(UNBIND_ERROR, err),
  clearSearch: () => createAction(CLEAR_SEARCH),
  searchRequest: (payload: number) => createAction(SEARCH_REQUEST, payload),
  searchSuccess: (payload: IServerResponse<ICompany[]>) => createAction(SEARCH_SUCCESS, payload),
  searchFail: (payload: string) => createAction(SEARCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: ICompanyAddProps) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: {
    id: number;
    data: ICompanyEditProps;
    unbind?: boolean;
    history?: any;
  }) => createAction(EDIT_REQUEST, payload),
  editSuccess: (data: ICompany) => createAction(EDIT_SUCCESS, data),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  clearDelCompany: () => createAction(CLEAR_DEL_COMPANY),
  delCompanyRequest: (payload: number) => createAction(DEL_COMPANY_REQUEST, payload),
  delCompanySuccess: () => createAction(DEL_COMPANY_SUCCESS),
  delCompanyFail: (payload: string) => createAction(DEL_COMPANY_FAIL, payload),

  clearUploadLogo: () => createAction(CLEAR_UPLOAD_LOGO),
  uploadLogoRequest: (payload: { id: number; data: FormData }) =>
    createAction(UPLOAD_LOGO_REQUEST, payload),
  uploadLogoSuccess: () => createAction(UPLOAD_LOGO_SUCCESS),
  uploadLogoFail: (payload: string) => createAction(UPLOAD_LOGO_FAIL, payload),

  clearDeleteLogo: () => createAction(CLEAR_DELETE_LOGO),
  deleteLogoRequest: (payload: { id: number }) => createAction(DELETE_LOGO_REQUEST, payload),
  deleteLogoSuccess: () => createAction(DELETE_LOGO_SUCCESS),
  deleteLogoFail: (payload: string) => createAction(DELETE_LOGO_FAIL, payload),

  setDeliveryTimeRequest: (payload: { id: number; data: IEditDeliveryTime }) =>
    createAction(SET_DELIVERY_TIME_REQUEST, payload),
  setDeliveryTimeFail: (payload: string) => createAction(SET_DELIVERY_TIME_FAIL, payload),
  setDeliveryTimeSuccess: (payload: IServerResponse<ICompany>) =>
    createAction(SET_DELIVERY_TIME_SUCCESS, payload),
  clearSetDeliveryTime: () => createAction(CLEADELIVERY_TIME),

  stripeRequest: (payload: { companyId: number; stripeId?: number, checkStripeStatus?: boolean }) =>
    createAction(STRIPE_REQUEST, payload),
  stripeRequestSuccess: (payload: any) => createAction(STRIPE_REQUEST_SUCCESS, payload),
  stripeStatus: (payload: any) => createAction(STRIPE_STATUS, payload),
  stripeRequestError: (payload: any) => createAction(STRIPE_REQUEST_ERROR, payload),
  clearStripeReq: () => createAction(CLEAR_STRIPE_REQUEST),

  stripeStatRequest: () => createAction(STRIPE_STAT_REQUEST),
  stripeStatRequestError: () => createAction(STRIPE_STAT_ERROR),
  stripeStatRequestSuccess: (payload?: any) => createAction(STRIPE_STAT_SUCCESS, payload),
  clearStripeStatRequest: (payload?: any) => createAction(CLEAR_STRIPE_STAT, payload),
};

export type TActions = ActionsUnion<typeof actions>;
function* unbindSaga({
  payload,
}: {
  payload: { company_id: number; manager_id: number; bind: boolean };
}) {
  try {
    yield call(() => unbindManager(payload.company_id, payload.manager_id, payload.bind));
    yield put(actions.unbindSuccess());
    setTimeout(() => window.location.reload(), 1000);
  } catch (e) {
    yield put(actions.unbindError(e?.response?.data?.message));
  }
}
function* fetchSaga({ payload }: { payload: { page: number; perPage: number } }) {
  try {
    const { data }: { data: IServerResponse<ICompany[]> } = yield call(() =>
      getCompanies(payload.page, payload.perPage)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: { id: number; location?: any } }) {
  try {
    const { data }: { data: IServerResponse<ICompany> } = yield call(() =>
      getCompany(payload.id, payload.location)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* searchSaga({ payload }: { payload: number }) {
  try {
    yield put(actions.clearFetch());
    const { data }: { data: IServerResponse<ICompany[]> } = yield call(() =>
      searchCompanies(payload)
    );
    yield put(actions.searchSuccess(data));
  } catch (e) {
    yield put(actions.searchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: ICompanyAddProps }) {
  try {
    const { data }: { data: IServerResponse<ICompany> } = yield call(() => createCompany(payload));
    yield put(actions.editSuccess(data.data));
    payload.history.push('/companies/list');
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({
  payload,
}: {
  payload: { id: number; data: ICompanyEditProps; unbind?: boolean };
}) {
  try {
    const { data }: { data: IServerResponse<ICompany> } = yield call(() => {
      return editCompany(payload.id, payload.data, payload.unbind);
    });

    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delCompanySaga({ payload }: { payload: number }) {
  try {
    yield call(() => delCompany(payload));
    yield put(actions.delCompanySuccess());
  } catch (e) {
    yield put(actions.delCompanyFail(e?.response?.data?.message || 'Network error'));
  }
}

function* uploadLogoSaga({ payload }: { payload: { id: number; data: FormData } }) {
  try {
    yield call(() => uploadLogo(payload.id, payload.data));
    yield put(actions.uploadLogoSuccess());
  } catch (e) {
    yield put(actions.uploadLogoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* deleteLogoSaga({ payload }: { payload: { id: number } }) {
  try {
    yield call(() => deleteLogo(payload.id));
    yield put(actions.deleteLogoSuccess());
  } catch (e) {
    yield put(actions.deleteLogoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* setDeliveryTimeSaga({ payload }: { payload: { id: number; data: IEditDeliveryTime } }) {
  try {
    const { data }: { data: IServerResponse<ICompany> } = yield call(() =>
      setDeliveryTime(payload.id, payload.data)
    );
    yield put(actions.setDeliveryTimeSuccess(data));
  } catch (e) {
    yield put(actions.setDeliveryTimeFail(e?.response?.data?.message || 'Network error'));
  }
}

function* stripeRequestSaga({ payload }: { payload: { companyId: number; stripeId?: number, checkStripeStatus?: boolean } }) {
  try {
    const { data }: { data: any } = yield call(() =>
      sendStripeRequest(payload.companyId, payload.stripeId)
    );
    console.log('stripedata', data);
    // debugger;

    yield put(actions.stripeStatus(data?.data[0]));


    if (!payload.checkStripeStatus) {
      yield put(actions.stripeRequestSuccess(data?.data[0]?.link));
    }
  } catch (e) {
    yield put(actions.stripeRequestError(e.response));
  }
}
function* stripeStatRequestSaga() {
  try {
    const { data }: { data: any } = yield call(() => sendStripeStatRequest());
    yield put(actions.stripeStatRequestSuccess(data?.data[0]?.link));
  } catch (e) {
    yield put(actions.stripeStatRequestError());
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.unbindManager>>(UNBIND_MANAGER, unbindSaga);
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.searchRequest>>(SEARCH_REQUEST, searchSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.uploadLogoRequest>>(
    UPLOAD_LOGO_REQUEST,
    uploadLogoSaga
  );
  yield takeLatest<ReturnType<typeof actions.deleteLogoRequest>>(
    DELETE_LOGO_REQUEST,
    deleteLogoSaga
  );
  yield takeLatest<ReturnType<typeof actions.delCompanyRequest>>(
    DEL_COMPANY_REQUEST,
    delCompanySaga
  );
  yield takeLatest<ReturnType<typeof actions.setDeliveryTimeRequest>>(
    SET_DELIVERY_TIME_REQUEST,
    setDeliveryTimeSaga
  );

  yield takeLatest<ReturnType<typeof actions.stripeRequest>>(STRIPE_REQUEST, stripeRequestSaga);
  yield takeLatest<ReturnType<typeof actions.stripeStatRequest>>(
    STRIPE_STAT_REQUEST,
    stripeStatRequestSaga
  );
}
