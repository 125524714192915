import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../../_metronic/index';
import { IAppState } from '../../../../store/rootDuck';
import { actions as authActions } from '../../../../store/ducks/auth.duck';
import { actions as cartActions } from '../../../../store/ducks/cart.duck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';

const Logout: React.FC<TPropsFromRedux> = ({ logout, hasAuthToken, clearCart, clearMe }) => {
  useEffect(() => {
    clearCart();
    logout();
    clearMe();
  }, [logout]);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/main" />;
};

const connector = connect(
  (state: IAppState) => ({
    hasAuthToken: Boolean(state.auth.authToken),
  }),
  {
    logout: authActions.logout,
    clearCart: cartActions.clearCartRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Logout);
