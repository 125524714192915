import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { LayoutSplashScreen } from '../../../_metronic';
import getMenuConfig from '../../router/MenuConfig';
import * as builder from '../../../_metronic/ducks/builder';
import { actions as cartActions } from '../../store/ducks/cart.duck';
import { actions as profileActions } from '../../store/ducks/profile.duck';
import { IAppState } from '../../store/rootDuck';
import Preloader from '../../components/old/ui/Preloader/Preloader';
import { UserRoles } from '../../interfaces/user';
import ProductView from './products/ProductView';
import CompanyView from './companies/CompanyView';

const CategoriesPage = lazy(() => import('./categories'));
const UsersPage = lazy(() => import('./users'));
const CompaniesPage = lazy(() => import('./companies'));
const UserDocPage = lazy(() => import('./userDocs'));
const SettingsPage = lazy(() => import('./settings'));
const ProductsPage = lazy(() => import('./products'));
const OrdersPage = lazy(() => import('./orders'));
const StorePage = lazy(() => import('./store'));
const CartPage = lazy(() => import('./cart'));
const Messages = lazy(() => import('./messages'));
const CollectionsPage = lazy(() => import('./collections'));
const Reviews = lazy(() => import('./Reviews'));
const MarketPage = lazy(() => import('./MarketPage'));

const HomePage: React.FC<TPropsFromRedux & { userLastLocation: any }> = ({
  userRoles,
  isAuthorized,
  setMenuConfig,
  getOrCreateCart,
  getOrCreateCartLoading,
  userLastLocation,
  editMe,
  getLocalGuestCart,
  isAdmin,
  me,
}) => {
  const [menuConfig] = useState(getMenuConfig(userRoles, isAuthorized));
  const history = useHistory();

  useEffect(() => {
    setMenuConfig(menuConfig);
  }, [setMenuConfig, menuConfig]);

  useEffect(() => {
    if(userLastLocation == '/main'){
      history.push('/orders/list/full');
    }
  }, []);

  // Проверка на существование корзины у пользователя
  useEffect(() => {
    const localCart = localStorage.getItem('guestCart');
    if (userRoles && userRoles.includes(UserRoles.ROLE_BUYER) && localCart) {
      const localLocation = localStorage.getItem('location');
      const parseLocalLocation = localLocation && JSON.parse(localLocation);
      if (Boolean(parseLocalLocation)) {
        editMe({ location: parseLocalLocation });
        localStorage.removeItem('location');
      }
      history.replace('/cart');
    }
    if (userRoles && !userRoles.includes(UserRoles.ROLE_VENDOR) && !Boolean(isAdmin)) {
      getOrCreateCart();
    }
  }, [getOrCreateCart, isAdmin, userRoles]);

  useEffect(() => {
    !Boolean(isAuthorized) && getLocalGuestCart();
  }, [isAuthorized, getLocalGuestCart]);

  useEffect(() => {
    if (isAuthorized) {
      // history.push('/orders/list/full');
    }
  }, [isAuthorized]);

  if (getOrCreateCartLoading) return <Preloader />;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="orders/list/full" />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/categories" component={CategoriesPage} />
        <Route path="/messages" component={Messages} />
        <Route path="/products" component={ProductsPage} />
        <Route path="/documents" component={UserDocPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/companies" component={CompaniesPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/stores" component={StorePage} />
        <Route path="/collections" component={CollectionsPage} />
        <Route path="/market/:id" component={MarketPage} />
        <Route path="/cart" component={CartPage} />
        <Route path="/viewproduct/:id" component={ProductView} />
        <Route path="/viewcompany/:id" component={CompanyView} />
        <Route path="/reviews/:companyId?" component={Reviews} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
    isAuthorized: state.auth.user != null,
    getOrCreateCartLoading: state.cart.getOrCreateCartLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
  }),
  {
    editMe: profileActions.editRequest,
    setMenuConfig: builder.actions.setMenuConfig,
    getOrCreateCart: cartActions.getOrCreateRequest,
    getLocalGuestCart: cartActions.getLocalGuestCart,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
