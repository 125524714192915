import axios from 'axios';
import {
  createReviewUrl,
  delPhotoFromProduct,
  delReviewUrl,
  getProductByIdUrl,
  getReviewsUrl,
  All_REVIEWS_URL,
  PRODUCT_URL,
  PRODUCTS_URL,
  setProductMainPhotoUrl,
} from '../constants';
import { IProductFilter, IReviewCreate } from '../interfaces/product';
import { camelToSnakeCase } from '../utils/utils';

export const createProduct = (files: FormData) => {
  return axios.post(PRODUCT_URL, files, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};
export const getAllRewiews = (companyId: string = '') => {
  return axios.get(All_REVIEWS_URL + (companyId ? `/?company_id=${companyId}` : ''));
};
export const editProduct = (id: number, files: FormData) => {
  return axios.post(getProductByIdUrl(id), files, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const deleteProduct = (id: number | undefined) => {
  return axios.delete(getProductByIdUrl(id || -1));
};

export const getProductById = (id: number) => {
  return axios.get(getProductByIdUrl(id));
};

export const getFilters = () => {
  return axios.get('/api/shop/filters');
};

const onlyActive = (url: string, only_active?: 1 | 0) => {
  if (only_active !== undefined) {
    return `${url}&only_active=${only_active}`;
  } else {
    return url;
  }
};

const withFilter = (url: string, filter?: IProductFilter) => {
  if (filter) {
    Object.keys(filter).forEach((key: string) => {
      if (key !== 'params') {
        const value = (filter as any)[key];
        if (!!value) {
          url += `&${camelToSnakeCase(key)}=${(filter as any)[key]}`;
        }
      }
    });
    return url;
  }

  return url;
};

const filterToFormData = (
  filter: IProductFilter = {
    params: {},
  }
) => {
  if (Object.keys(filter.params).length) {
    const data = new FormData();

    const body = Object.keys(filter.params)
      .filter((key) => {
        const isArray = Array.isArray(filter.params[key]);
        const values = isArray
          ? filter.params[key].filter((item: any) => item)
          : filter.params[key]
            ? [filter.params[key]]
            : null;

        return isArray ? !!values.length : !!values;
      })
      .map((key) => {
        const isArray = Array.isArray(filter.params[key]);
        const values = isArray ? filter.params[key] : [filter.params[key]];

        return {
          parameter: +key.replace(/\D/g, ''),
          values,
        };
      });

    data.append('parameters', JSON.stringify(body));

    return data;
  } else {
    return {};
  }
};

export const getProducts = (
  page: number = 1,
  perPage: number = 20,
  companyId?: number | null,
  categoryId?: number,
  only_active?: 1 | 0,
  location?: any = JSON.parse(localStorage.getItem('location')),
  filter?: IProductFilter,
  dayFilter: string = ''
) => {
  const formData = filterToFormData(filter);
  const day = dayFilter !== '' ? `&day=${dayFilter}` : '';

  if ((companyId as number) >= 0 && (categoryId as number) >= 0) {
    return axios.post(
      withFilter(
        onlyActive(
          `/api/shop/category/${categoryId}/products?page=${page}${day}&per_page=${perPage}&company_id=${companyId}&sort_type=new${location ? '&delivery_to_user=true' : ''}`,
          only_active
        ),
        filter
      ),
      formData,
      location && {
        params: {
          lat: location.lat,
          lng: location.lng,
        },
      }
    );
  }
  if ((companyId as number) >= 0 ?? (categoryId as number) === 0) {
    return axios.post(
      withFilter(
        onlyActive(
          `/api/shop/category/${categoryId}/products?company_id=${companyId}page=${page}${day}&per_page=${perPage}${location ? '&delivery_to_user=true' : ''}`,
          only_active
        ),
        filter
      )
    );
  }
  if ((categoryId as number) >= 0) {
    return axios.post(
      withFilter(
        onlyActive(
          `/api/shop/category/${categoryId}/products?page=${page}${day}&per_page=${perPage}${location ? '&delivery_to_user=true' : ''}`,
          only_active
        ),
        filter
      ),
      formData,
      location && {
        params: {
          lat: location.lat,
          lng: location.lng,
        },
      }
    );
  }
  return axios.post(
    withFilter(onlyActive(`${PRODUCTS_URL}?page=${page}&per_page=${perPage}`, only_active), filter),
    formData
  );
};

export const delPhoto = (photoId: number) => {
  return axios.delete(delPhotoFromProduct(photoId));
};

export function setMainPhoto(id: number) {
  return axios.post(setProductMainPhotoUrl(id));
}

export const getReviews = (productId: number, perPage: number, page: number) =>
  axios.get(getReviewsUrl(productId, perPage, page));

export const createReview = (productId: number, body: IReviewCreate) =>
  axios.post(createReviewUrl(productId), body);

export const delReview = (reviewId: number) => axios.delete(delReviewUrl(reviewId));
