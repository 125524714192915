import useStyles from './hooks/useStyles';
import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';
interface IProps {
  open: boolean;
  onClose: () => {} | void;
  closeButton?: boolean;
  title?: string;
  frame?: boolean;
  dark?: boolean;
  className?: string;
  style?: React.CSSProperties;
}
const Dialog: React.FC<IProps> = ({
  open = false,
  onClose,
  children,
  closeButton = true,
  title,
  dark = false,
  frame = true,
  className = '',
  style,
}) => {
  const styles = useStyles();
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0 }}
          className={styles.wrapper}
          onClick={(e) => onClose()}
          style={{
            background: dark ? '#0000003F' : '#00000011',
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              onClose();
            }
          }}
        >
          {!frame && (
            <svg
              className={styles.closeButton}
              width="38"
              height="38"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0002 0.333374C13.7038 0.333374 10.4815 1.31086 7.74067 3.14221C4.99985 4.97357 2.86364 7.57655 1.60218 10.622C0.340717 13.6674 0.010662 17.0185 0.653749 20.2515C1.29684 23.4846 2.88418 26.4543 5.21506 28.7852C7.54593 31.116 10.5156 32.7034 13.7487 33.3465C16.9817 33.9895 20.3328 33.6595 23.3782 32.398C26.4237 31.1366 29.0266 29.0004 30.858 26.2595C32.6894 23.5187 33.6668 20.2964 33.6668 17C33.6668 14.8113 33.2357 12.6441 32.3982 10.622C31.5606 8.59989 30.3329 6.76257 28.7853 5.21493C27.2376 3.66728 25.4003 2.43963 23.3782 1.60205C21.3561 0.76447 19.1889 0.333374 17.0002 0.333374ZM21.5168 19.15C21.673 19.305 21.797 19.4893 21.8817 19.6924C21.9663 19.8955 22.0098 20.1134 22.0098 20.3334C22.0098 20.5534 21.9663 20.7712 21.8817 20.9743C21.797 21.1774 21.673 21.3618 21.5168 21.5167C21.3619 21.6729 21.1776 21.7969 20.9745 21.8815C20.7714 21.9661 20.5535 22.0097 20.3335 22.0097C20.1135 22.0097 19.8956 21.9661 19.6925 21.8815C19.4894 21.7969 19.3051 21.6729 19.1502 21.5167L17.0002 19.35L14.8502 21.5167C14.6952 21.6729 14.5109 21.7969 14.3078 21.8815C14.1047 21.9661 13.8869 22.0097 13.6668 22.0097C13.4468 22.0097 13.229 21.9661 13.0259 21.8815C12.8228 21.7969 12.6384 21.6729 12.4835 21.5167C12.3273 21.3618 12.2033 21.1774 12.1187 20.9743C12.0341 20.7712 11.9905 20.5534 11.9905 20.3334C11.9905 20.1134 12.0341 19.8955 12.1187 19.6924C12.2033 19.4893 12.3273 19.305 12.4835 19.15L14.6502 17L12.4835 14.85C12.1697 14.5362 11.9934 14.1105 11.9934 13.6667C11.9934 13.2229 12.1697 12.7972 12.4835 12.4834C12.7973 12.1695 13.223 11.9932 13.6668 11.9932C14.1107 11.9932 14.5363 12.1695 14.8502 12.4834L17.0002 14.65L19.1502 12.4834C19.464 12.1695 19.8897 11.9932 20.3335 11.9932C20.7773 11.9932 21.203 12.1695 21.5168 12.4834C21.8307 12.7972 22.007 13.2229 22.007 13.6667C22.007 14.1105 21.8307 14.5362 21.5168 14.85L19.3502 17L21.5168 19.15Z"
                fill="white"
              />
            </svg>
          )}
          <motion.div
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.16 }}
            exit={{ scale: 1.1 }}
            className={`${styles.form} ${className}`}
            style={{
              padding: 32,
              background: 'white',
              boxShadow: '0 0 50px 0 #00000033',
              ...style,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {title || closeButton ? (
              <div className={styles.titleBar}>
                {title && <span>{title}</span>}
                {closeButton && (
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => onClose()}
                  >
                    <rect width="31" height="30.9979" rx="15.4989" fill="#484848" />
                    <path
                      d="M17.2608 15.4979L22.6306 10.1405C22.8658 9.90539 22.9979 9.58646 22.9979 9.2539C22.9979 8.92135 22.8658 8.60241 22.6306 8.36726C22.3955 8.13211 22.0765 8 21.744 8C21.4114 8 21.0925 8.13211 20.8573 8.36726L15.5 13.7371L10.1427 8.36726C9.90752 8.13211 9.58859 8 9.25603 8C8.92348 8 8.60454 8.13211 8.36939 8.36726C8.13424 8.60241 8.00213 8.92135 8.00213 9.2539C8.00213 9.58646 8.13424 9.90539 8.36939 10.1405L13.7392 15.4979L8.36939 20.8552C8.25234 20.9713 8.15944 21.1094 8.09604 21.2616C8.03264 21.4138 8 21.577 8 21.7418C8 21.9067 8.03264 22.0699 8.09604 22.2221C8.15944 22.3743 8.25234 22.5124 8.36939 22.6285C8.48548 22.7455 8.6236 22.8384 8.77578 22.9018C8.92795 22.9652 9.09118 22.9979 9.25603 22.9979C9.42089 22.9979 9.58411 22.9652 9.73629 22.9018C9.88847 22.8384 10.0266 22.7455 10.1427 22.6285L15.5 17.2587L20.8573 22.6285C20.9734 22.7455 21.1115 22.8384 21.2637 22.9018C21.4159 22.9652 21.5791 22.9979 21.744 22.9979C21.9088 22.9979 22.072 22.9652 22.2242 22.9018C22.3764 22.8384 22.5145 22.7455 22.6306 22.6285C22.7477 22.5124 22.8406 22.3743 22.904 22.2221C22.9674 22.0699 23 21.9067 23 21.7418C23 21.577 22.9674 21.4138 22.904 21.2616C22.8406 21.1094 22.7477 20.9713 22.6306 20.8552L17.2608 15.4979Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
            ) : null}
            <div className={styles.content}>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export { Dialog };
