import React, { FC } from 'react';
import { useStyles } from './hooks/useStyles';

export const SmsInput: FC<React.InputHTMLAttributes<HTMLInputElement>> = (props) => {
  const classes = useStyles();
  const length = props.value?.toString().length || 0;

  return (
    <div className={classes.container} style={props.style}>
      <input {...props} autoComplete={'off'} maxLength={4} type="numeric" autoFocus />

      <span className={length === 0 ? 'focus' : ''}>{props.value?.toString()[0] || ''}</span>
      <span className={length === 1 ? 'focus' : ''}>{props.value?.toString()[1] || ''}</span>
      <span className={length === 2 ? 'focus' : ''}>{props.value?.toString()[2] || ''}</span>
      <span className={length >= 3 ? 'focus' : ''}>{props.value?.toString()[3] || ''}</span>
    </div>
  );
};
