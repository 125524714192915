import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFormatMessage } from '../../../../hooks/useFormatMessage';

export const getInitValues = (active: boolean) => (!active ? { phone: '' } : { email: '' });

const getValidationSchema = (fm: any, active: boolean) => {
  return !active
    ? Yup.object().shape({
        phone: Yup.string().required(fm('AUTH.INPUT.PHONE_REQUIRED')),
      })
    : Yup.object().shape({
        email: Yup.string()
          .email(fm('AUTH.INPUT.EMAIL_INCORRECT'))
          .required(fm('AUTH.INPUT.EMAIL_REQUIRED')),
      });
};

export const useSigninFormik = (
  submit: (phone: string, active: boolean) => void,
  active: boolean
) => {
  const fm = useFormatMessage();
  const validationSchema = getValidationSchema(fm, active);

  return useFormik({
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: getInitValues(active),
    validationSchema,
    onSubmit: (submitValues) => {
      if (active) submit(submitValues.email as string, active);
      else submit(submitValues.phone as string, active);
    },
  });
};
