import React from 'react';
import { IntlProvider } from 'react-intl';

// import '@formatjs/intl-relativetimeformat/polyfill';
// import '@formatjs/intl-relativetimeformat/locale-data/en';

import enMessages from './messages/en';

const allMessages = {
  en: enMessages,
};

export default function I18nProvider({ children }) {
  // const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages['en'];

  return (
    <IntlProvider locale={'en'} messages={messages}>
      {children}
    </IntlProvider>
  );
}
