import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../constants/colors';
export const useStyles = makeStyles((theme) =>
  createStyles({
    miniLeftSection: {
      display: 'none',
      [theme.breakpoints.down(834)]: {
        display: 'block',
        width: '100%',
      },
    },

    wrapper: {
      marginTop: 64,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(834)]: {
        marginTop: 24,
        padding: '0 16px',
      },
      [theme.breakpoints.down(500)]: {
        marginTop: 16,
      },
    },
    sticky: {
      position: 'fixed',
      background: colors.primary.white,
      padding: 16,
      zIndex: 50,
      width: '100%',
      maxWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(834)]: {
        flexDirection: 'column',
        '& >div:last-child': {
          marginTop: 10,
        },
      },
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      width: '100%',
      maxWidth: 1440,
      [theme.breakpoints.down(500)]: {
        marginTop: 0,
        '& .mini': {
          display: 'block',
        },
        '& .big': {
          display: 'none !important',
        },
      },
    },

    headActions: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px',
      borderRadius: 12,
      border: `1px solid ${colors.primary.lightGrey}`,
      boxShadow: `inset 0 0 2px 0 ${colors.primary.white}, 11px 22px 44px -12px rgba(124, 177, 66, 0.15)`,
      '& >div': {
        cursor: 'pointer',
      },
      '& >div:last-child': {
        marginLeft: 16,
      },
    },
    headActionsAdmin: {
      marginRight: "6%",
    },
    leftSection: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down(834)]: {
        display: 'none',
      },
      maxWidth: '35%',
      marginRight: '100px',
    },
    rightSection: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& button': {
        minWidth: 76,
        width: 76,
        padding: 0,
      },
    },
    mainLogo: {
      cursor: 'pointer',
      width: 187,
      [theme.breakpoints.down(500)]: {
        width: 131,
      },
    },
    mainLogoMini: {
      display: 'none',
      cursor: 'pointer',
      width: 32,
    },
  })
);
