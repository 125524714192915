import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { IAppState } from '../../../../store/rootDuck';
import { useIntl } from 'react-intl';

const ForgotPasswordEmail: React.FC<TPropsFromRedux> = ({ email }) => {
  const intl = useIntl();

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>{intl.formatMessage({ id: 'EMAIL.NEW' })}</h3>
        </div>
        <div className="form-group">{email}</div>
      </div>
    </div>
  );
};

const connector = connect((state: IAppState) => ({
  email: state.auth.email,
}));

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ForgotPasswordEmail);
