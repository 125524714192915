import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    authModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      borderRadius: 12,
      padding: 32,
      width: 364,
      maxWidth: '90%',

      '&:focus': {
        outline: 'none',
      },

      '& h2': {
        fontWeight: 600,
        fontSize: 19,
        letterSpacing: 0.15,
        color: '#1A1A1A',
        marginBottom: 16,
      },
    },
    tabs: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,

      '& p': {
        fontWeight: 600,
        fontSize: 16,
        color: '#484848',
        marginRight: 16,
        marginBottom: 0,
        paddingBottom: 4,
        borderBottom: '2px solid transparent',
        cursor: 'pointer',

        '&.active': {
          borderBottomColor: '#1A1A1A',
          color: '#1A1A1A',
        },
      },
    },
    helpText: {
      '& span': {
        color: '#7CB142',
        cursor: 'pointer',
        textDecoration: 'underline !important',
      },
    },
    tabWrapper: {
      display: 'flex',
      marginBottom: 16,
      '&>div': {
        position: 'relative',
        cursor: 'pointer',
        '&>p': {
          marginBottom: '8px !important',
        },
      },
    },
    activeTab: {
      '&::after': {
        position: 'absolute',
        content: '""',
        left: 0,
        bottom: 0,
        height: 2,
        background: 'black',
        width: '100%',
      },
    },
  })
);
