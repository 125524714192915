import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { Link } from 'react-router-dom';
import { ICategoryItem } from '../../../../../pages/home/categories/interfaces';

const useTreeItemStyles = makeStyles((theme) => ({}));

const treeNode = (item: any, classes: any, activeId: number, select: (ids: string[]) => void) => {
  return (
    <li
      onClick={() => select([item.id.toString()])}
      aria-haspopup="true"
      data-placement="right"
      className={`kt-menu__item ${
        item.id === activeId ? 'kt-menu__item--active kt-menu__item--here' : ''
      } `}
    >
      <Link to={`/products/catalog/${item.id}`} className="kt-menu__link kt-menu__toggle">
        <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
          <span />
        </i>
        <span className="kt-menu__link-text" style={{ whiteSpace: 'nowrap' }}>
          {item.name}
        </span>
      </Link>
    </li>
  );
};

const renderTree = (
  nodes: any,
  classes: any,
  activeId: number,
  onSelect: (ids: string[]) => void
) => {
  return (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id?.toString() || 'key'}
      label={treeNode(nodes, classes, activeId, (val) => onSelect(val))}
    >
      {Array.isArray(nodes.children)
        ? nodes.children
            .filter((node: ICategoryItem) => node.active && !!node.products_num)
            .map((node: any) =>
              renderTree(node, classes, activeId, (val) => onSelect([...val, nodes.id.toString()]))
            )
        : null}
    </TreeItem>
  );
};

const Tree: React.FC<any> = ({ categories, activeId }) => {
  const innerClasses = useTreeItemStyles();
  // const intl = useIntl();
  const [expanded, setExpanded] = useState<string[]>([]);

  categories = categories.filter((cat: ICategoryItem) => cat.active && !!cat.products_num);

  const toggleExpanded = (ids: string[]) => {
    const checkId = ids[0];

    if (expanded.includes(checkId)) {
      setExpanded([...expanded, ...ids].filter((id) => checkId !== id));
    } else {
      setExpanded(ids);
    }
  };

  return (
    <TreeView expanded={expanded}>
      {categories.map((item: any) => renderTree(item, innerClasses, activeId, toggleExpanded))}
    </TreeView>
  );
};

export default Tree;
