import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { actions as authActions } from '../../../../store/ducks/auth.duck';

import TermDialog from '../../components/TermDialog';
import { IAppState } from '../../../../store/rootDuck';
import LoginByPhoneForm from '../../components/LoginByPhoneForm';
import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { UserRoleType } from '../../../../interfaces/user';
import { Text, TextType } from '../../../../components/base/Text';

const Registration: React.FC<TPropsFromRedux & WrappedComponentProps> = ({
  intl,
  clearReg,
  register,
  sendSms,
  regLoading,
  regSuccess,
  regError,
  sendSmsLoading,
  sendSmsSuccess,
  sendSmsErrors,
  loginByPhone,
  loginByPhoneLoading,
  loginByPhoneSuccess,
  clearLoginByPhone,
  clearSendSms,
  loginByPhoneErrors,
  authData,
}) => {
  const history = useHistory();
  const [isAgreementOpen, setOpenUserAgreement] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useCrudSnackbar({
    success: loginByPhoneSuccess,
    error: loginByPhoneErrors,
    clear: clearLoginByPhone,
    successMessage: intl.formatMessage({ id: 'AUTH.LOGIN.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })} ${loginByPhoneErrors}`,
  });

  useCrudSnackbar({
    success: sendSmsSuccess,
    error: sendSmsErrors,
    clear: clearSendSms,
    successMessage: intl.formatMessage({ id: 'AUTH.SEND_SMS.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })} ${sendSmsErrors}`,
  });

  useEffect(() => {
    loginByPhoneSuccess && history.replace('/');
  }, [loginByPhoneSuccess]);

  useEffect(() => {
    if (regError) {
      enqueueSnackbar(regError, {
        variant: 'error',
      });
      clearReg();
    }
    if (regSuccess) {
      history.push('/auth/email-sent/registration');
      clearReg();
    }
  }, [clearReg, enqueueSnackbar, history, regError, regSuccess]);

  useEffect(() => {
    return () => {
      clearReg();
    };
  }, []);

  return (
    <>
      <TermDialog isOpen={isAgreementOpen} handleClose={() => setOpenUserAgreement(false)} />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <Text type={TextType.semiboldH5} margin={{ bottom: 24 }}>
            Sign up
          </Text>

          <Formik
            initialValues={{
              email: '',
              login: '',
              role: 'ROLE_VENDOR',
              acceptTerms: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_FIELD' }))
                .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_FIELD' })),
            })}
            onSubmit={(values) => {
              register({
                email: values.email,
                roles: [values.role as UserRoleType],
                login: values.email,
              });
            }}
          >
            {({ values, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <LoginByPhoneForm
                  sendSms={sendSms}
                  sendSmsLoading={sendSmsLoading}
                  sendSmsSuccess={sendSmsSuccess}
                  login={loginByPhone}
                  loginLoading={loginByPhoneLoading}
                  backButton
                  roles={[values.role as UserRoleType]}
                  visibleAgreement={!sendSmsSuccess}
                  setOpenUserAgreement={() => setOpenUserAgreement(true)}
                  authData={authData}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    regLoading: state.auth.regLoading,
    regSuccess: state.auth.regSuccess,
    regError: state.auth.regError,
    sendSmsLoading: state.auth.sendSmsLoading,
    sendSmsSuccess: state.auth.sendSmsSuccess,
    sendSmsErrors: state.auth.sendSmsErrors,
    loginByPhoneLoading: state.auth.loginByPhoneLoading,
    loginByPhoneSuccess: state.auth.loginByPhoneSuccess,
    loginByPhoneErrors: state.auth.loginByPhoneErrors,
    authData: state.auth.authData,
  }),
  {
    register: authActions.regRequest,
    clearReg: authActions.clearReg,
    sendSms: authActions.sendSmsRequest,
    loginByPhone: authActions.loginByPhoneRequest,
    clearLoginByPhone: authActions.clearLoginByPhone,
    clearSendSms: authActions.clearSendSms,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(injectIntl(Registration));
