import { menuConfigItems } from '../constants/menuConfigItems';
import { UserRoleType } from '../interfaces/user';

const getMenuConfig = (userRoles: [UserRoleType] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (userRoles === undefined) {
    return getDefaultMenu();
  } else if (userRoles[0] === 'ROLE_ADMIN') {
    return getAdminMenu();
  } else if (userRoles[0] === 'ROLE_MANAGER') {
    return getDefaultMenu();
  } else if (userRoles[0] === 'ROLE_VENDOR') {
    return getVendorMenu();
  } else if (userRoles[0] === 'ROLE_BUYER') {
    return getBuyerMenu(isAuthorized);
  }
};
const {
  profile,
  products,
  orders,
  legal,
  settings,
  logout,
  users,
  catalog,
  myProducts,
  login,
} = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [profile, logout],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      catalog,
      products,
      users,
      orders,
      {
        root: true,
        title: 'MENU.COMPANIES',
        translate: 'MENU.COMPANIES',
        bullet: 'dot',
        icon: 'flaticon-buildings',
        submenu: [
          {
            translate: 'SUBMENU.COMPANIES',
            title: 'SUBMENU.COMPANIES',
            page: 'companies/list',
            activePages: ['companies/edit', 'companies/new'],
          },
          // {
          // translate: 'MENU.CATALOG.STORES',
          // title: 'MENU.CATALOG.STORES',
          // page: 'stores/list',
          // }
        ],
      },
      settings,
      legal,
      logout,
    ],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      { ...orders, submenu: [{ ...orders.submenu[0] }] },
      myProducts,
      // {
      // root: true,
      // title: 'MENU.USERS',
      // translate: 'MENU.USERS',
      // bullet: 'dot',
      // submenu: [
      // { translate: 'SUBMENU.TEAM', title: 'SUBMENU.TEAM', page: 'users/managers' },
      // ],
      // },
      {
        root: true,
        title: 'MENU.VENDOR.COMPANY',
        translate: 'MENU.VENDOR.COMPANY',
        bullet: 'dot',
        icon: 'flaticon-buildings',
        submenu: [
          {
            translate: 'SUBMENU.COMPANY_SETTINGS',
            title: 'SUBMENU.COMPANY_SETTINGS',
            page: 'companies/list',
            activePages: ['companies/edit', 'companies/new'],
          },
          {
            translate: 'SUBMENU.MANAGEGRS',
            title: 'SUBMENU.MANAGEGRS',
            page: 'companies/managers/list',
          },
          {
            translate: 'MENU.CATALOG.STORES',
            title: 'MENU.CATALOG.STORES',
            page: 'stores/list',
            activePages: ['stores/edit', 'stores/create'],
          },
        ],
      },
      {
        root: true,
        title: 'MENU.SETTINGS',
        bullet: 'dot',
        translate: 'MENU.SETTINGS',
        icon: 'flaticon-settings',
        submenu: [
          {
            title: 'SUBMENU.PROFILE',
            translate: 'SUBMENU.PROFILE',
            page: 'settings/profile',
          },
        ],
      },
      catalog,
      logout,
    ],
  },
});

const getBuyerMenu = (isAuthorized?: boolean) => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: isAuthorized
      ? [
          catalog,
          { ...orders, submenu: [{ ...orders.submenu[0] }] },
          {
            root: true,
            title: 'MENU.SETTINGS',
            bullet: 'dot',
            translate: 'MENU.SETTINGS',
            icon: 'flaticon-settings',
            submenu: [
              {
                title: 'SUBMENU.PROFILE',
                translate: 'SUBMENU.PROFILE',
                page: 'settings/profile',
              },
            ],
          },
          logout,
        ]
      : [catalog, login],
  },
});

export default getMenuConfig;
