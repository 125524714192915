import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 96,
    height: 50,
    borderRadius: 12,
    padding: '0 22px',
    boxShadow: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: '0.1s',
    whiteSpace: 'nowrap',
  },
  default: {
    background: colors.primary.green,
    '&:hover': {
      background: colors.primary.greenHover,
      // boxShadow: '11px 22px 44px -12px rgba(124, 177, 66, 0.5)',
    },
  },
  warning: {
    background: colors.primary.red,
    '&:hover': {
      background: colors.primary.redHover,
      // boxShadow: `11px 22px 44px -12px ${colors.primary.red}`,
    },
  },
  light: {
    background: colors.primary.lightSmokyGrey,
    color: '#1A1A1A',
    '&:hover': {
      background: colors.primary.lightSmokyGreyHover,
      // boxShadow: `11px 22px 44px -12px ${colors.primary.pink}`,
    },
  },
  outline: {
    background: 'transparent',
    boxShadow: `inset 0 0 0 1px ${colors.primary.green}`,
    '&:hover': {
      boxShadow: `inset 0 0 0 2px ${colors.primary.green}`,
    },
  },
}));
