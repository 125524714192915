import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { changeStatus, getCompanyManagers, getUsersByLogin } from '../../crud/users.crud';
import { IUser, ManagerStatus } from '../../interfaces/user';

const CLEAR_FETCH = 'managers/CLEAR_FETCH';
const FETCH_REQUEST = 'managers/FETCH_REQUEST';
const FETCH_SUCCESS = 'managers/FETCH_SUCCESS';
const FETCH_FAIL = 'managers/FETCH_FAIL';

const CLEAR_SEARCH_FETCH = 'managers/CLEAR_SEARCH_REQUEST';
const FETCH_SEARCH_REQUEST = 'managers/FETCH_SEARCH_REQUEST';
const FETCH_SEARCH_SUCCESS = 'managers/FETCH_SEARCH_SUCCESS';
const FETCH_SEARCH_FAIL = 'managers/FETCH_SEARCH_FAIL';

const CLEAR_CHANGE_STATUS_FETCH = 'managers/CLEAR_CHANGE_STATUS_REQUEST';
const FETCH_CHANGE_STATUS_REQUEST = 'managers/FETCH_CHANGE_STATUS_REQUEST';
const FETCH_CHANGE_STATUS_SUCCESS = 'managers/FETCH_CHANGE_STATUS_SUCCESS';
const FETCH_CHANGE_STATUS_FAIL = 'managers/FETCH_CHANGE_STATUS_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  users: IUser[];

  loading: boolean;
  success: boolean;
  error: string | null;

  page_search: number;
  per_page_search: number;
  total_search: number;
  users_search: IUser[];

  loading_search: boolean;
  success_search: boolean;
  error_search: string | null;

  change_status_loading: boolean;
  change_status_success: boolean;
  change_status_error: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  users: [],
  loading: false,
  success: false,
  error: null,

  page_search: 1,
  per_page_search: 20,
  total_search: 0,
  users_search: [],

  loading_search: false,
  success_search: false,
  error_search: null,

  change_status_loading: false,
  change_status_success: false,
  change_status_error: '',
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'managers', whitelist: ['managers', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_CHANGE_STATUS_FETCH: {
        return {
          ...state,
          change_status_loading: false,
          change_status_error: null,
          change_status_success: false,
        };
      }

      case FETCH_CHANGE_STATUS_REQUEST: {
        return {
          ...state,
          change_status_loading: true,
          change_status_error: null,
          change_status_success: false,
        };
      }

      case FETCH_CHANGE_STATUS_SUCCESS: {
        return {
          ...state,
          change_status_loading: false,
          change_status_error: null,
          change_status_success: true,
        };
      }

      case FETCH_CHANGE_STATUS_FAIL: {
        return {
          ...state,
          change_status_loading: false,
          change_status_error: action.payload,
          change_status_success: false,
        };
      }

      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false, users: [] };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          users: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          users: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_SEARCH_REQUEST: {
        return {
          ...state,
          users_search: [],
          loading_search: true,
          error_search: null,
          success_search: false,
        };
      }

      case FETCH_SEARCH_SUCCESS: {
        return {
          ...state,
          page_search: action.payload.page,
          per_page_search: action.payload.per_page,
          total_search: action.payload.total,
          users_search: action.payload.data,
          loading_search: false,
          success_search: true,
        };
      }

      case FETCH_SEARCH_FAIL: {
        return { ...state, loading_search: false, error_search: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: { page: number; perPage: number; companyId: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IUser[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearSearchFetch: () => createAction(CLEAR_SEARCH_FETCH),
  searchFetchRequest: (payload: { page: number; perPage: number; login: string }) =>
    createAction(FETCH_SEARCH_REQUEST, payload),
  searchFetchSuccess: (payload: IServerResponse<IUser[]>) =>
    createAction(FETCH_SEARCH_SUCCESS, payload),
  searchFetchFail: (payload: string) => createAction(FETCH_SEARCH_FAIL, payload),

  clearStatusFetch: () => createAction(CLEAR_CHANGE_STATUS_FETCH),
  changeStatusRequest: (payload: { userId: number; status: ManagerStatus }) =>
    createAction(FETCH_CHANGE_STATUS_REQUEST, payload),
  changeStatusSuccess: () => createAction(FETCH_CHANGE_STATUS_SUCCESS),
  changeStatusFail: (payload: string) => createAction(FETCH_CHANGE_STATUS_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: { page: number; perPage: number; companyId: number };
}) {
  try {
    const { data }: { data: IServerResponse<IUser[]> } = yield call(() =>
      getCompanyManagers(payload.companyId, payload.page, payload.perPage)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* searchFetchSaga({
  payload,
}: {
  payload: { page: number; perPage: number; login: string };
}) {
  try {
    const { data }: { data: IServerResponse<IUser[]> } = yield call(() =>
      getUsersByLogin(payload.page, payload.perPage, payload.login)
    );
    yield put(actions.searchFetchSuccess(data));
  } catch (e) {
    yield put(actions.searchFetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* changeStatusSaga({ payload }: { payload: { userId: number; status: ManagerStatus } }) {
  try {
    yield call(() => changeStatus(payload.userId, { manager_status: payload.status }));
    yield put(actions.changeStatusSuccess());
  } catch (e) {
    yield put(actions.changeStatusFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.searchFetchRequest>>(
    FETCH_SEARCH_REQUEST,
    searchFetchSaga
  );
  yield takeLatest<ReturnType<typeof actions.changeStatusRequest>>(
    FETCH_CHANGE_STATUS_REQUEST,
    changeStatusSaga
  );
}
