import { FC, useState, useRef, useEffect } from 'react';
import { TextType } from '../../../components/base/Text';
import { colors } from '../../../constants/colors';
import { Button } from '../../../components/base/Button';
import { Text } from '../../../components/base/Text';
import { createStyles, makeStyles, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import AutoCompleteInput from '../../../components/base/AutocompleteLocationsCopy/index';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { actions as productActions } from '../../../store/ducks/product.duck'
// import { connect, ConnectedProps } from 'react-redux';



interface AddressEditProps {
  //   open: boolean;
  //   close: () => void;
  //   formik: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    fullWidth: {
      width: '100%',
      marginBottom: 15,
      height: '50px',
    },
    clearIcon: {
      position: 'absolute',
      right: 32,
      marginTop: 12,
      width: 27,
      height: 30,
      cursor: 'pointer',
    },
    popWrap: {
      padding: '20px',
      ['@media (min-width:600px)']: {
        // minWidth: '500px',
      },

    },
    addressField: {
      marginBottom: '12px',
      '& .pac-target-input': {
        height: '55px',
      },
    },
  })
);

export const AddressEdit: FC<AddressEditProps> = (props) => {

  const intl = useIntl();
  const classes = useStyles();
  // const [value, setValue] = useState(props.selected);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [roomValue, setRoomValue] = useState(JSON.parse(localStorage.getItem('unitOffice')) || '');
  const [commentValue, setCommentValue] = useState(
    JSON.parse(localStorage.getItem('comment')) || ''
  );
  const dispatch = useDispatch()



  useEffect(() => {
    const setAddressFieldWidth = () => {
      if (window.innerWidth > 700) {
        setWindowSize(600)
      } else {
        setWindowSize(window.innerWidth - 30)
      }
    }
    setAddressFieldWidth()
    window.addEventListener('resize', () => {
      setAddressFieldWidth()
    })
  }, [])

  const { page, per_page } = useSelector(
    ({ products }: any) => products,
    shallowEqual
  );


  return (
    <div className={classes.popWrap} style={{ width: `${windowSize}px` }}>
      <div className={classes.modalHeader}>
        <Text type={TextType.semiboldH5} margin={{ bottom: 32 }}>
          Enter new delivery address
        </Text>
      </div>

      {props.selected?.address && (
        <GoogleMap
          mapContainerStyle={{
            height: 115,
            width: 'auto',
            borderRadius: 0,
            marginBottom: 16,
          }}
          center={{
            lat: props.selected?.lat || 0,
            lng: props.selected?.lng || 0,
          }}
          zoom={15}
          options={{ disableDefaultUI: true }}
        >
          <Marker
            label={''}
            position={{
              lat: props.selected?.lat || 0,
              lng: props.selected?.lng || 0,
            }}
          />
        </GoogleMap>
      )}

      <div className={classes.addressField}>
        <AutoCompleteInput
          // value={value || ''}
          value={props.selected || ''}
          setSelectedLocation={(location) => {
            // setValue(location);
            props.setSelected(location);
          }}
        />
      </div>

      <div>
        <TextField
          autoComplete="off"
          variant="outlined"
          placeholder={intl.formatMessage({ id: 'CART.MODAL.INPUT.UNIT' })}
          className={classes.fullWidth}
          onChange={(event) => {
            setRoomValue(event.target.value);
          }}
          value={roomValue}
        />
        {roomValue && (
          <svg
            className={classes.clearIcon}
            onClick={() => {
              setRoomValue('');
            }}
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5002 0.083252C8.43995 0.083252 6.42599 0.694179 4.71298 1.83878C2.99997 2.98337 1.66484 4.61024 0.876422 6.51363C0.0880094 8.41703 -0.118275 10.5115 0.283654 12.5321C0.685583 14.5527 1.67767 16.4088 3.13447 17.8656C4.59127 19.3224 6.44734 20.3145 8.46798 20.7164C10.4886 21.1184 12.5831 20.9121 14.4865 20.1237C16.3899 19.3353 18.0167 18.0001 19.1613 16.2871C20.3059 14.5741 20.9168 12.5601 20.9168 10.4999C20.9168 9.13198 20.6474 7.77744 20.1239 6.51363C19.6004 5.24982 18.8331 4.1015 17.8659 3.13422C16.8986 2.16695 15.7503 1.39966 14.4865 0.876173C13.2226 0.352687 11.8681 0.083252 10.5002 0.083252ZM13.3231 11.8437C13.4207 11.9405 13.4982 12.0557 13.5511 12.1827C13.604 12.3096 13.6312 12.4457 13.6312 12.5833C13.6312 12.7208 13.604 12.8569 13.5511 12.9839C13.4982 13.1108 13.4207 13.226 13.3231 13.3228C13.2262 13.4205 13.111 13.498 12.9841 13.5508C12.8572 13.6037 12.721 13.631 12.5835 13.631C12.446 13.631 12.3098 13.6037 12.1829 13.5508C12.056 13.498 11.9408 13.4205 11.8439 13.3228L10.5002 11.9687L9.15642 13.3228C9.05958 13.4205 8.94437 13.498 8.81744 13.5508C8.6905 13.6037 8.55435 13.631 8.41683 13.631C8.27932 13.631 8.14317 13.6037 8.01623 13.5508C7.8893 13.498 7.77409 13.4205 7.67725 13.3228C7.57962 13.226 7.50212 13.1108 7.44924 12.9839C7.39635 12.8569 7.36913 12.7208 7.36913 12.5833C7.36913 12.4457 7.39635 12.3096 7.44924 12.1827C7.50212 12.0557 7.57962 11.9405 7.67725 11.8437L9.03142 10.4999L7.67725 9.15617C7.4811 8.96002 7.37091 8.69398 7.37091 8.41658C7.37091 8.13919 7.4811 7.87315 7.67725 7.677C7.8734 7.48085 8.13944 7.37066 8.41683 7.37066C8.69423 7.37066 8.96027 7.48085 9.15642 7.677L10.5002 9.03117L11.8439 7.677C12.0401 7.48085 12.3061 7.37066 12.5835 7.37066C12.8609 7.37066 13.1269 7.48085 13.3231 7.677C13.5192 7.87315 13.6294 8.13919 13.6294 8.41658C13.6294 8.69398 13.5192 8.96002 13.3231 9.15617L11.9689 10.4999L13.3231 11.8437Z"
              fill="#7CB142"
            />
          </svg>
        )}
      </div>

      <div>
        <TextField
          autoComplete="off"
          variant="outlined"
          placeholder={intl.formatMessage({ id: 'CART.MODAL.INPUT.COMMENT' })}
          className={classes.fullWidth}
          onChange={(event) => {
            setCommentValue(event.target.value);
          }}
          value={commentValue}
        />
        {commentValue && (
          <svg
            className={classes.clearIcon}
            onClick={() => {
              setCommentValue('');
            }}
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5002 0.083252C8.43995 0.083252 6.42599 0.694179 4.71298 1.83878C2.99997 2.98337 1.66484 4.61024 0.876422 6.51363C0.0880094 8.41703 -0.118275 10.5115 0.283654 12.5321C0.685583 14.5527 1.67767 16.4088 3.13447 17.8656C4.59127 19.3224 6.44734 20.3145 8.46798 20.7164C10.4886 21.1184 12.5831 20.9121 14.4865 20.1237C16.3899 19.3353 18.0167 18.0001 19.1613 16.2871C20.3059 14.5741 20.9168 12.5601 20.9168 10.4999C20.9168 9.13198 20.6474 7.77744 20.1239 6.51363C19.6004 5.24982 18.8331 4.1015 17.8659 3.13422C16.8986 2.16695 15.7503 1.39966 14.4865 0.876173C13.2226 0.352687 11.8681 0.083252 10.5002 0.083252ZM13.3231 11.8437C13.4207 11.9405 13.4982 12.0557 13.5511 12.1827C13.604 12.3096 13.6312 12.4457 13.6312 12.5833C13.6312 12.7208 13.604 12.8569 13.5511 12.9839C13.4982 13.1108 13.4207 13.226 13.3231 13.3228C13.2262 13.4205 13.111 13.498 12.9841 13.5508C12.8572 13.6037 12.721 13.631 12.5835 13.631C12.446 13.631 12.3098 13.6037 12.1829 13.5508C12.056 13.498 11.9408 13.4205 11.8439 13.3228L10.5002 11.9687L9.15642 13.3228C9.05958 13.4205 8.94437 13.498 8.81744 13.5508C8.6905 13.6037 8.55435 13.631 8.41683 13.631C8.27932 13.631 8.14317 13.6037 8.01623 13.5508C7.8893 13.498 7.77409 13.4205 7.67725 13.3228C7.57962 13.226 7.50212 13.1108 7.44924 12.9839C7.39635 12.8569 7.36913 12.7208 7.36913 12.5833C7.36913 12.4457 7.39635 12.3096 7.44924 12.1827C7.50212 12.0557 7.57962 11.9405 7.67725 11.8437L9.03142 10.4999L7.67725 9.15617C7.4811 8.96002 7.37091 8.69398 7.37091 8.41658C7.37091 8.13919 7.4811 7.87315 7.67725 7.677C7.8734 7.48085 8.13944 7.37066 8.41683 7.37066C8.69423 7.37066 8.96027 7.48085 9.15642 7.677L10.5002 9.03117L11.8439 7.677C12.0401 7.48085 12.3061 7.37066 12.5835 7.37066C12.8609 7.37066 13.1269 7.48085 13.3231 7.677C13.5192 7.87315 13.6294 8.13919 13.6294 8.41658C13.6294 8.69398 13.5192 8.96002 13.3231 9.15617L11.9689 10.4999L13.3231 11.8437Z"
              fill="#7CB142"
            />
          </svg>
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          text={'Cancel'}
          style={{ width: '48%' }}
          light
          onClick={() => props.handleClose()}
        />
        <Button
          text={'Save'}
          style={{ width: '48%', backgroundColor: colors.primary.dark }}
          onClick={() => {
            console.log('qw');

            localStorage.setItem('location', props.selected.address.trim().length > 0 ? JSON.stringify(props.selected) : null);
            dispatch(productActions.fetchRequest({
              page: 1,
              perPage: 12,
              // companyId: +companyIdProp,
              categoryId: 0,
              location: props.selected.address.trim().length > 0 ? props.selected : null,
              only_active: 1,
              day: ''
            }))
            props.setSelected(props.selected);
            localStorage.setItem('unitOffice', JSON.stringify(roomValue));
            localStorage.setItem('comment', JSON.stringify(commentValue));
            props.handleClose();
          }}
        />
      </div>
    </div>
  );
};

