import { createStyles, makeStyles, Theme} from '@material-ui/core';
import { colors } from '../../../../../../constants/colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    sidebar: {
      background: 'linear-gradient(290.46deg, #FBFAF8 0%, #FFFFFF 100%);',
      zIndex: 197414,
      width: 350,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      transformOrigin: 'top right',
      transition: 'transform  0.2s ease-out',
      height: '100%',
      '& a': {
        '& :active,:visit,:focus': {
          color: 'unset !default',
        },
      },
    },
    itemsTitle: {
      marginBottom: 10,
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#DED6D6',
    },
    menuItems: {
      color: '#000',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 2.5,
      cursor: 'pointer',
      display: 'block',
    },
    'close-btn': {
      marginBottom: 30,
      cursor: 'pointer',
    },
    itemsWrapper: {
      '&>div:first-child': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginRight: '0px !important',
        marginBottom: '0px !important',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    accountSection: {
      margin: '25px 0',
      display: 'flex',
      alignItems: 'flex-start',
      minHeight: 80,
      '& img': {
        marginRight: 10,
        borderRadius: 12,
      },
      [theme.breakpoints.down(960)]: {
        margin: '40px 0 0 ',
      },
    },
    active: {
      backgroundColor: colors.primary.green,
      borderRadius: 6,
      color: 'white !important',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 1,
        height: '65%',
        right: 15,
        backgroundColor: 'white',
      },
    },
  })
);
