import React, { FC, useEffect, useState } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { useSignupFormik } from '../../hooks/useSignupFormik';
import { useFormatMessage } from '../../../../../hooks/useFormatMessage';
import { Input } from '../../../../../components/base/Input';
import { Text, TextType } from '../../../../../components/base/Text';
import { Button } from '../../../../../components/base/Button';
import TermDialog from '../../../../../components/old/ui/Dialog/TermDialog';
import { LoginType } from '../../../../../interfaces/user';

interface ISignupFormProps {
  submit: (data: { code: string; phone: string; fio: string; email: string }) => void;
  signType: LoginType;
  phone: string;
  email: string;
  loading: boolean;
}

export const SignupForm: FC<ISignupFormProps> = ({ signType, submit, phone, email, loading }) => {
  const classes = useStyles();
  const formik = useSignupFormik(submit, signType === LoginType.email);
  const fm = useFormatMessage();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('user_agreement');
  function openDialog(type: any) {
    setDialogType(type);
    setDialogOpen(true);
  }
  useEffect(() => {
    if (email.includes('@')) formik.setFieldValue('email', email);
    else formik.setFieldValue('phone', phone);
  }, [email, phone]);
  return (
    <>
      <TermDialog
        contentType={dialogType}
        isOpen={isDialogOpen}
        handleClose={() => setDialogOpen(false)}
      />

      <form onSubmit={formik.handleSubmit}>
        {signType === LoginType.phone ? (
          <Input
            phone
            label="Phone number"
            name={'phone'}
            type={'phone'}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone}
            error={!!formik.errors.phone}
            placeholder={'+61'}
          />
        ) : (
          <Input
            label={fm('AUTH.INPUT.EMAIL')}
            name={'email'}
            type={'email'}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
            placeholder={'email@google.com'}
          />
        )}

        <Input
          label={fm('USER.EDIT.FIO')}
          name={'fio'}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.fio}
          error={!!formik.errors.fio}
          placeholder={'Ivan'}
        />
        {signType === LoginType.email ? (
          <Input
            phone
            label="Phone number"
            name={'phone'}
            type={'phone'}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone}
            error={!!formik.errors.phone}
            placeholder={'+61'}
          />
        ) : (
          <Input
            label={fm('AUTH.INPUT.EMAIL')}
            name={'email'}
            type={'email'}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
            placeholder={'email@google.com'}
          />
        )}
        <Text type={TextType.caption} margin={{ top: 16, bottom: 16 }}>
          Enter your phone number in international format (+61...). You will receive an SMS with a
          login code
        </Text>
        <Text type={TextType.caption} className={classes.helpText} margin={{ bottom: 16 }}>
          Pressing the button, you confirm its viability and acceptance of{' '}
          <span onClick={() => openDialog('privacy_policy')}>privacy policy</span> and{' '}
          <span onClick={() => openDialog('user_agreement')}>
            agreement concerning the processing of personal data
          </span>
        </Text>
        <Button type={'submit'} text={'Next'} full loading={loading} />
      </form>
    </>
  );
};
