import axios from 'axios';
import { CREATE_STORE_URL, getStoreByIdUrl, setWorkingTimeUrl, STORES_URL, setDeliveryZoneUrl } from '../constants';
import { IEditWorkingTime, IStore } from '../interfaces/store';

export const createStore = (data: IStore) => {
  return axios.post(CREATE_STORE_URL, data);
};

export const getStores = (page: number, perPage: number, companyId?: number) => {
  return axios.get(
    `${STORES_URL}${companyId ? `?company_id=${companyId}` : ''}&page=${page}&perPage=${perPage}`
  );
};

export const editStore = (id: number, data: IStore) => axios.put(getStoreByIdUrl(id), data);

export const deleteStore = (id: number) => axios.delete(getStoreByIdUrl(id));

export const getStoreById = (id: number) => axios.get(getStoreByIdUrl(id));

export const setWorkingTime = (id: number, data: IEditWorkingTime) => {
  let url = setWorkingTimeUrl(id);

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      url += `${url === setWorkingTimeUrl(id) ? '?' : '&'}${prop}=${(data as any)[prop]}`;
    }
  }

  return axios.post(url, {});
};

export const setDeliveryZone = (id: number, data: any) => axios.put(setDeliveryZoneUrl(id), data);



