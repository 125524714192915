import React, { FC } from 'react';
import { useStyles } from './hooks/useStyles';
import { Text, TextType } from '../Text';

interface IRadioProps {
  checked: boolean;
  onPress?: () => void;
  label?: string;
  Label?: any;
  style?: any;
}

export const Checkbox: FC<IRadioProps> = (props) => {
  const classes = useStyles();

  return (
    <div style={props.style} className={classes.container} onClick={props.onPress}>
      <div className={props.checked ? 'active' : ''}>
        <span />
      </div>

      {props.label && (
        <Text type={TextType.body1} margin={{ left: 10 }}>
          {props.label}
        </Text>
      )}

      {props.Label && <marker style={{ marginLeft: 15 }}>{props.Label}</marker>}
    </div>
  );
};
