import { connect, ConnectedProps } from 'react-redux';
import { Button } from '../../../../../components/base/Button';
import { colors } from '../../../../../constants/colors';
import { IAppState } from '../../../../../store/rootDuck';
import { actions as cartActions } from '../../../../../store/ducks/cart.duck';
import { useStyles } from './hooks/useStyles';
import { useEffect } from 'react';
import { ICart, IGuestCart } from '../../../cart/interfaces';
import { API_DOMAIN } from '../../../../../constants';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import { useHistory } from 'react-router-dom';
import { thousands } from '../../../../../utils/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { Scrollbars } from 'react-custom-scrollbars';

const CartSidebar: React.FC<TPropsFromRedux> = ({
  cart,
  guestCart,
  isAuthorized,
  companyId,
  fetchStrores,
  setProductCount,
  setCountGuestCart,
  cartSidebarState,
  setCartSidebarState,
  cartLength,
  guestCartLength,
  setProductCountLoading,
}) => {
  const styles = useStyles();
  let userCart: ICart | IGuestCart | null = isAuthorized ? cart : guestCart;

  const history = useHistory();
  let totalPrice = 0;
  if (userCart) userCart.items.forEach((item) => (totalPrice += item.product.price * item.count));

  useEffect(() => {
    if (isAuthorized) {
      if (companyId) {
        fetchStrores({ companyId });
      }
    } else if (guestCart && guestCart.cartCompanyId) {
      fetchStrores({ companyId: guestCart.cartCompanyId });
    }
  }, [isAuthorized, companyId]);

  return (
    <div
      className={styles.sidebar}
      style={{
        transform: `translateX(${cartSidebarState ? 0 : 100}%)`,
        boxShadow: cartSidebarState ? '-11px 22px 44px -12px rgba(124, 177, 66, 0.25)' : 'none',
      }}
    >
      <div
        style={{ cursor: 'pointer', width: 'fit-content' }}
        onClick={() => {
          setCartSidebarState(false);
        }}
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.2608 7.49787L14.6306 2.14055C14.8658 1.90539 14.9979 1.58646 14.9979 1.2539C14.9979 0.921347 14.8658 0.602412 14.6306 0.36726C14.3955 0.132107 14.0765 0 13.744 0C13.4114 0 13.0925 0.132107 12.8573 0.36726L7.5 5.73707L2.14268 0.36726C1.90752 0.132107 1.58859 -2.47773e-09 1.25603 0C0.923478 2.47773e-09 0.604543 0.132107 0.369391 0.36726C0.134238 0.602412 0.00213119 0.921347 0.00213119 1.2539C0.00213119 1.58646 0.134238 1.90539 0.369391 2.14055L5.7392 7.49787L0.369391 12.8552C0.252343 12.9713 0.159441 13.1094 0.0960411 13.2616C0.0326416 13.4138 0 13.577 0 13.7418C0 13.9067 0.0326416 14.0699 0.0960411 14.2221C0.159441 14.3743 0.252343 14.5124 0.369391 14.6285C0.485482 14.7455 0.6236 14.8384 0.775777 14.9018C0.927954 14.9652 1.09118 14.9979 1.25603 14.9979C1.42089 14.9979 1.58411 14.9652 1.73629 14.9018C1.88847 14.8384 2.02659 14.7455 2.14268 14.6285L7.5 9.25867L12.8573 14.6285C12.9734 14.7455 13.1115 14.8384 13.2637 14.9018C13.4159 14.9652 13.5791 14.9979 13.744 14.9979C13.9088 14.9979 14.072 14.9652 14.2242 14.9018C14.3764 14.8384 14.5145 14.7455 14.6306 14.6285C14.7477 14.5124 14.8406 14.3743 14.904 14.2221C14.9674 14.0699 15 13.9067 15 13.7418C15 13.577 14.9674 13.4138 14.904 13.2616C14.8406 13.1094 14.7477 12.9713 14.6306 12.8552L9.2608 7.49787Z"
            fill="#1A1A1A"
          />
        </svg>
      </div>

      <AnimatePresence>
        {userCart?.items.length ? (
          <>
            <div style={{ fontSize: 16, fontWeight: 600, marginTop: 28 }}>Your cart</div>
            <Button
              style={{ marginTop: 19 }}
              text="Buy now"
              text2={'A$' + ' ' + thousands(String(totalPrice))}
              onClick={() => history.push('/cart')}
            />
            <div
              style={{ borderBottom: `1px solid ${colors.primary.lightGrey}`, marginTop: 15 }}
            ></div>
            <Scrollbars className={styles.productsWrapper} autoHide>
              {userCart &&
                userCart.items.map((item) => (
                  <motion.div
                    transition={{ delay: 0.2 }}
                    initial={{ opacity: 0, translateY: '100%' }}
                    animate={{ opacity: 1, translateY: '0%' }}
                    className={styles.cartWrapper}
                  >
                    <motion.div
                      transition={{ delay: 0.3 }}
                      initial={{ opacity: 0, scale: 0.7 }}
                      animate={{ opacity: 1, scale: 1 }}
                    >
                      <motion.img
                        className={styles.cartItemImage}
                        src={
                          item.product.photos && item.product.photos?.length > 0
                            ? `${API_DOMAIN}/${item.product.photos[0].small}`
                            : `${toAbsoluteUrl('/images/placeholder.png')}`
                        }
                      />
                    </motion.div>
                    <div className={styles.cartInfo}>
                      <span style={{ lineHeight: 1 }}>{item.product.name}</span>
                      <div style={{ display: 'flex', fontWeight: 600 }}>
                        <div
                          className={styles.changeProductCount}
                          onClick={() => {
                            if (!setProductCountLoading)
                              if (isAuthorized) {
                                setProductCount({
                                  product_id: item.product.id || 0,
                                  count: --item.count,
                                });
                              } else {
                                setCountGuestCart({
                                  count: --item.count,
                                  product_id: item.product.id || 0,
                                  type: 'dec',
                                });
                              }
                          }}
                        >
                          -
                        </div>
                        <span style={{ margin: '0 8px' }}>{item.count}</span>
                        <div
                          className={styles.changeProductCount}
                          onClick={() => {
                            if (!setProductCountLoading)
                              if (isAuthorized) {
                                setProductCount({
                                  product_id: item.product.id || 0,
                                  count: ++item.count,
                                });
                              } else {
                                setCountGuestCart({
                                  count: ++item.count,
                                  product_id: item.product.id || 0,
                                  type: 'inc',
                                });
                              }
                          }}
                        >
                          +
                        </div>
                        <span style={{ flex: 1, textAlign: 'right' }}>A$ {item.product.price}</span>
                      </div>
                    </div>
                  </motion.div>
                ))}
            </Scrollbars>
          </>
        ) : (
          <div className={styles.emptyCartWrapper}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100px"
              height="100px"
              viewBox="0 0 24 24"
              role="presentation"
            >
              <g transform="translate(2 2)">
                <path
                  d="M15.149,18.459A1.514,1.514,0,1,1,16.668,20,1.535,1.535,0,0,1,15.149,18.459Zm-11.241,0A1.514,1.514,0,1,1,5.426,20,1.534,1.534,0,0,1,3.908,18.459Zm1.679-3.164a2.509,2.509,0,0,1-1.726-.689,2.616,2.616,0,0,1-.812-1.7L2.129,1.784.62,1.518A.766.766,0,0,1,.765,0,.817.817,0,0,1,.88.009L3.263.375a.768.768,0,0,1,.619.694l.19,2.286a.617.617,0,0,0,.609.572H18.176a1.689,1.689,0,0,1,1.409.684,2.251,2.251,0,0,1,.38,1.754l-.95,6.694a2.582,2.582,0,0,1-2.528,2.235Zm5.785-6.826a.758.758,0,0,0,.75.765h2.767a.758.758,0,0,0,.75-.765.75.75,0,0,0-.75-.766H12.121A.75.75,0,0,0,11.372,8.468Z"
                  fill={colors.primary.dark}
                ></path>
              </g>
            </svg>
            <div>Your cart is empty</div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,
    loadingLocations: state.yaLocations.loading,
    checkoutLoading: state.cart.checkoutLoading,
    checkoutSuccess: state.cart.checkoutSuccess,
    checkoutError: state.cart.checkoutError,
    stores: state.cart.stores,
    storesLoading: state.cart.storesLoading,
    storesSuccess: state.cart.storesSuccess,
    storesError: state.cart.storesError,
    guestCart: state.cart.guestCart,
    guestCartLength: state.cart.guestCart?.goods_num,
    cartLength: state.cart.cart?.goods_num,
    isAuthorized: state.auth.user != null,
    companyId: state.cart.companyId,
    isAdmin: state.auth.user?.is_admin,
    deliveryDate: state.cart.deliveryDate,
    cartSidebarState: state.cart.cartSidebarState,
  }),
  {
    setProductCount: cartActions.setProductCountRequest,
    clearCheckout: cartActions.clearCheckout,
    checkout: cartActions.checkoutRequest,
    clearStores: cartActions.clearStores,
    fetchStrores: cartActions.storesRequest,
    setCountGuestCart: cartActions.setCountGuestCart,
    setInfoUserGuestCart: cartActions.setInfoUserGuestCart,
    setCartDate: cartActions.setCartDate,
    setCartSidebarState: cartActions.setCartSidebarState,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CartSidebar);
