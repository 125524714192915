import React from 'react';
import { Button } from '../../../base/Button';

import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog } from '../../../base/Dialog';
import { makeStyles } from '@material-ui/core';

interface IAlertDialog extends DialogProps {
  handleAgree: any;
  handleClose: any;
  okText?: string;
  cancelText?: string;
  message?: string;
}
const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '20px !important',
  },
  card: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttons: {
    marginRight: theme.spacing(2),
  },
  autoLoc: {
    width: '100%',
  },
  editor: {
    minHeight: 305,
  },
  empty: {
    marginBottom: 20,
    marginTop: 20,
  },
}));
const AlertDialog: React.FC<IAlertDialog> = ({
  open,
  handleClose,
  handleAgree,
  okText = 'OK',
  cancelText = 'Cancel',
  message = '',
}) => {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialogContainer}
      open={open}
      onClose={handleClose}
      closeButton={false}
    >
      <div style={{ fontSize: 16, color: 'black' }}>{message}</div>
      <div className={classes.actions}>
        <Button onClick={() => handleAgree()} text={okText} full />
        &nbsp;&nbsp;
        <Button onClick={() => handleClose()} light text={cancelText} full />
      </div>
    </Dialog>
  );
};

export default AlertDialog;
