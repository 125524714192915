import { ICompany } from './companies';
import { ILocation } from './locations';

export enum ManagerStatus {
  moderation = 'In moderation',
  hired = 'Hired',

  // Only in front
  canceled = 'Canceled',
}

export interface IUserAddProps {
  login: string | undefined;
  email: string | undefined;
  password: string;
  roles: string[];
  fio: string | undefined;
}

export interface IUserEditProps {
  login?: string | undefined;
  email?: string | undefined;
  password?: string;
  fio?: string | undefined;
  active?: boolean;
  locale?: string;
  company_id?: number;
  manager_status?: ManagerStatus | null;
  location?: ILocation;
  phoneNumbers: string;
}

export interface IUserRecipient {
  fio: string;
  phone: string;
  id: string;
}

export interface IUser {
  recipient_in_order: string;
  id: number;
  email: string;
  api_token: string;
  fio: string;
  login: string;
  is_admin: boolean;
  active: boolean;
  company: ICompany | null;
  roles: [UserRoleType];
  locale: string;
  manager_status: ManagerStatus;
  location?: ILocation | null;
  phone: string;
  phone_numbers: string;
}

export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_USER = 'ROLE_USER',
  ROLE_VENDOR = 'ROLE_VENDOR',
  ROLE_BUYER = 'ROLE_BUYER',
  ROLE_RECIPIENT = 'ROLE_RECIPIENT',
}

export type UserRoleType =
  | 'ROLE_ADMIN'
  | 'ROLE_MANAGER'
  | 'ROLE_USER'
  | 'ROLE_VENDOR'
  | 'ROLE_BUYER'
  | 'ROLE_RECIPIENT';

export enum LoginType {
  email = 'email',
  phone = 'phone',
  login = 'login',
}
