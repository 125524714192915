import React from 'react';
import Wrapper from './components/Wrapper';
import { toAbsoluteUrl } from '../../../../_metronic';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import CheckInSystem from './components/CheckInSystem';
import Login from './components/Login';
import Registration from './components/Registration';
import EmailSentPage from './components/EmailSentPage';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordEmail from './components/ForgotPasswordEmail';
import NewPassword from './components/NewPassword';
import CodeRequest from './components/CodeRequest';
import { useStyles } from './hooks/useStyles';
import { useMediaQuery, useTheme } from '@material-ui/core';

const PartnerAuth = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  return (
    <Wrapper>
      <div className={classes.pageWrapper}>
        <img className={classes.image} src={toAbsoluteUrl('/images/flower.png')} alt="" />
        {useMediaQuery(theme.breakpoints.down('sm')) && (
          <img
            className={classes.logo}
            src={toAbsoluteUrl('/images/logoVector.svg')}
            onClick={() => history.push('/main')}
            alt="logo"
          />
        )}
        <div className={classes.routes}>
          <Switch>
            <Route path="/auth/partner" component={CheckInSystem} />
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/registration" component={Registration} />
            <Route path="/auth/email-sent/registration" component={EmailSentPage} />
            <Route path="/auth/forgot-password" component={ForgotPassword} />
            <Route path="/auth/verify-code" component={CodeRequest} />
            <Route path="/auth/forgot-password-email" component={ForgotPasswordEmail} />
            <Route path="/auth/change_password/:code" component={NewPassword} />
            <Redirect from="/auth" exact to="/auth/partner" />
            <Redirect to="/auth/partner" />
          </Switch>
        </div>
      </div>
    </Wrapper>
  );
};

export default PartnerAuth;
