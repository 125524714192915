import axios from 'axios';
import {
  CREATE_USER_URL,
  delUserUrl,
  editUserUrl,
  editVendorUrl,
  GET_USERS_URL,
  getManagers,
  getUserUrl,
  SEND_INVITE_URL,
  USER_RECIPIENTS_URL,
} from '../constants';
import { IUserAddProps, IUserEditProps, ManagerStatus } from '../interfaces/user';
export function getUserRecipients(id: number) {
  return axios.get(USER_RECIPIENTS_URL(id));
}
export function getUsersByRole(page: number, perPage: number, roles: string) {
  return axios.get(`${GET_USERS_URL}?page=${page}&per_page=${perPage}&roles=${roles}`);
}

export function getUsersByLogin(page: number, perPage: number, login: string) {
  return axios.get(`${GET_USERS_URL}?page=${page}&per_page=${perPage}&login=${login}`);
}

export function getUser(id: number) {
  return axios.get(getUserUrl(id));
}

export function createUser(data: IUserAddProps) {
  return axios.post(CREATE_USER_URL, data);
}

export function editUser(id: number, data: IUserEditProps) {
  return axios.put(editUserUrl(id), data);
}

export function changeStatus(id: number, data: IUserEditProps) {
  const body =
    data.manager_status === ManagerStatus.canceled
      ? {
          manager_status: null,
          company_id: 0,
        }
      : data;

  return axios.put(editVendorUrl(id), body);
}

export function sendInvite(data: string) {
  return axios.get(`${SEND_INVITE_URL}?email=${data}`);
}

export function delUser(id: number) {
  return axios.delete(delUserUrl(id));
}

export function getCompanyManagers(companyId: number, page: number, perPage: number) {
  return axios.get(getManagers(companyId, page, perPage));
}
