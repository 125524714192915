import React, { FC } from 'react';
import { useStyles } from './hooks/useStyles';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import { Text, TextType } from '../../../../../components/base/Text';
import { useHistory } from 'react-router';
import { colors } from '../../../../../constants/colors';

interface IWrapperProps {}

const Wrapper: FC<IWrapperProps> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <div className="left" />
        <img
          className={classes.logo}
          src={toAbsoluteUrl('/images/logoVector.svg')}
          onClick={() => history.push('/main')}
          alt="logo"
        />
        <div className="right">
          <Text
            color={colors.primary.green}
            type={TextType.semiboldButton}
            style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
            onClick={() => history.goBack()}
          >
            Back to shop
          </Text>
        </div>
      </header>

      <section className={classes.body}>{children}</section>

      <footer className={classes.footer}>
        <Text type={TextType.semiboldButton}>© Flower Nation, inc.</Text>
      </footer>
    </div>
  );
};

export default Wrapper;
