import React, { FC } from 'react';
import { Text, TextType } from '../../../../../../components/base/Text';
import { colors } from '../../../../../../constants/colors';
import { useStyles } from '../../hooks/useStyles';
import { Iconly, ShieldDone, Wallet, TimeCircle } from 'react-iconly';

interface ICompanyInfoProps {
  img: string;
  name: string;
  click: () => void;
  rating: number;
}

export const CompanyInfo: FC<ICompanyInfoProps> = ({ img, name, click, rating }) => {
  const classes = useStyles();

  return (
    <div className={classes.company}>
      <div className={classes.companyInfo}>
        <div className={classes.companyAvatar}>
          <img src={img} alt="" style={{ objectFit: 'cover' }} />
        </div>

        <div style={{ whiteSpace: 'nowrap' }}>
          <Text color={'#484848'} type={TextType.regularSubtitle1}>
            Retail store
          </Text>
          <Text margin={{ top: 8, bottom: 8 }} type={TextType.semiboldSubtitle1}>
            {name}
          </Text>
          <Text
            style={{ cursor: 'pointer' }}
            onClick={click}
            color={colors.primary.green}
            type={TextType.regularSubtitle1}
          >
            About store
          </Text>
        </div>
      </div>

      <div className={classes.splitter} />

      <div className={`${classes.ratingSection} ${classes.rating}`}>
        <Iconly
          name={'Star'}
          set={'bold'}
          primaryColor={colors.primary.yellow}
          style={{ fontSize: 10 }}
        />

        <Text
          type={TextType.body2}
          style={{ display: 'flex', fontWeight: 400 }}
          margin={{ left: 8 }}
        >
          <Text type={TextType.semiboldSubtitle1} margin={{ right: 8 }}>
            {rating}
          </Text>
          out of
          <Text type={TextType.semiboldSubtitle1} margin={{ left: 8, right: 8 }}>
            5
          </Text>
          product ratings
        </Text>
        <div className={classes.splitter} />
      </div>
      <div
        className={classes.ratingSection}
        style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}
      >
        <span className={classes.rating2}>
          <Text
            type={TextType.body2}
            style={{ display: 'flex', fontWeight: 300, alignItems: 'center', fontSize: 11 }}
           
          >
            <Iconly
              style={{ marginRight: 8 }}
              name={'Star'}
              set={'bold'}
              primaryColor={colors.primary.yellow}
            />
            <Text type={TextType.semiboldSubtitle1} margin={{ right: 8 }}>
              {rating}
            </Text>
            out of
            <Text type={TextType.semiboldSubtitle1} margin={{ left: 8, right: 8 }}>
              5
            </Text>
            product ratings
          </Text>
        </span>
        <span>
          <Wallet style={{ fontSize: 10 }} set="bold" primaryColor={colors.primary.green} />
          <span className={classes.textInfoCompany}>Secure online payment</span>
        </span>

        <span>
          <TimeCircle set="bold" primaryColor={colors.primary.green} />
          <span className={classes.textInfoCompany}>95% of orders delivered on time</span>
        </span>
        <span>
          <ShieldDone set="bold" primaryColor={colors.primary.green} />
          <span className={classes.textInfoCompany}>Verified brand seller</span>
        </span>
      </div>
    </div>
  );
};
