import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFormatMessage } from '../../../../hooks/useFormatMessage';

export const getInitValues = () => ({
  email: '',
  fio: '',
  code: '',
  phone: '',
});

const phoneValidate = (fm: any) => {
  return Yup.object().shape({
    phone: Yup.string().required(fm('AUTH.INPUT.PHONE_REQUIRED')),
    fio: Yup.string().required(fm('AUTH.INPUT.NAME_REQUIRED')),
  });
};
const emailValidate = (fm: any) => {
  return Yup.object().shape({
    fio: Yup.string().required(fm('AUTH.INPUT.NAME_REQUIRED')),
    email: Yup.string().required(fm('AUTH.INPUT.EMAIL_INCORRECT')),
  });
};

export const useSignupFormik = (
  submit: (data: { code: string; phone: string; fio: string; email: string }) => void,
  isEmail: boolean
) => {
  const fm = useFormatMessage();
  const validationSchema = isEmail ? emailValidate(fm) : phoneValidate(fm);

  return useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: getInitValues(),
    validationSchema,
    onSubmit: (submitValues) => {
      submit(submitValues);
    },
  });
};
