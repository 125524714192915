import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IProductFilter, IProduct, IReview } from '../../interfaces/product';
import {
  createProduct,
  deleteProduct,
  delPhoto,
  editProduct,
  getAllRewiews,
  getFilters,
  getProductById,
  getProducts,
  setMainPhoto,
} from '../../crud/product.crud';

import { actions as categoriesActions } from './categories.duck';
import { IFilter } from '../../interfaces/filters';

const FETCH_REQUEST = 'products/FETCH_REQUEST';
const FETCH_SUCCESS = 'products/FETCH_SUCCESS';
const FETCH_FAIL = 'products/FETCH_FAIL';
const SET_EMPTY = 'products/SET_EMPTY';
const FETCH_ALL_REVIEWS = 'products/FETCH_ALL_REVIEWS';
const FETCH_ALL_REVIEWS_SUCCESS = 'products/FETCH_ALL_REVIEWS_SUCCESS';
const FETCH_ALL_REVIEWS_ERROR = 'products/FETCH_ALL_REVIEWS_ERROR';
const CLEAR_REVIEW_LIST = 'products/CLEAR_REVIEW_LIST';
const FETCH_BY_ID_REQUEST = 'products/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'products/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'products/FETCH_BY_ID_FAIL';

const DEL_REQUEST = 'products/DEL_REQUEST';
//const DEL_SUCCESS = 'products/DEL_SUCCESS';
const DEL_FAIL = 'products/DEL_FAIL';
const CLEAR_DEL = 'products/CLEAR_DEL';
const CLEAR_PRODUCTS = 'products/CLEAR_PRODUCTS';

const CLEAR_EDIT = 'products/CLEAR_EDIT';
const ADD_REQUEST = 'products/ADD_REQUEST';
const EDIT_REQUEST = 'products/EDIT_REQUEST';
const EDIT_SUCCESS = 'products/EDIT_SUCCESS';
const EDIT_FAIL = 'products/EDIT_FAIL';
const CLEAR_PRODUCT = 'products/CLEAR_PRODUCT';

const CLEAR_UPLOAD_PHOTO = 'products/CLEAR_UPLOAD_PHOTO';
const UPLOAD_PHOTO_REQUEST = 'products/UPLOAD_PHOTO_REQUEST';
const UPLOAD_PHOTO_SUCCESS = 'products/UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_FAIL = 'products/UPLOAD_PHOTO_FAIL';

const CLEAR_DEL_PHOTO = 'products/CLEAR_DEL_PHOTO';
const DEL_PHOTO_REQUEST = 'products//DEL_PHOTO_REQUEST';
const DEL_PHOTO_SUCCESS = 'products/DEL_PHOTO_SUCCESS';
const DEL_PHOTO_FAIL = 'products/DEL_PHOTO_FAIL';

const CLEAR_SET_MAIN_PHOTO = 'products/CLEAR_SET_MAIN_PHOTO';
const SET_MAIN_PHOTO_REQUEST = 'products/SET_MAIN_PHOTO_REQUEST';
const SET_MAIN_PHOTO_SUCCESS = 'products/SET_MAIN_PHOTO_SUCCESS';
const SET_MAIN_PHOTO_FAIL = 'products/SET_MAIN_PHOTO_FAIL';

const SET_PRODUCT_REVIEWS = 'products/SET_PRODUCT_REVIEWS';

const SET_PRODUCTS_FILTER = 'products/SET_PRODUCTS_FILTER';

const FETCH_FILTERS_REQUEST = 'products/FETCH_FILTERS_REQUEST';
const FETCH_FILTERS_SUCCESS = 'products/FETCH_FILTERS_SUCCESS';
const FETCH_FILTERS_FAIL = 'products/FETCH_FILTERS_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  products: IProduct[];
  loading: boolean;
  success: boolean;
  error: string | null;
  reviews: IReview[];
  product: IProduct | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;
  reviewLoading: boolean;
  reviewSuccess: boolean;
  reviewError: string | null;
  delError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  photoLoading: boolean;
  photoSuccess: boolean;
  photoError: string | null;

  delPhotoLoading: boolean;
  delPhotoSuccess: boolean;
  delPhotoError: string | null;

  setMainPhotoLoading: boolean;
  setMainPhotoSuccess: boolean;
  setMainPhotoError: string | null;

  reviewsProduct: {
    total: number;
    rating: number | null;
    reviews: IReview[];
    meAdd: boolean;
  } | null;

  filtersList: IFilter[];
  filtersListLoading: boolean;
  filtersListSuccess: boolean;
  filtersListError: string | null;
  productsFilter: IProductFilter;
}

const defaultPaginatorProps = {
  page: 1,
  per_page: 12,
  total: 0,
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  products: [],
  reviews: [],
  loading: false,
  success: false,
  error: null,
  reviewError: null,
  reviewSuccess: false,
  reviewLoading: false,
  product: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  delError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  photoLoading: false,
  photoSuccess: false,
  photoError: null,

  delPhotoLoading: false,
  delPhotoSuccess: false,
  delPhotoError: null,

  setMainPhotoLoading: false,
  setMainPhotoSuccess: false,
  setMainPhotoError: null,

  reviewsProduct: null,

  filtersList: [],
  filtersListLoading: false,
  filtersListSuccess: false,
  filtersListError: null,
  productsFilter: {
    params: {},
  },
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'products', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, products: [] };
      }

      case CLEAR_PRODUCTS: {
        return {
          ...state,
          products: [],
          ...defaultPaginatorProps,
          productsFilter: {
            params: {},
          },
        };
      }

      case CLEAR_PRODUCT: {
        return { ...state, ...defaultPaginatorProps, product: undefined };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          products: [],
          loading: true,
          success: false,
          error: null,
        };
      }
      case FETCH_ALL_REVIEWS: {
        return {
          ...defaultPaginatorProps,
          ...state,
          reviewLoading: true,
          reviewSuccess: false,
          reviewError: null,
        };
      }
      case FETCH_ALL_REVIEWS_SUCCESS: {
        return {
          ...defaultPaginatorProps,
          ...state,
          reviews: action.payload.data,
          reviewLoading: false,
          reviewSuccess: true,
          reviewError: null,
        };
      }
      case FETCH_ALL_REVIEWS_ERROR: {
        return {
          ...defaultPaginatorProps,
          ...state,
          reviewLoading: false,
          reviewSuccess: false,
          reviewError: action.payload,
        };
      }
      case CLEAR_REVIEW_LIST: {
        return {
          ...defaultPaginatorProps,
          ...state,
          reviews: [],
          reviewLoading: false,
          reviewSuccess: false,
          reviewError: null,
        };
      }
      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total_pages,
          products: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload, products: [] };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          product: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, product: action.payload };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case DEL_FAIL: {
        return { ...state, delError: action.payload };
      }

      case CLEAR_DEL: {
        return { ...state, delError: null };
      }

      case CLEAR_UPLOAD_PHOTO: {
        return { ...state, photoLoading: false, photoSuccess: false, photoError: null };
      }

      case UPLOAD_PHOTO_REQUEST: {
        return {
          ...state,
          photoLoading: true,
          photoSuccess: false,
          photoError: null,
        };
      }

      case UPLOAD_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          photoLoading: false,
          photoSuccess: true,
        };
      }

      case UPLOAD_PHOTO_FAIL: {
        return { ...state, photoLoading: false, photoError: action.payload };
      }

      case CLEAR_DEL_PHOTO: {
        return { ...state, delPhotoLoading: false, delPhotoSuccess: false, delPhotoError: null };
      }

      case DEL_PHOTO_REQUEST: {
        return {
          ...state,
          delPhotoLoading: true,
          delPhotoSuccess: false,
          delPhotoError: null,
        };
      }

      case DEL_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          delPhotoLoading: false,
          delPhotoSuccess: true,
        };
      }

      case DEL_PHOTO_FAIL: {
        return {
          ...state,
          delPhotoLoading: false,
          delPhotoError: action.payload,
        };
      }

      case CLEAR_SET_MAIN_PHOTO: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_REQUEST: {
        return {
          ...state,
          setMainPhotoLoading: true,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: true,
        };
      }

      case SET_MAIN_PHOTO_FAIL: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoError: action.payload,
        };
      }

      case SET_PRODUCT_REVIEWS: {
        return {
          ...state,
          reviewsProduct: {
            rating: action.payload.rating,
            reviews: action.payload.data,
            total: action.payload.total,
            meAdd: action.payload.me_add,
          },
        };
      }

      case SET_PRODUCTS_FILTER: {
        return {
          ...state,
          page: 1,
          productsFilter:
            action.payload === null
              ? { params: {} }
              : { ...state.productsFilter, ...action.payload },
        };
      }

      case FETCH_FILTERS_REQUEST: {
        return {
          ...state,
          filtersListLoading: true,
          filtersListSuccess: false,
          filtersListError: '',
        };
      }

      case FETCH_FILTERS_SUCCESS: {
        return {
          ...state,
          filtersList: action.payload.data,
          filtersListLoading: false,
          filtersListSuccess: true,
        };
      }

      case FETCH_FILTERS_FAIL: {
        return { ...state, filtersListLoading: false, filtersListError: action.payload };
      }

      default: {
        return state;
      }
    }
  }
);

export const actions = {
  fetchAllReviews: ({ payload }: { payload: string } = { payload: '' }) =>
    createAction(FETCH_ALL_REVIEWS, payload),
  fetchAllReviewSuccess: (payload: { data: [] }) =>
    createAction(FETCH_ALL_REVIEWS_SUCCESS, payload),
  fetchAllReviewError: (payload: string) => createAction(FETCH_ALL_REVIEWS_ERROR, payload),
  clearReviewList: () => createAction(CLEAR_REVIEW_LIST),
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    companyId?: number | null;
    categoryId?: number;
    only_active?: 1 | 0;
    location?: any;
    productsFilter?: IProductFilter;
    day?: string;
  }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IProduct[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  setEmpty: () => createAction(SET_EMPTY),
  clearProducts: () => createAction(CLEAR_PRODUCTS),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  delRequest: (payload: {
    id: number | undefined;
    page: number;
    perPage: number;
    companyId?: number;
    categoryId?: number;
    only_active?: 0 | 1;
  }) => createAction(DEL_REQUEST, payload),
  delFail: (payload: string) => createAction(DEL_FAIL, payload),
  clearDel: () => createAction(CLEAR_DEL),

  fetchFiltersRequest: () => createAction(FETCH_FILTERS_REQUEST),
  fetchFiltersSuccess: (payload: IServerResponse<IFilter[]>) =>
    createAction(FETCH_FILTERS_SUCCESS, payload),
  fetchFiltersFail: (payload: string) => createAction(FETCH_FILTERS_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { data: FormData }) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: FormData }) => createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: IProduct) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
  clearProduct: () => createAction(CLEAR_PRODUCT),

  clearPhotoRequest: () => createAction(CLEAR_UPLOAD_PHOTO),
  uploadPhotoRequest: (payload: { modificationId: number; files: FormData }) =>
    createAction(UPLOAD_PHOTO_REQUEST, payload),
  uploadPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(UPLOAD_PHOTO_SUCCESS, payload),
  uploadPhotoFail: (payload: string) => createAction(UPLOAD_PHOTO_FAIL, payload),

  clearDelPhotoRequest: () => createAction(CLEAR_DEL_PHOTO),
  delPhotoRequest: (payload: number) => createAction(DEL_PHOTO_REQUEST, payload),
  delPhotoSuccess: (payload: IServerResponse<IProduct>) => createAction(DEL_PHOTO_SUCCESS, payload),
  delPhotoFail: (payload: string) => createAction(DEL_PHOTO_FAIL, payload),

  clearSetMainPhotoRequest: () => createAction(CLEAR_SET_MAIN_PHOTO),
  setMainPhotoRequest: (payload: number) => createAction(SET_MAIN_PHOTO_REQUEST, payload),
  setMainPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(SET_MAIN_PHOTO_SUCCESS, payload),
  setMainPhotoFail: (payload: string) => createAction(SET_MAIN_PHOTO_FAIL, payload),

  setProductReviews: (payload: {
    rating: number | null;
    data: IReview[];
    total: number;
    me_add: boolean;
  }) => createAction(SET_PRODUCT_REVIEWS, payload),

  setProductsFilter: (payload: Partial<IProductFilter> | null) =>
    createAction(SET_PRODUCTS_FILTER, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    companyId?: number;
    categoryId?: number;
    only_active?: 1 | 0;
    location?: any;
    productsFilter?: IProductFilter;
    day?: string;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProducts(
        payload.page,
        payload.perPage,
        payload.companyId,
        payload.categoryId,
        payload.only_active,
        payload.location,
        payload.productsFilter,
        payload.day || ''
      )
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}
function* fetchReviewSaga({ payload }: { payload: string } = { payload: '' }) {
  try {
    const { data }: { data: any } = yield call(() => getAllRewiews(payload));
    yield put(actions.fetchAllReviewSuccess(data));
  } catch (e) {
    yield put(actions.fetchAllReviewError(e?.response?.data?.message || 'Request error'));
  }
}
function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() => getProductById(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: { data: FormData } }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      createProduct(payload.data)
    );
    yield put(categoriesActions.fetchCatalogCategories());
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: FormData } }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      editProduct(payload.id, payload.data)
    );
    yield put(categoriesActions.fetchCatalogCategories());
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delPhotoSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() => delPhoto(payload));
    yield put(actions.delPhotoSuccess(data));
  } catch (e) {
    yield put(actions.delPhotoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* setMainPhotoSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() => setMainPhoto(payload));
    yield put(actions.setMainPhotoSuccess(data));
  } catch (e) {
    yield put(actions.setMainPhotoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delSaga({
  payload,
}: {
  payload: {
    id: number | undefined;
    page: number;
    perPage: number;
    companyId?: number;
    categoryId?: number;
    only_active?: 0 | 1;
  };
}) {
  try {
    yield call(() => deleteProduct(payload.id));
    yield put(categoriesActions.fetchCatalogCategories());
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        companyId: payload.companyId,
        categoryId: payload.categoryId,
        only_active: payload.only_active,
      })
    );
  } catch (e) {
    yield put(actions.delFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchFiltersSaga() {
  try {
    const { data }: { data: IServerResponse<IFilter[]> } = yield call(() => getFilters());
    yield put(actions.fetchFiltersSuccess(data));
  } catch (e) {
    yield put(actions.fetchFiltersFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.delPhotoRequest>>(DEL_PHOTO_REQUEST, delPhotoSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DEL_REQUEST, delSaga);
  yield takeLatest<ReturnType<typeof actions.fetchAllReviews>>(FETCH_ALL_REVIEWS, fetchReviewSaga);
  yield takeLatest<ReturnType<typeof actions.setMainPhotoRequest>>(
    SET_MAIN_PHOTO_REQUEST,
    setMainPhotoSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchFiltersRequest>>(
    FETCH_FILTERS_REQUEST,
    fetchFiltersSaga
  );
}
