import axios from 'axios';
import {
  addProuctToCartUrl,
  checkoutCartUrl,
  clearCartUrl,
  createCartUrl,
  getCartUrl,
  setProductCountUrl,
  stripeCheckoutUrl,
  getDeliveryCostUrl,
} from '../constants';
import { ICartCheckout } from '../pages/home/cart/interfaces';

export function setProductCount({
  hash,
  product_id,
  count,
}: {
  hash: string;
  product_id: number;
  count: number;
}) {
  return axios.put(setProductCountUrl(hash, product_id, count), {});
}

export function getCart(hash: string) {
  return axios.get(getCartUrl(hash));
}

export function clearCart(hash: string) {
  return axios.put(clearCartUrl(hash), {});
}

export function createCart() {
  return axios.post(createCartUrl(), {});
}

export function addProduct({
  product_id,
  count,
  hash,
}: {
  product_id: number;
  count: number;
  hash: string;
}) {
  return axios.post(addProuctToCartUrl(hash, product_id, count), {});
}

export function cartCheckout(data: ICartCheckout, hash: string) {
  return axios.post(checkoutCartUrl(hash), {
    address: data.location.address,
    status: 'new',
    fio: Boolean(data.fio) ? data.fio : undefined,
    email: Boolean(data.email) ? data.email : undefined,
    phone: Boolean(data.phone) ? data.phone.replace(`+61`, '').replace(/ /g, '').trim() : undefined,
    deliveryDay: data.deliveryDay,
    comment: data.comment,
    unitOffice: data.unit_office
  });
}

export function cartEdit(
  fio: string,
  email: string,
  phone: string,
  hash: string,
  deliveryDay: string,
  comment?: string
) {

  return axios.put(getCartUrl(hash), {
    fio: Boolean(fio) ? fio : undefined,
    email: Boolean(email) ? email : undefined,
    phone: Boolean(phone) ? phone.replace(`+61`, '').replace(/ /g, '').trim() : undefined,
    deliveryDay,
    comment,
  }).then((res) => {
    console.log("res", res);
  }).catch((err) => {
    console.error("err", err.response);
  })
}


export function checkoutStripeReq(orderId: number) {
  return axios.get(stripeCheckoutUrl(orderId));
}

export const checkDeliveryCost = (id: number, lng: string, lat: string) => axios.put(getDeliveryCostUrl(id, lng, lat));
