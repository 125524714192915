import React, { FC } from 'react';
import { Text, TextType } from '../../../../../components/base/Text';
import { useVerifyCodeFormik } from '../../hooks/useVerifyCodeFormik';
import { SmsInput } from '../../../../../components/base/SmsInput';
import { LoginType } from '../../../../../interfaces/user';

interface IVerifyCodeFormProps {
  submit: (code: string) => void;
  phone: string;
  email: string;
  changePhoneNumber: () => void;
  verifyType: LoginType;
}

export const VerifyCodeForm: FC<IVerifyCodeFormProps> = ({
  submit,
  phone,
  email,
  changePhoneNumber,
  verifyType = LoginType.phone,
}) => {
  const formik = useVerifyCodeFormik(submit);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Text type={TextType.caption} margin={{ bottom: 16 }}>
        {verifyType === LoginType.email
          ? ` Enter the confirmation code sent to email address ${email}`
          : `Enter the code sent to phone ${phone}`}
      </Text>

      {verifyType === LoginType.phone ? (
        <SmsInput
          name={'code'}
          value={formik.values.code}
          onChange={(e) => {
            formik.handleChange(e);

            if (e.target.value && e.target.value.length === 4) {
              formik.submitForm();
            }
          }}
        />
      ) : (
        <SmsInput
          name={'code'}
          value={formik.values.code}
          onChange={(e) => {
            formik.handleChange(e);
            if (e.target.value && e.target.value.length === 4) {
              formik.submitForm();
            }
          }}
        />
        // <Input
        //   placeholder="Enter confirmation code"
        //   type="password"
        //   name="code"
        //   onChange={(event) => {
        //     formik.handleChange(event);
        //     if (event.target.value.length === 6) {
        //       formik.submitForm();
        //     }
        //   }}
        //   onBlur={formik.handleBlur}
        //   value={formik.values.code}
        //   error={Boolean(formik.touched.code && formik.errors.code)}
        //   errorHelper={(formik.touched.code && formik.errors.code) as string}
        // />
      )}
      {/*<Text type={TextType.caption} margin={{ bottom: 16, top: 16 }}>
        Didn`t receive SMS? 00:56
      </Text>*/}

      <Text
        style={{ cursor: 'pointer', marginTop: 16 }}
        onClick={changePhoneNumber}
        type={TextType.caption}
        margin={{ bottom: 16 }}
      >
        Another {verifyType === LoginType.email ? 'email address' : 'phone number'}
      </Text>
    </form>
  );
};
