import axios from 'axios';
import {
  GET_COMPANIES_URL,
  CREATE_COMPANY_URL,
  SEARCH_COMPANY_URL,
  editCompanyUrl,
  getCompanyUrl,
  delCompanyUrl,
  uploadLogoCompanyUrl,
  deleteLogoCompanyUrl,
  setDeliveryTimeUrl,
  stripeReqUrl,
  ME_URL,
  stripeStatReqUrl,
} from '../constants';

import { ICompanyEditProps, ICompanyAddProps, IEditDeliveryTime } from '../interfaces/companies';

export function getCompanies(page: number, perPage: number) {
  return axios.get(`${GET_COMPANIES_URL}?page=${page}&per_page=${perPage}`);
}

export function getCompany(id: number, location?: any) {
  return axios.get(
    getCompanyUrl(id),
    location && {
      params: {
        lat: location.lat,
        lng: location.lng,
      },
    }
  );
}

export function createCompany(data: ICompanyAddProps) {
  let formdata = new FormData();
  formdata.append('name', data.name);
  formdata.append('brand', data.brand);
  formdata.append('inn', data.inn);
  formdata.append('phone_number', data.phone_number);
  formdata.append('site', data.site);
  formdata.append('description', data.description || '');
  formdata.append('photo', data.photo);

  return axios.post(CREATE_COMPANY_URL, formdata);
}

export const searchCompanies = (inn: number) => {
  return axios.get(`${SEARCH_COMPANY_URL}?inn=${inn}`);
};
export function unbindManager(company_id: number, manager_id: number, bind: boolean) {
  return axios[bind ? 'put' : 'delete'](`/api/manager/${company_id}?manager_id=${manager_id}`);
}
export function editCompany(id: number, data: ICompanyEditProps, unbind: boolean = false) {
  if (unbind) {
    return axios.put(ME_URL, unbind ? { companyId: 0 } : data);
  }
  let form = new FormData();
  form.append('name', data.name || '');
  form.append('brand', data.brand || '');
  form.append('phone_number', data.phone_number || '');
  form.append('description', data.description || '');
  form.append('inn', data.inn || '');
  form.append('logo', data.logo || '');
  form.append('site', data.site || '');
  if (data.sameDayDelivery) form.append('sameDayDelivery', data.sameDayDelivery);
  return axios.post(editCompanyUrl(id), form);
}

export function delCompany(id: number) {
  return axios.delete(delCompanyUrl(id));
}

export function uploadLogo(id: number, data: FormData) {
  return axios.post(uploadLogoCompanyUrl(id), data, {
    headers: { 'content-type': 'multipart/form-data' },
  });
}

export function deleteLogo(id: number) {
  return axios.delete(deleteLogoCompanyUrl(id));
}

export const setDeliveryTime = (id: number, data: IEditDeliveryTime) => {
  let url = setDeliveryTimeUrl(id);

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      url += `${url === setDeliveryTimeUrl(id) ? '?' : '&'}${prop}=${(data as any)[prop]}`;
    }
  }

  return axios.post(url, {});
};

export function sendStripeRequest(companyId: number, stripeId?: number) {
  return axios.post(stripeReqUrl(companyId, stripeId), {});
}
export function sendStripeStatRequest() {
  return axios.get(stripeStatReqUrl(), {});
}
// export function checkoutStripeRequest(orderId: number) {
//   return axios.get(stripeCheckoutUrl(orderId));
// }
