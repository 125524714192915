import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Preloader from '../../../../../../components/old/ui/Preloader/Preloader';
import { IAppState } from '../../../../../../store/rootDuck';
import { useGetReviews } from './hooks/useGetReviews';
import { useDeleteReview } from './hooks/useDeleteReview';
import { Text, TextType } from '../../../../../../components/base/Text';
import { colors } from '../../../../../../constants/colors';
import { Button } from '../../../../../../components/base/Button';
import { useStyles } from './hooks/useStyles';
import { useDefineUserRole } from '../../../../../../hooks/useDefineUserRole';
import Rating from '../Rating';
import { IconButton } from '@material-ui/core';
import { API_DOMAIN } from '../../../../../../constants';
import { toAbsoluteUrl } from '../../../../../../../_metronic';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router';
import { IProduct } from '../../../../../../interfaces/product';

interface IProps {
  meAdd?: boolean;
  product: IProduct;
  name: string;
}

const ReviewsList: React.FC<IProps> = ({ meAdd = false, product, name }) => {
  const classes = useStyles();
  const intl = useIntl();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const history = useHistory();
  const productId = useMemo(() => product.id, [product]);

  const [fetchReviews, loading] = useGetReviews();
  const [deleteReview] = useDeleteReview();
  const [pageReviews, setPageReviews] = useState({ perPage: 20, page: 1 });

  useEffect(() => {
    productId && fetchReviews(productId, pageReviews.perPage, pageReviews.page);
  }, [productId, fetchReviews]);

  const handleDeleteReview = useCallback(
    (id: number) => {
      deleteReview(id, productId, pageReviews.perPage, pageReviews.page);
    },
    [productId, pageReviews]
  );

  if (loading) return <Preloader />;

  return (
    <>
      <div className={classes.headerTitle}>Reviews</div>

      <div className={classes.reviews}>
        <div className={classes.reviewsList}>
          {reviewsProduct && reviewsProduct.reviews.length > 0 && (
            <>
              {reviewsProduct.reviews.map((item) => (
                <div style={{ marginBottom: 32 }}>
                  <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
                    <div className={classes.avatar}>
                      <img
                        alt={item.user.fio}
                        src={
                          item.user.avatar
                            ? `${API_DOMAIN}/${item.user.avatar}`
                            : toAbsoluteUrl('/images/placeholder.png')
                        }
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 'auto' }}>
                      <Text type={TextType.semiboldSubtitle1} margin={{ bottom: 4 }}>
                        {item.user.fio} about this product
                      </Text>
                      <Rating rating={item.rating} />
                    </div>

                    {(isAdmin || item.user?.id === me?.id) && (
                      <Tooltip title={intl.formatMessage({ id: 'PRODUCT.REVIEW.BUTTON.DEL' })}>
                        <IconButton onClick={() => handleDeleteReview(item.id)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>

                  <Text>{item.text}</Text>
                </div>
              ))}

              <div
                style={{
                  paddingTop: 16,
                  borderTop: `1px solid ${colors.primary.lightGrey}`,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {reviewsProduct.total !== reviewsProduct.reviews.length && (
                  <Text
                    style={{ cursor: 'pointer' }}
                    color={colors.primary.green}
                    type={TextType.semiboldButton}
                    onClick={() => {}}
                  >
                    Load more reviews
                  </Text>
                )}
              </div>
            </>
          )}
        </div>

        <div className={classes.statistics}>
          <div className={classes.statisticsHeader}>
            <Rating rating={reviewsProduct?.rating} />

            <Text style={{ fontSize: 17, marginBottom: -10 }} type={TextType.boldH3}>
              {reviewsProduct?.rating || 0}/5
            </Text>
          </div>

          {reviewsProduct && (
            <div className={classes.statisticsList}>
              {Array(5)
                .fill('')
                .map((_, index) => {
                  const length =
                    reviewsProduct.reviews.filter((item) => item.rating === 5 - index).length || 0;
                  const percent = (length * 100) / reviewsProduct.reviews.length || 0;

                  return (
                    <div key={index} className={classes.statisticsItem}>
                      <Text type={TextType.body2}>{5 - index} stars</Text>
                      <div className="progress">
                        <span style={{ width: `${percent}%` }} />
                      </div>
                      <Text type={TextType.semiboldSubtitle2}>{length}</Text>
                    </div>
                  );
                })}
            </div>
          )}

          {(isBuyer || isAdmin) && !meAdd && (
            <div className={classes.feedButton}>
              <Button
                onClick={() => history.push('/products/review', { product, name })}
                text={'Write a feedback'}
                full
                leftIcon={'Edit'}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewsList;
