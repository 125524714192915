import React, { useState } from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import Header from '../../app/components/base/Header';
import HTMLClassService from './HTMLClassService';
import Sidebar from '../../app/pages/home/main/components/Sidebar/Sidebar';
import DeliveryInfo from '../../app/pages/buyer/components/DeliveryInfo';
import { Button } from '../../app/components/base/Button';
import { SidebarPosition, UserType } from '../../app/constants';
import './assets/Base.scss';
import { createStyles, makeStyles } from '@material-ui/styles';
const htmlClassService = new HTMLClassService();
const useStyle = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      height: '100vh',
      [theme.breakpoints.down(960)]: {
        justifyContent:"center",
      },
    },
    Aside: {
      position: 'fixed',
    },
    leftSidebar: {
      minWidth: '265px',
      width: '265px',
      zIndex: '101',
      [theme.breakpoints.down(960)]: {
        display:"none",
      },
    },
    rightSidebar: {
      transition: 'transform 0.3s ease',
      minWidth: '265px',
      position: 'fixed',
      right:0,
      width: '19%',
      zIndex: '100',
      height: '100vh',
      display:"none",
      [theme.breakpoints.down(960)]: {
        display:"inline",
      },
    },
    rightSidebarIsShown: {
      transform: 'translateX(100%)',
    },
    rightSidebarWithBoxShadow: {
      boxShadow: '-11px 22px 44px -12px rgba(124, 177, 66, 0.25)',
    },
    rightWrapper: {
      display: 'flex',
      flexDirection: 'row',
      //flex: 1,
      margin: '20px 20px',
      width: 'calc(100% - 300px)',
      // minWidth: '1000px',
      height: '100vh',
      [theme.breakpoints.down(960)]: {
        width: '100%',
        margin: '0px 20px',
      },
    },
    childrenContaner: {
      width: 'calc(100% - 300px)',
      flex: 1,
      [theme.breakpoints.down(960)]: {
        marginTop: 50,
        width: '92%',
      },
    },
    headerContaner: {
      display:"none",
      [theme.breakpoints.down(960)]: {
        display:"inline",
      },
    },
  })
);
function Layout({ children, asideDisplay, subheaderDisplay, selfLayout, layoutConfig }) {
  htmlClassService.setConfig(layoutConfig);
  const classes = useStyle();
  const [isShown, setIsShown] = useState(true);
  const [leftPosition, setLeftPosition] = useState({ left: 0, top: 0 });
  // const contentCssClasses = htmlClassService.classes.content.join(' ');

  return selfLayout !== 'blank' ? (
    <div className={classes.wrapper}>
      <div className={classes.leftSidebar} onClick={() => setIsShown && setIsShown(true)}>
        <div className={classes.Aside}>
          <Sidebar
            position={SidebarPosition.LEFT}
            userType={UserType.PARTNER}
            onSwitch={() => {}}
          />
        </div>
      </div>
      <div className={classes.rightWrapper}>
        <div className={classes.headerContaner}>
        <Header
          leftPosition={leftPosition}
          setLeftPosition={setLeftPosition}
          setIsShown={setIsShown}
        />
        </div>
        <div className={classes.childrenContaner} onClick={() => setIsShown && setIsShown(true)}>{children}</div>
      </div>
      <div className={`${classes.rightSidebar} ${isShown ? classes.rightSidebarIsShown : ''} ${isShown ? '' : classes.rightSidebarWithBoxShadow}`}>
        <div className={classes.Aside}>
          <Sidebar
            position={SidebarPosition.LEFT}
            userType={UserType.PARTNER}
            onSwitch={() => {}}
            isShown={isShown}
            setIsShown={setIsShown}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.wrapper}>{children}</div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, 'self.layout'),
  asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  subheaderDisplay: objectPath.get(layoutConfig, 'subheader.display'),
  desktopHeaderDisplay: objectPath.get(layoutConfig, 'header.self.fixed.desktop'),
  contentContainerClasses: '',
});

export default connect(mapStateToProps)(Layout);
