import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    position: 'relative',
    '& input': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      outline: 'none',
      opacity: 0,
      width: '100%',
    },
    '& span': {
      width: 50,
      height: 50,
      border: `1px solid ${colors.complementary.green}`,
      borderRadius: 12,
      marginRight: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 36,
    },

    '&:focus-within span.focus': {
      borderColor: colors.primary.green,
    },
  },
}));
