import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormattedMessage, useIntl, WrappedComponentProps } from 'react-intl';
import { TextField } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { actions as authActions } from '../../../../store/ducks/auth.duck';
import { Button } from '../../../../components/base/Button';
import { IAppState } from '../../../../store/rootDuck';
import Preloader from '../../../../components/old/ui/Preloader/Preloader';
import { LoginType } from '../../../../interfaces/user';
import { Input } from '../../../../components/base/Input';

const Login: React.FC<TPropsFromRedux & WrappedComponentProps> = ({
  clearLogin,
  login,
  loginLoading,
  loginSuccess,
  loginError,

  clearLoginByPhone,
  loginByPhone,
  loginByPhoneLoading,
  loginByPhoneSuccess,
  loginByPhoneError,

  clearSendCode,
  sendCodeConfirm,
  sendCodeLoading,
  sendCodeSuccess,
  sendCodeError,

  clearFindInSystem,
  findInSystemSuccess,

  authData,
}) => {
  const history = useHistory();
  const intl = useIntl();

  let validationSchema = {};

  if (authData && authData.type === LoginType.login) {
    validationSchema = {
      password: Yup.string().required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
    };
  }
  if (authData && authData.type === LoginType.phone) {
    validationSchema = {
      codeConfirm: Yup.string().required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
      ),
    };
  }

  const backHandler = useCallback(() => {
    clearFindInSystem();

    history.push('/auth');
  }, [clearFindInSystem, history]);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '000000',
      codeConfirm: '',
    },
    onSubmit: (values) => {
      if (authData && authData.type === LoginType.login) {
        login({ login: authData?.value || '', password: values.password });
      }
      if (authData && authData.type === LoginType.phone) {
        loginByPhone({
          phone: authData?.value || '',
          code: values.codeConfirm,
          country_code: '61',
        });
      }
    },
    validationSchema: Yup.object().shape(validationSchema),
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loginError) {
      enqueueSnackbar(loginError, {
        variant: 'error',
      });
      clearLogin();
    }
  }, [clearLogin, enqueueSnackbar, loginError]);

  useEffect(() => {
    if (!findInSystemSuccess) {
      history.push('/auth');
    }

    return () => {
      clearFindInSystem();
      clearSendCode();
    };
  }, []);

  useEffect(() => {
    if (sendCodeSuccess || sendCodeError) {
      enqueueSnackbar(
        sendCodeSuccess
          ? 'A confirmation code has been sent to the number you specified'
          : sendCodeError,
        {
          variant: sendCodeSuccess ? 'success' : 'error',
        }
      );
      clearSendCode();
    }
  }, [enqueueSnackbar, sendCodeSuccess, sendCodeError, clearSendCode]);

  useEffect(() => {
    if (loginByPhoneError) {
      enqueueSnackbar(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID.CODE' }), {
        variant: 'error',
      });
      clearLoginByPhone();
    }
  }, [enqueueSnackbar, loginByPhoneError]);

  useEffect(() => {
    if (loginSuccess || loginByPhoneSuccess) {
      clearLogin();
      clearLoginByPhone();
      history.replace('/');
    }
  }, [clearLoginByPhone, clearLogin, fetch, loginSuccess, loginByPhoneSuccess]);

  if (sendCodeLoading) return <Preloader />;

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>{intl.formatMessage({ id: 'AUTH.LOGIN.TITLE' })}</h3>
          </div>

          <form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
            {authData && authData.type === LoginType.login && (
              <>
                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label={intl.formatMessage({
                      id: 'AUTH.INPUT.PASSWORD',
                    })}
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    margin: '15px 0',
                  }}
                >
                  <Link to="/auth/forgot-password" className="kt-link kt-login__link-forgot">
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                </div>

                {/*<div className="kt-login__actions">*/}
                {/*  <ButtonWithLoader*/}
                {/*    onPress={handleSubmit}*/}
                {/*    disabled={findInSystemLoading}*/}
                {/*    loading={findInSystemLoading}*/}
                {/*  >*/}
                {/*    <FormattedMessage id="AUTH.MAIN.BUTTON" />*/}
                {/*  </ButtonWithLoader>*/}
                {/*</div>*/}

                <div
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                >
                  <div className="kt-login__actions" style={{ marginRight: 30 }}>
                    <button
                      onClick={() => history.goBack()}
                      type="button"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                    >
                      {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
                    </button>
                  </div>

                  <div className="kt-login__actions">
                    <Button
                      onClick={() => handleSubmit()}
                      loading={loginLoading}
                      text={intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}
                    ></Button>
                  </div>
                </div>
              </>
            )}

            {authData && authData.type === LoginType.phone && (
              <>
                <div className="form-group">
                  <Input
                    type="text"
                    label={intl.formatMessage({
                      id: 'AUTH.INPUT.CODE',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'AUTH.INPUT.CODE',
                    })}
                    className="kt-width-full"
                    name="codeConfirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.codeConfirm}
                    errorHelper={(touched.codeConfirm && errors.codeConfirm) || ''}
                    error={Boolean(touched.codeConfirm && errors.codeConfirm)}
                  />
                </div>

                <div
                  className="kt-login__actions"
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                >
                  <Button
                    onClick={() => backHandler()}
                    type="button"
                    light
                    className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                    style={{ marginRight: 30 }}
                    text={intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
                  />

                  <Button
                    onClick={() => handleSubmit()}
                    disabled={loginLoading || loginByPhoneLoading}
                    loading={loginLoading || loginByPhoneLoading}
                    text={intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    loginLoading: state.auth.loginLoading,
    loginSuccess: state.auth.loginSuccess,
    loginError: state.auth.loginErrors,

    loginByPhoneLoading: state.auth.loginByPhoneLoading,
    loginByPhoneSuccess: state.auth.loginByPhoneSuccess,
    loginByPhoneError: state.auth.loginByPhoneErrors,

    sendCodeLoading: state.auth.sendCodeConfirmLoading,
    sendCodeSuccess: state.auth.sendCodeConfirmSuccess,
    sendCodeError: state.auth.sendCodeConfirmError,

    findInSystemLoading: state.auth.findInSystemLoading,
    findInSystemSuccess: state.auth.findInSystemSuccess,
    findInSystemError: state.auth.findInSystemError,

    authData: state.auth.authData,
  }),
  {
    login: authActions.loginRequest,
    clearLogin: authActions.clearLogin,

    loginByPhone: authActions.loginByPhoneRequest,
    clearLoginByPhone: authActions.clearLoginByPhone,

    sendCodeConfirm: authActions.sendCodeRequest,
    clearSendCode: authActions.clearSendCode,

    findInSystem: authActions.findInSystemRequest,
    clearFindInSystem: authActions.clearFindInSystem,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Login);
