import React from 'react';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: 900,
    maxHeight: 800,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 250,
    },
    [theme.breakpoints.only(500)]: {
      maxWidth: 500,
    },
    objectFit: 'contain',
  },

  backdrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
}));

interface IProps extends DialogProps {
  handleClose: any;
  url: string;
}

const ImageDialog: React.FC<IProps> = ({ open, handleClose, url }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
        <img className={classes.img} src={url} alt='content'/>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
