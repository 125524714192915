import React, { FC } from 'react';
import { useStyles } from './hooks/useStyles';
import InputMask from 'react-input-mask';
import { Iconly } from 'react-iconly';
import { colors } from '../../../constants/colors';

interface IInputProps {
  error?: boolean;
  errorHelper?: string;
  last?: boolean;
  label?: string;
  phone?: boolean;
  multiLine?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  ref?: any;
  rightAction?: { onClick: () => void; icon: string };
}

export const Input: FC<IInputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
  error,
  errorHelper,
  last,
  label,
  phone,
  ref,
  rightAction,
  multiLine = false,
  ...props
}) => {
  const classes = useStyles();
  const errorStyle = { boxShadow: `0 0 0 1px ${colors.primary.red}` };
  return (
    <div
      className={classes.container}
      style={{
        marginBottom: last ? 0 : 16,
      }}
    >
      {!!label && <label>{label}</label>}

      <div style={error ? errorStyle : {}}>
        {props.leftIcon && (
          <Iconly
            name={props.leftIcon}
            set="bulk"
            primaryColor={colors.primary.green}
            size="medium"
            style={{ marginRight: 8 }}
          />
        )}

        {phone ? (
          <InputMask {...props} mask={`+61 999 999 999`} style={{ width: '100%' }}>
            {(inputProps: any) => <input {...inputProps} style={{ width: '100%' }} />}
          </InputMask>
        ) : (
          <input
            ref={ref}
            {...props}
            onChange={(e) => {
              error = false;
              props.onChange && props.onChange(e);
            }}
            style={{ width: '100%' }}
          />
        )}

        {props.rightIcon && (
          <Iconly
            name={props.rightIcon}
            set="bulk"
            primaryColor={colors.primary.green}
            size="medium"
            style={{ marginLeft: 8 }}
          />
        )}
        {rightAction && (
          <div
            onClick={rightAction.onClick}
            className={classes.actionBtn}
            style={{ width: 'fit-content', boxShadow: 'none' }}
          >
            <Iconly name="User" set="bold" primaryColor={colors.primary.white} />
          </div>
        )}
      </div>

      {error && <p style={{ marginLeft: 10 }}>{errorHelper}</p>}
    </div>
  );
};
