import axios from 'axios';

import { API_SEARCH_LOCATION_URI } from '../constants';

const callLocationApi = axios.create({
  baseURL: `${API_SEARCH_LOCATION_URI}`,
  headers: {
    Accept: '*/*',
  },
});

export const getLocation = (q) =>
  callLocationApi.get(
    `${API_SEARCH_LOCATION_URI}&address=${q}&region=AU&sensor=true&language=en-AU&components=country:AU**`
  );

export const getLocationByCoord = ({ lat, lng }) =>
  callLocationApi.get(`${API_SEARCH_LOCATION_URI}&latlng=${lat},${lng}`);
