import { ITypeParameter } from '../../../../../../interfaces/productType';
import { ICartProps } from '../Cart';
import { useStyles } from '../../hooks/useStyles';
import { Text, TextType } from '../../../../../../components/base/Text';
import RichEditor from '../../../../../../components/old/RichEditor';
import { IDeliveryTime } from '../../../../../../interfaces/companies';

interface IProps {
  cartProps: ICartProps;
  parameter_values: ITypeParameter[];
  descriptionHtml: any;
  periods: IDeliveryTime[];
}
export const Description: React.FC<IProps> = ({
  cartProps,
  parameter_values,
  descriptionHtml,
  periods,
}) => {
  const classes = useStyles();
  const ignoreNames = [
    'Bouquet color',
    'Bouquet size',
    'Occasion',
    'Width',
    'Height',
    'Number of flowers',
  ];
  let description = parameter_values.filter((param) => param.parameter_name === 'Description');
  let sizeSection = parameter_values.filter((param) =>
    ignoreNames.slice(3).includes(param.parameter_name as string)
  );
  parameter_values = parameter_values.filter((param) => {
    return !ignoreNames.includes(param.parameter_name as string);
  });
  parameter_values.push(...description);
  let b = sizeSection.filter((item) => item.parameter_name === 'Number of flowers');
  sizeSection = sizeSection.filter((item) => item.parameter_name !== 'Number of flowers');
  sizeSection.push(...b);
  return (
    <div className={classes.description}>
      <div className={classes.compositionSize}>
        {parameter_values?.map((item) => {
          return (
            <div key={item.parameter_id}>
              <Text margin={{ bottom: 24 }} type={TextType.semiboldSubtitle1}>
                {item.parameter_name || item.parameter_id}
              </Text>

              {Array.isArray(item.value) ? (
                <div style={{ marginBottom: 16 }}>
                  {item.value.map((item, index) => (
                    <Text className={classes.point} key={index} margin={{ bottom: 16 }}>
                      {item}
                    </Text>
                  ))}
                </div>
              ) : (
                <Text className={classes.point} margin={{ bottom: 32 }}>
                  {item.value}
                </Text>
              )}
            </div>
          );
        })}
        {sizeSection.length > 0 && (
          <div>
            <Text margin={{ bottom: 24 }} type={TextType.semiboldSubtitle1}>
              Size
            </Text>
            {sizeSection.map((item, index) => {
              return (
                <div className={classes.sizeSection}>
                  <Text key={item.id} margin={{ bottom: 16 }}>
                    {item.parameter_name}
                  </Text>
                  <div className={classes.dots}> </div>

                  <Text key={item.id + 's'} margin={{ bottom: 16 }}>
                    {item.value}
                    {item.parameter_name !== 'Number of flowers' ? ' см' : ''}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Text margin={{ bottom: 24, top: 12 }} type={TextType.semiboldSubtitle1}>
        Description
      </Text>
      <RichEditor
        toolbar={false}
        disabled={true}
        editorState={descriptionHtml}
        placeholder={''}
        setEditorState={() => {}}
        elevation={0}
        border={false}
        style={{ padding: 0, fontSize: 10 }}
      />
      <Text margin={{ bottom: 24, top: 12 }} type={TextType.semiboldSubtitle1}>
        Delivery periods
      </Text>
      {periods.map((time, index) => (
        <div className={classes.sizeSection}>
          <Text margin={{ bottom: 16 }}>{`Time period ${index + 1}`}</Text>
          <div className={classes.dots}> </div>

          <Text margin={{ bottom: 16 }}>{`${time.start_time} - ${time.stop_time}`}</Text>
        </div>
      ))}
    </div>
  );
};
