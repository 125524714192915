/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { createStyles, makeStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import HomePage from '../pages/home/HomePage';
import ErrorsPage from '../pages/errors/ErrorsPage';
import LogoutPage from '../pages/auth/PartnerAuth/components/Logout';
import PartnerAuth from '../pages/auth/PartnerAuth';

import { LayoutContextProvider } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';
import ReactGA from 'react-ga';

import * as routerHelpers from './RouterHelpers';

import { IAppState } from '../store/rootDuck';
import { useDefineUserRole } from '../hooks/useDefineUserRole';
import BuyerPages from '../pages/buyer';
import { colors } from '../constants/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    success: {
      backgroundColor: `${colors.primary.green} !important`,
    },
    error: {
      backgroundColor: colors.primary.red,
    },
  })
);

ReactGA.initialize('UA-197341215-1');

export const Routes = withRouter(({ history }) => {
  const classes = useStyles();
  const lastLocation = useLastLocation();

  // routerHelpers.saveLastLocation(lastLocation);

  useEffect(() => {
    // console.log("lastLocation", lastLocation);
  }, [lastLocation]);

  useEffect(() => {
    history.listen((location) => {
      ReactGA.pageview(location.pathname + location.search);
      routerHelpers.saveLastLocation(location);
    });
  }, []);

  const { isAuthorized, menuConfig, userLastLocation, isBuyer } = useSelector(
    ({ auth, builder: { menuConfig } }: IAppState) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      isBuyer: useDefineUserRole(auth.user, 'ROLE_BUYER'),
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isAuthorized) {
      // routerHelpers.forgotLastLocation();
    }
  }, [isAuthorized]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
      }}
    >
      {/* Create `LayoutContext` from current `history` and `menuConfig`. */}
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={LogoutPage} />
          <Route path="/auth">
            <PartnerAuth />
          </Route>

          {!isAuthorized || isBuyer ? (
            <Route path="/" component={BuyerPages} />
          ) : (
            <Route path={'/'}>
              <Layout>
                <HomePage userLastLocation={userLastLocation} />
              </Layout>
            </Route>
          )}
        </Switch>
      </LayoutContextProvider>
    </SnackbarProvider>
  );
});
