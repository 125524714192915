import React, { lazy, useEffect, useState, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from '../../components/base/Header';
import { useStyles } from './hooks/useStyles';
import { connect, ConnectedProps } from 'react-redux';
import { IAppState } from '../../store/rootDuck';
import { actions as authActions } from '../../store/ducks/auth.duck';
import { WrappedComponentProps } from 'react-intl';
import { useHistory } from 'react-router';
import { SidebarPosition } from '../../constants';
import Sidebar from '../home/main/components/Sidebar/Sidebar';
import BuyerAuth from '../auth/BuyerAuth';
import ProductView from '../home/products/ProductView';
import CompanyView from '../home/companies/CompanyView';
import { BuyerContext } from './Context';
import { actions as profileActions } from '../../store/ducks/profile.duck';
import * as builder from '../../../_metronic/ducks/builder';
import { actions as cartActions } from '../../store/ducks/cart.duck';
import { UserRoles } from '../../interfaces/user';
import Preloader from '../../components/old/ui/Preloader/Preloader';
import { Input } from '../../components/base/Input';
import { Button } from '../../components/base/Button';
import { actions as productActions } from '../../store/ducks/product.duck';
import Footer from '../home/main/components/Footer/Footer';
import CartSidebar from '../home/main/components/CartSidebar';

import AutoCompleteInput from '../../components/base/AutocompleteLocationsCopy/index';
import DeliveryInfo from './components/DeliveryInfo';

const MainPage = lazy(() => import('../home/main'));
const NewProduct = lazy(() => import('../home/NewProduct'));
const PersonalInfo = lazy(() => import('../home/PersonalInfo'));
const Market = lazy(() => import('../home/MarketPage'));
const ProductsPage = lazy(() => import('../home/products'));
const OrdersPage = lazy(() => import('../home/orders'));
const CartPage = lazy(() => import('../home/cart'));
const SettingsPage = lazy(() => import('../home/settings'));

const BuyerPages: React.FC<TPropsFromRedux & WrappedComponentProps> = ({
  isAuthorized,
  setAuthFormvisibility,
  authFormVisibility,
  userRoles,
  setMenuConfig,
  getOrCreateCart,
  getOrCreateCartLoading,
  editMe,
  getLocalGuestCart,
  isAdmin,
  me,
  cartLenght,
  guestCartLenght,
  cartSidebarState,
  setCartSidebarState,
}) => {
  const [sidebarState, setSidebarState] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();
  const [Left, setLeft] = useState<any>(null);
  const [Right, setRight] = useState<any>(null);
  const [leftPosition, setLeftPosition] = useState({ left: 0, top: 0 });

  // const [location, setLocation] = useState({ address: '' });

  const toggleSidebar = () => {
    setSidebarState((prev) => !prev);
  };

  const sidebarSwitch = (data: any) => {
    setSidebarState(false);
    if (data == 'Login / Register') setAuthFormvisibility(true);
  };

  const getStorageLocal = useCallback(() => {
    const localLocation = localStorage.getItem('location');
    const parseLocalLocation = localLocation && JSON.parse(localLocation);
    return parseLocalLocation;
  }, []);

  useEffect(() => {
    const location = getStorageLocal();
    if (location) {
      setLocation(getStorageLocal());
    }
  }, []);

  // Проверка на существование корзины у пользователя
  const [location, setLocation] = useState({ address: '' });
  useEffect(() => {
    const localCart = localStorage.getItem('guestCart');
    if (userRoles && userRoles.includes(UserRoles.ROLE_BUYER) && localCart) {
      const localLocation = localStorage.getItem('location');
      const parseLocalLocation = localLocation && JSON.parse(localLocation);
      if (Boolean(parseLocalLocation)) {
        editMe({ location: parseLocalLocation });
        // localStorage.removeItem('location');
      }
      if (!localStorage.getItem('guestCart')) history.replace('/personal/profile');
    }

    if (userRoles && !userRoles.includes(UserRoles.ROLE_VENDOR) && !Boolean(isAdmin)) {
      getOrCreateCart();
    }
  }, [getOrCreateCart, isAdmin, userRoles]);

  useEffect(() => {
    !Boolean(isAuthorized) && getLocalGuestCart();
  }, [isAuthorized, getLocalGuestCart]);

  if (getOrCreateCartLoading) return <Preloader />;

  return (
    <BuyerContext.Provider value={{ setLeft, setRight, leftPosition }}>
      <Header
        leftPosition={leftPosition}
        setLeftPosition={setLeftPosition}
        leftSection={
          <div style={{ flex: 1, marginRight: 10 }} className="lInput">
            <DeliveryInfo />
          </div>
        }
        rightSection={
          <div className={classes.cartBox}>
            <Button
              text={`${isAuthorized ? cartLenght || 0 : guestCartLenght || 0}`}
              leftIcon="Buy"
              style={{ marginLeft: 10, alignSelf: 'flex-end' }}
              onClick={() => {
                setCartSidebarState(!cartSidebarState);
              }}
            />
          </div>
        }
        onUserClick={() =>
          !isAuthorized ? setAuthFormvisibility(true) : history.push('/personal')
        }
        onMenuClick={() => toggleSidebar()}
        style={{
          position: Market._payload._status === 1 ? 'static' : 'fixed',
        }}
      />
      <div
        style={{
          marginTop: '100px',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <BuyerAuth open={authFormVisibility} close={() => setAuthFormvisibility(false)} />
        <CartSidebar />
        <Sidebar
          state={sidebarState}
          onSwitch={(data: any) => sidebarSwitch(data)}
          position={SidebarPosition.RIGHT}
          authorized={!!isAuthorized}
        />
        <section className={classes.container}>
          <Switch>
            <Route path="/main" component={MainPage} />
            <Route path="/new-product" component={NewProduct} />
            <Route path="/personal/:page?" component={PersonalInfo} />
            <Route path="/market/:id?" component={Market} />
            <Route path="/products" component={ProductsPage} />
            <Route path="/orders" component={OrdersPage} />
            <Route path="/cart" component={CartPage} />
            <Route path="/viewproduct/:id" component={ProductView} />
            <Route path="/viewcompany/:id" component={CompanyView} />
            <Route path="/settings" component={SettingsPage} />
            <Redirect to="/main" />
          </Switch>
        </section>

        <footer>
          <Footer />
        </footer>
      </div>
    </BuyerContext.Provider>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
    isAuthorized: state.auth.user != null,
    getOrCreateCartLoading: state.cart.getOrCreateCartLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
    authFormVisibility: state.auth.authFormVisibility,
    cartLenght: state.cart.cart?.goods_num,
    guestCartLenght: state.cart.guestCart?.goods_num,
    cartSidebarState: state.cart.cartSidebarState,
  }),
  {
    setAuthFormvisibility: authActions.setAuthFormVisibility,
    editMe: profileActions.editRequest,
    setMenuConfig: builder.actions.setMenuConfig,
    getOrCreateCart: cartActions.getOrCreateRequest,
    getLocalGuestCart: cartActions.getLocalGuestCart,
    fetch: productActions.fetchRequest,
    setCartSidebarState: cartActions.setCartSidebarState,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BuyerPages);
