import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Inter'].join(','),
    },

    palette: {
      contrastThreshold: 3,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#7CB142',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        //main: '#0abb87',
        main: '#fd397a',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#ffffff',
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#cd0063',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: {
        // light: will be calculated from palette.primary.main,
        main: '#0abb87',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */

    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#484848',
          fontWeight: 400,
          fontSize: 12,
          letterSpacing: 0.4
        }
      },
      MuiOutlinedInput: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#DED6D6",
            fontWeight: 600,
            fontSize: 16
          },
        }
      }
      // MuiInputLabel: {
      //   outlined: {
      //     '&$shrink': {
      //        transform: 'translate(0px, -20px) scale(0.75)',
      //     },
      //   },
      // },
    }
  }
);

export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
