import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import { SigninForm } from './components/SigninForm';
import { Text, TextType } from '../../../components/base/Text';
import { VerifyCodeForm } from './components/VerifyCodeForm';
import { connect, ConnectedProps } from 'react-redux';
import { IAppState } from '../../../store/rootDuck';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LoginType, UserRoles } from '../../../interfaces/user';
import Preloader from '../../../components/old/ui/Preloader/Preloader';
import { SignupForm } from './components/SignupForm';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { useFormatMessage } from '../../../hooks/useFormatMessage';

interface IBuyerAuthProps {
  open: boolean;
  close: () => void;
}

enum AuthStep {
  checkInSystem = 'auth',
  signUp = 'signup',
  verifyCode = 'verifyCode',
}

const clearPhone = (phone: string) => phone.replace('+61', '').replace(/ /g, '').trim();

const BuyerAuth: React.FC<WrappedComponentProps & TPropsFromRedux & IBuyerAuthProps> = ({
  clearLoginByPhone,
  clearLoginByEmail,
  loginByPhone,
  loginByEmail,
  loginByPhoneLoading,
  loginByPhoneSuccess,
  loginByPhoneErrors,
  clearSendSms,
  sendSms,
  sendSmsLoading,
  sendSmsSuccess,
  sendSmsErrors,
  findInSystem,
  findInSystemSuccess,
  findInSystemLoading,
  findInSystemError,
  loginEmailErrors,
  loginEmailLoading,
  loginEmailSuccess,
  sendConfirmationCode,
  confirmationCodeFail,
  confirmationCodeLoading,
  confirmationCodeSuccess,
  clearConfirmation,
  open,
  close,
  registerUser,
  registerFail,
  registerLoading,
  registerSuccess,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState(AuthStep.checkInSystem);
  const [signinPhone, setSigninPhone] = useState('');
  const [signInEmail, setSigninEmail] = useState('');
  const [verifyType, setVerifyType] = useState<LoginType>(LoginType.phone);
  // const [activeTab, setActiveTab] = useState<number>(0);
  // const [isRegister, setIsRegister] = useState<boolean>(false);
  const fm = useFormatMessage();


  const signInSubmit = (data: string, active: boolean) => {
    setSigninPhone(data);
    findInSystem({ value: data, type: active ? LoginType.email : LoginType.phone });
    !active
      ? sendSms({
        country_code: '61',
        phone: clearPhone(data),
        country_name: 'Australia',
        roles: [UserRoles.ROLE_BUYER],
      })
      : sendConfirmationCode({ email: data });
  };

  useEffect(() => {
    if (loginByPhoneSuccess || loginEmailSuccess) {
      close();
    }
    return () => {
      clearLoginByEmail();
      clearLoginByPhone();
    };
  }, [loginByPhoneSuccess, loginEmailSuccess]);



  useCrudSnackbar({
    success: sendSmsSuccess,
    error: sendSmsErrors,
    clear: clearSendSms,
    successMessage: fm('AUTH.SEND_SMS.SUCCESS'),
    errorMessage: `${fm('ERROR')} ${sendSmsErrors}`,
  });
  useCrudSnackbar({
    success: confirmationCodeSuccess || registerSuccess,
    error: confirmationCodeFail,
    clear: clearConfirmation,
    successMessage: fm('AUTH.SEND_CODE.SUCCESS'),
    errorMessage: `${fm('ERROR')} ${confirmationCodeFail}`,
  });
  useCrudSnackbar({
    success: false,
    error: loginByPhoneErrors,
    successMessage: fm('AUTH.SEND_CODE.SUCCESS'),
    errorMessage: `${fm('ERROR')} ${loginByPhoneErrors}`,
    afterSuccessOrError: () => {
      setStep(AuthStep.checkInSystem);
    },
  });
  useCrudSnackbar({
    success: loginEmailSuccess,
    error: loginEmailErrors,
    clear: clearSendSms,
    successMessage: fm('AUTH.SEND_SMS.SUCCESS'),
    errorMessage: `${fm('ERROR')} ${loginEmailErrors}`,
  });
  useEffect(() => {
    if (!findInSystemLoading && (sendSmsSuccess || confirmationCodeSuccess)) {
      if (findInSystemSuccess) {
        setStep(AuthStep.verifyCode);
      } else if (findInSystemError) {
        setStep(AuthStep.signUp);
        // setActiveTab(1);
      }
    }
  }, [sendSmsSuccess, findInSystemLoading, confirmationCodeSuccess]);
  useEffect(() => {
    if (confirmationCodeFail) {
      setStep(AuthStep.signUp);
    }
  }, [confirmationCodeFail]);
  useEffect(() => {
    if (!open) {
      setStep(AuthStep.checkInSystem);
      setVerifyType(LoginType.phone);
    }
  }, [open]);
  useEffect(() => {
    if (registerSuccess) {
      // setVerifyType(LoginType.email);
      setStep(AuthStep.verifyCode);
    }
  }, [registerSuccess]);


  const config = {
    [AuthStep.checkInSystem]: {
      title: 'Sign in',
      form: (
        <SigninForm
          verifyType={verifyType}
          submit={signInSubmit}
          onChangeType={(type: LoginType) => {
            setVerifyType(type);
          }}
        />
      ),
    },


    [AuthStep.verifyCode]: {
      title: `Verify ${verifyType === LoginType.email ? 'email address' : 'phone number'}`,
      form: (
        <VerifyCodeForm
          changePhoneNumber={() => setStep(AuthStep.checkInSystem)}
          phone={signinPhone}
          email={signInEmail}
          submit={(code) => {
            if (verifyType === LoginType.phone) {
              loginByPhone({
                phone: clearPhone(signinPhone),
                code: code,
                country_code: '61',

              });
            } else if (verifyType === LoginType.email) {
              loginByEmail({ email: signinPhone, code: code || '1111' });
              // loginByEmail({ email: signInEmail, code: code || '1111' });
            }
          }}
          verifyType={verifyType}
        />
      ),
    },
    [AuthStep.signUp]: {
      title: 'Sign up',
      form: (
        <SignupForm
          signType={verifyType}
          phone={signinPhone}
          email={signinPhone}
          loading={registerLoading}
          submit={({ code, phone, email, fio }) => {
            try {
              setSigninPhone(phone);
              setSigninEmail(email);
              registerUser({
                email,
                phone: clearPhone(phone),
                fio,
                country_code: '61',
                roles: [UserRoles.ROLE_BUYER],
                country_name: 'Autstralia',
              });
            } catch (e) {
              console.log('e', e)
            }

          }}
        />
      ),
    },
  };

  return (
    <Modal open={open} onClose={close}>
      <div className={classes.authModal}>
        {findInSystemLoading ||
          sendSmsLoading ||
          loginByPhoneLoading ||
          confirmationCodeLoading ||
          loginEmailLoading ? (
          <Preloader />
        ) : (
          <>
            {step !== AuthStep.verifyCode ? (
              <>
                <Text type={TextType.semiboldH6} margin={{ bottom: 16 }}>
                  Sign in or Sign up
                </Text>
                <div className={classes.tabWrapper}>
                  <div
                    className={step !== AuthStep.signUp ? classes.activeTab : ''}
                    onClick={() => setStep(AuthStep.checkInSystem)}
                    style={{ marginRight: 16 }}
                  >
                    <Text type={TextType.semiboldButton} margin={{ bottom: 16 }}>
                      Sign in
                    </Text>
                  </div>
                  <div
                    className={step === AuthStep.signUp ? classes.activeTab : ''}
                    onClick={() => setStep(AuthStep.signUp)}
                  >
                    <Text type={TextType.semiboldButton} margin={{ bottom: 16 }}>
                      Sign up
                    </Text>
                  </div>
                </div>
              </>
            ) : (
              <Text type={TextType.semiboldH6} margin={{ bottom: 16 }}>
                {config[step].title}
              </Text>
            )}

            <>{config[step].form}</>
          </>
        )}
      </div>
    </Modal>
  );
};

const connector = connect(
  (state: IAppState) => ({
    user: state.auth.user,
    authToken: state.auth.authToken,

    confirmationCodeSuccess: state.auth.sendCodeSuccess,
    confirmationCodeFail: state.auth.sendCodeError,
    confirmationCodeLoading: state.auth.sendCodeLoading,

    loginEmailLoading: state.auth.loginEmailLoading,
    loginEmailSuccess: state.auth.loginEmailSuccess,
    loginEmailErrors: state.auth.loginEmailErrors,

    loginByPhoneLoading: state.auth.loginByPhoneLoading,
    loginByPhoneSuccess: state.auth.loginByPhoneSuccess,
    loginByPhoneErrors: state.auth.loginByPhoneErrors,

    sendSmsLoading: state.auth.sendSmsLoading,
    sendSmsSuccess: state.auth.sendSmsSuccess,
    sendSmsErrors: state.auth.sendSmsErrors,
    countryId: state.auth.countryId,

    findInSystemLoading: state.auth.findInSystemLoading,
    findInSystemSuccess: state.auth.findInSystemSuccess,
    findInSystemError: state.auth.findInSystemError,

    registerSuccess: state.auth.registerUserSuccess,
    registerLoading: state.auth.registerUserLoading,
    registerFail: state.auth.registerUserFail,
  }),
  {
    registerUser: authActions.registerUser,
    clearLoginByEmail: authActions.clearLoginByEmail,
    clearLoginByPhone: authActions.clearLoginByPhone,
    loginByPhone: authActions.loginByPhoneRequest,
    loginByEmail: authActions.loginEmail,
    clearSendSms: authActions.clearSendSms,
    clearConfirmation: authActions.clearConfirmCode,
    sendSms: authActions.sendSmsRequest,
    sendConfirmationCode: authActions.sendEmailCode,
    getCountry: authActions.countryRequest,
    findInSystem: authActions.findInSystemRequest,
    clearFindInSystem: authActions.clearFindInSystem,
    clearSendCode: authActions.clearSendCode,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(BuyerAuth));
