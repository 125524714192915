import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      top: 30,
      right: 30,
      cursor: 'pointer',
    },
    wrapper: {
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 1974141516,
      transition: 'all 0.2s ease',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(500)]: {
        padding: '0px 16px',
      },
    },
    content: {
      flex: '0 1 auto',
      overflow: 'auto',
      padding: 4,
    },
    form: {
      borderRadius: 14,
      maxHeight: '80%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
    },
    titleBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 33,
      fontWeight: 600,
      marginBottom: 16,
      '& svg': {
        cursor: 'pointer',
      },
    },
    show: {
      opacity: 1,
    },
  })
);
export default useStyles;
