import axios from 'axios';
import {
  GET_COUNTRY_ID,
  getRecoveryPasswordUrl,
  LOGIN_URL,
  NEW_PASSWORD_URL,
  REGISTER_URL,
  sendSmsUrl,
} from '../constants';
import { LoginType, UserRoleType } from '../interfaces/user';
import { IUserForRegister } from '../pages/auth/interfaces';

export function login(login: string, password: string) {
  let formData = new FormData();
  formData.append('login', login);
  formData.append('password', password);
  return axios.post(LOGIN_URL, formData);
}
export function loginByEmail(email: string, confirmCode: string) {
  return axios.post(`/api/users/code_login?email=${email}&code=${confirmCode}`);
}
export const register = (data: IUserForRegister) => {
  return axios.post(REGISTER_URL, data);
};

export function recoveryPassword(email: string) {
  return axios.get(getRecoveryPasswordUrl(email));
}

export function newPassword(data: { password: string; password2: string; code: string }) {
  return axios.post(NEW_PASSWORD_URL, data);
}

export function sendSms(
  phone: string,
  country_name: string,
  country_code: string,
  roles?: [UserRoleType],
  countryId?: number | null,
  email?: string,
  fio?: string
) {
  return axios.post(sendSmsUrl(country_name, country_code, email ? email : ''), {
    roles: roles || undefined,
    countryId: countryId || 2,
    phoneNumbers: phone.replace(/[{()}]/g, ''),
    fio: Boolean(fio) ? fio : undefined,
  });
}

export function loginByPhone(
  phone: string,
  code: string,
  country_code: string,
  email?: string,
  fio?: string
) {
  return axios.post(
    `/api/users/code_login?country_code=${country_code}&phoneNumbers=${phone
      .replace(`+${country_code}`, '')
      .replace(/ /g, '')
      .replace(/[{()}]/g, '')
      .trim()}&code=${code}`,
    null,
    {
      params: {
        // email: Boolean(email) ? email : undefined,
        fio: Boolean(fio) ? fio : undefined,
      },
    }
  );
}

export function getCountryid() {
  return axios.get(GET_COUNTRY_ID);
}

export const findInSystem = ({ value, type }: { value: string; type: LoginType }) => {
  if (type === 'phone') {
    value = value
      .replace('+', '')
      .replace(/ /g, '')
      .replace(/[{()}]/g, '');
  }
  return axios.get(`api/_p/user/find_in_system?${type}=${value}`);
};
export const sendEmailConfirmCode = (email: string) => {
  return axios.post(`/api/users/code_request?email=${email}`);
};
export const sendCodeConfirm = (phone: string, roles?: string[]) => {
  return axios.post(`api/users/code_register_sms?country_name=Australia&country_code=61`, {
    countryId: 2,
    roles: roles || undefined,
    phoneNumbers: phone
      .replace('+', '')
      .replace(/ /g, '')
      .replace(/[{()}]/g, '')
      .slice(2),
  });
};

export const registerUser = (payload: {
  country_name: string;
  country_code: string;
  email: string;
  fio: string;
  role: string;
  countryId: number;
  phoneNumber: string;
}) => {

  return axios.post(
    '/api/users/code_register_sms',
    {
      roles: [payload.role],
      countryId: payload.countryId,
      phoneNumbers: payload.phoneNumber,
    },
    {
      params: {
        email: payload.email,
        fio: payload.fio,
        country_name: payload.country_name,
        country_code: payload.country_code,
      },
    }
  );
};
