import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  Card,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { a11yProps, TabPanel } from '../../../components/old/ui/Tab/TabPanel';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutSubheader } from '../../../utils/layout';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import Preloader from '../../../components/old/ui/Preloader/Preloader';
import { API_DOMAIN } from '../../../constants';
import RichEditor from '../../../components/old/RichEditor';
import { toAbsoluteUrl } from '../../../../_metronic';
import { SHeadTableCell } from '../../../components/old/styledComponents/Tables';
import { Col, Row } from 'react-bootstrap';
import { thousands } from '../../../utils/utils';
import ImageDialog from '../../../components/old/ui/ImageDialog/imageDialog';
import { colors } from '../../../constants/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    containerPaper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
      overflow: 'visible !important',
    },
    container: {
      paddingTop: 50,
      paddingBottom: 50,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 50,
        paddingRight: 50,
      },
    },
    containerTop: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    containerBot: {
      marginTop: 35,
    },
    imgContainer: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        height: 200,
      },
      [theme.breakpoints.only(500)]: {
        maxWidth: 350,
        height: 250,
      },
    },
    img: {
      maxWidth: 430,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
      },
      [theme.breakpoints.only(500)]: {
        maxWidth: 250,
      },
      cursor: 'pointer',
    },
    galleryContainer: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 280,
        maxHeigth: 280,
      },
      [theme.breakpoints.only(500)]: {
        maxWidth: 350,
        maxHeigth: 350,
      },
    },
    productInfo: {
      paddingLeft: 35,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        marginTop: 25,
      },
    },
    addToCartBtn: {},
    actions: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 10,
      },
    },
    richEditor: {
      [theme.breakpoints.up('lg')]: {
        width: 950,
      },
    },
    richEditor1: {},
  })
);

const CompanyView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  company,
  loading,
  isAuthorized,
  fetch,
  clearDelCompany,
}) => {
  const intl = useIntl();
  // const styles = useHomeStyles();
  const classes = useStyles();
  const [valueTabs, setValueTabs] = useState(0);
  const [isImageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const handleTabsChange = (event: any, newValue: number) => {
    setValueTabs(newValue);
  };
  setLayoutSubheader({
    title: intl.formatMessage({ id: 'COMPANY.TITLE.VIEW' }),
    back: true,
  });

  useEffect(() => {
    if (isAuthorized) {
      fetch({ id: Number(id) });
    } else {
      const localLocation = localStorage.getItem('location');
      const parseLocalLocation = localLocation && JSON.parse(localLocation);
      fetch({ id: Number(id), location: parseLocalLocation });
    }
    return () => {
      clearDelCompany();
    };
  }, [id]);

  const descriptionHtml = useMemo(() => {
    if (company?.description) {
      return EditorState.createWithContent(stateFromHTML(company.description));
    } else {
      return EditorState.createEmpty();
    }
  }, [company]);

  const filterPickupPoints = useMemo(() => {
    if (company && company.stores && company.stores.length > 0) {
      return company.stores.filter((store) => store.nearest && store.location?.pickup_point);
    }
    return null;
  }, [company, company?.stores]);

  const handleImage = useCallback(
    (url) => {
      if (isImageOpen) {
        setImageUrl(null);
        setImageOpen(false);
      } else {
        setImageUrl(url);
        setImageOpen(true);
      }
    },
    [isImageOpen, imageUrl]
  );

  if (loading || !company) return <Preloader />;

  return (
    <>
      {imageUrl && <ImageDialog open={isImageOpen} handleClose={handleImage} url={imageUrl} />}
      <Paper className={classes.containerPaper}>
        <div className={classes.container}>
          <Typography variant="h5" style={{ marginBottom: 25 }}>
            {`${company.name}`}
          </Typography>
          <div className={classes.containerTop}>
            <div className={classes.galleryContainer}>
              {company.logo ? (
                <Card
                  className={classes.imgContainer}
                  elevation={0}
                  style={{ height: 'auto', maxHeight: 400, alignItems: 'start' }}
                >
                  <img
                    onClick={() => handleImage(`${API_DOMAIN}/${company.logo}`)}
                    src={`${API_DOMAIN}/${company.logo}`}
                    className={classes.img}
                    alt="company"
                  />
                </Card>
              ) : (
                <Card className={classes.imgContainer}>
                  <img
                    onClick={() => handleImage(toAbsoluteUrl('/images/placeholder.png'))}
                    src={toAbsoluteUrl('/images/placeholder.png')}
                    className={classes.img}
                    alt="company"
                  />
                </Card>
              )}
            </div>
            <div className={classes.productInfo}>
              <Typography variant="h5">Brand:</Typography>
              <Typography variant="h6">{company.brand}</Typography>
            </div>
            <div className={classes.productInfo}>
              <Typography variant="h5">Distance, m:</Typography>
              <Typography variant="h6">
                {company.distantion !== null ? thousands(String(company.distantion)) : '-'}
              </Typography>
            </div>
          </div>
          <Tabs
            value={valueTabs}
            onChange={handleTabsChange}
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            aria-label="tabs"
          >
            {Boolean(company.description) && (
              <Tab
                label={intl.formatMessage({ id: 'PRODUCT.TABLE.DESCRIPTION' })}
                {...a11yProps(0)}
              />
            )}
            {filterPickupPoints && filterPickupPoints.length > 0 && (
              <Tab label={intl.formatMessage({ id: 'COMPANY.TABS.PICKUP' })} {...a11yProps(1)} />
            )}
          </Tabs>
          {Boolean(company.description) && (
            <TabPanel value={valueTabs} index={0}>
              <div className={classes.containerBot}>
                <RichEditor
                  disabled={true}
                  editorState={descriptionHtml}
                  placeholder={''}
                  setEditorState={() => {}}
                  className={classes.richEditor}
                  elevation={0}
                />
              </div>
            </TabPanel>
          )}
          {filterPickupPoints && filterPickupPoints.length > 0 && (
            <TabPanel value={valueTabs} index={Boolean(company.description) ? 1 : 0}>
              <Row>
                <Col>
                  <div className={classes.containerBot}>
                    <Table aria-label="stores" className={classes.richEditor}>
                      <TableHead>
                        <TableRow>
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'PRODUCT.TABLE.NAME' })}
                          </SHeadTableCell>
                          <SHeadTableCell>{intl.formatMessage({ id: 'ADDRESS' })}</SHeadTableCell>
                          {/* <SHeadTableCell>
                            {intl.formatMessage({ id: 'COMPANY.TABLE.PHONE' })}
                          </SHeadTableCell> */}
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'STORES.TABS.WORKING_TIME' })}
                          </SHeadTableCell>
                          {/* <SHeadTableCell>
                            {intl.formatMessage({ id: 'COMPANY.TABLE.PICKUP' })}
                          </SHeadTableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filterPickupPoints.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell style={{ color: colors.primary.green }}>
                              {item.name}
                            </TableCell>
                            <TableCell>{item.location?.address || '-'}</TableCell>
                            {/* <TableCell>{item.phone_number || '-'}</TableCell> */}
                            <TableCell>
                              {item.location &&
                              item.location.working_times &&
                              item.location.working_times.length > 0 ? (
                                <>
                                  {item.location.working_times?.map((item) => (
                                    <p>{`${item.day}: ${item.start_time}-${item.stop_time}`}</p>
                                  ))}
                                </>
                              ) : (
                                <span>-</span>
                              )}
                            </TableCell>
                            {/* <TableCell>
                              {item.location?.pickup_point
                                ? intl.formatMessage({ id: 'STORE.FORMCONTROL.YES' })
                                : intl.formatMessage({ id: 'STORE.FORMCONTROL.NO' })}
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </TabPanel>
          )}
        </div>
      </Paper>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    company: state.companies.company,
    loading: state.companies.byIdLoading,
    byIdSuccess: state.companies.byIdSuccess,
    isAuthorized: state.auth.user != null,
  }),
  {
    fetch: companiesActions.fetchByIdRequest,
    clearDelCompany: companiesActions.clearDelCompany,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanyView);
