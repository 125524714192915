import axios from 'axios';

export const getResponseMessage = (e: any) =>
  (e && e.response && e.response.data && e.response.data.message) || 'Error';

export const thousands = (inData: string) => {
  return inData.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${'\u00A0'}`);
};

export const makeAxiosGetRequest = (
  url: string,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>
) => {
  setLoading(true);
  axios
    .get(url)
    .then((res) => setData(res.data.data))
    .catch((err) => {
      if (err) setErr(err);
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};
export function range(start: number, end: number) {
  return Array(end - start + 1)
    .fill(0)
    .map((_, index) => start + index);
}
export const makeFetchGetRequest = (
  url: any,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>
) => {
  setLoading(true);
  axios
    .get(url, {
      transformRequest: (data, headers) => {
        delete headers.common['X-AUTH-TOKEN'];
      },
    })
    .then((res) => {
      setData(res.data.data);
    })
    .catch((err) => {
      if (err) setErr(err);
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};

export const makeAxiosPostRequest = (
  url: string,
  data: any | undefined,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>,
  options?: any
) => {
  if (!options) options = {};
  setLoading(true);
  axios
    .post(url, data, options)
    .then((res) => setData(res))
    .catch((err) => {
      if (err) setErr(err);
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};

export const makeAxiosDeleteRequest = (
  url: string,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>
) => {
  setLoading(true);
  axios
    .delete(url)
    .then((res) => setData(res))
    .catch((err) => {
      if (err) setErr(err);
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const maskPhone = (phone: string = '', country_code = '61') => {
  if (!phone) {
    return '';
  }
  phone = phone.replace(country_code, '');

  const val = phone.replace(/\D/g, '');

  return `+${country_code}-${val.slice(0, 3)}-${val.slice(3, 6)}-${val.slice(6)}`;
};

export const cleanPhone = (phone: string, country = '61') => {
  return phone
    .replace(country, '')
    .replace(/ /g, '')
    .replace(/[{()}]/g, '')
    .trim();
};

// TODO:
