import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import * as auth from './ducks/auth.duck';
import * as users from './ducks/users.duck';
import * as profile from './ducks/profile.duck';
import * as companies from './ducks/companies.duck';

import * as categories from './ducks/categories.duck';
import * as products from './ducks/product.duck';
import * as productTypes from './ducks/productType.duck';
import * as orders from './ducks/orders.duck';
import * as agreement from './ducks/agreement.duck';
import * as yaLocations from './ducks/yaLocations.duck';
import * as stores from './ducks/store.duck';
import * as managers from './ducks/managers.duck';
import * as cart from './ducks/cart.duck';
import * as collections from './ducks/collections.duck';

import { metronic } from '../../_metronic';

export type TAppActions =
  | auth.TActions
  | companies.TActions
  | users.TActions
  | profile.TActions
  | categories.TActions
  | products.TActions
  | productTypes.TActions
  | orders.TActions
  | agreement.TActions
  | yaLocations.TActions
  | stores.TActions
  | managers.TActions
  | cart.TActions
  | collections.TActions

export interface IAppState {
  i18n: typeof metronic.i18n.reducer;
  builder: typeof metronic.builder.initialState;
  auth: auth.IInitialState & PersistPartial;
  companies: companies.IInitialState & PersistPartial;
  users: users.IInitialState & PersistPartial;
  profile: profile.IInitialState & PersistPartial;
  categories: categories.IInitialState & PersistPartial;
  products: products.IInitialState & PersistPartial;
  productTypes: productTypes.IInitialState & PersistPartial;
  orders: orders.IInitialState & PersistPartial;
  agreement: agreement.IInitialState;
  yaLocations: yaLocations.IInitialState;
  stores: stores.IInitialState & PersistPartial;
  managers: managers.IInitialState & PersistPartial;
  cart: cart.IInitialState & PersistPartial;
  collections: collections.IInitialState & PersistPartial;
}

export const rootReducer: (state: IAppState, action: TAppActions) => IAppState = combineReducers<
  IAppState,
  TAppActions
>({
  auth: auth.reducer,
  companies: companies.reducer,
  users: users.reducer,
  profile: profile.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  categories: categories.reducer,
  products: products.reducer,
  productTypes: productTypes.reducer,
  orders: orders.reducer,
  agreement: agreement.reducer,
  yaLocations: yaLocations.reducer,
  stores: stores.reducer,
  managers: managers.reducer,
  cart: cart.reducer,
  collections: collections.reducer,
});

export function* rootSaga() {
  yield all(
    [
      auth.saga,
      companies.saga,
      users.saga,
      profile.saga,
      categories.saga,
      stores.saga,
      products.saga,
      productTypes.saga,
      orders.saga,
      agreement.saga,
      yaLocations.saga,
      managers.saga,
      cart.saga,
      collections.saga,
    ].map((saga) => fork(saga))
  );
}
