import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../constants/colors';
export const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      marginTop: 64,
      background: colors.primary.green,
      borderRadius: '40px 40px 0 0',
      padding: '64px 0',
      width: '100%',
      [theme.breakpoints.down(500)]: {
        padding: 16,
        borderRadius: `20px 20px 0 0`,
      },
    },
    section1: {
      maxWidth: 1440,
      width: '95%',
      margin: '0 auto',
      display: 'flex',
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column',
      },
    },
    subscribeForm: {
      maxWidth: 280,
      '& >span': {
        lineHeight: '23px',
        fontWeight: 600,
        color: colors.primary.white,
        '&:first-child': {
          fontSize: 23,
        },
      },
      '& >div': {
        marginTop: 24,
        '& >*': {
          marginBottom: 15,
        },
        '& .text': {
          fontWeight: 'bold',
        },
      },
      [theme.breakpoints.down(500)]: {
        maxWidth: 'none',
      },
    },
    linksTable: {
      padding: 0,
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',

      '& table': {
        marginRight: '25px',
        height: 'fit-content',
        color: colors.primary.white,
      },

      '& th': {
        fontSize: 23,
      },
      '& td': {
        whiteSpace: 'nowrap',
        paddingRight: 30,
        fontSize: 16,
        '& a': {
          color: colors.primary.white,
          fontWeight: 600,
          lineHeight: 2,
          '&:hover': {
            textDecoration: 'underline !important',
          },
        },
      },
      [theme.breakpoints.down(500)]: {
        padding: 0,
      },
    },
    appLinks: {
      textAlign: 'right',
      width: 'min-content',
      '& img': {
        cursor: 'pointer',
        marginBottom: 16,
        widht: 150,
        height: 46,
      },
    },
    section2: {
      maxWidth: 1440,
      width: '95%',
      margin: '0 auto',
      marginTop: 20,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      [theme.breakpoints.down(500)]: {
        '& >div span': {
          display: 'block',
        },
      },
      '& >div:first-child': {
        wordBreak: 'break-word',
      },
      '& span': {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        marginRight: 40,
        fontSize: 16,
        fontWeight: 600,
        color: colors.primary.white,
        '& :hover': {
          textDecoration: 'underline ',
        },
      },
      '& svg': {
        marginLeft: 10,
      },
    },
  })
);
