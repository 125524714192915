import React, { FC } from 'react';
import { Text, TextType } from '../../../../../../components/base/Text';
import { Button } from '../../../../../../components/base/Button';
import { useStyles } from './hooks/useStyles';
import Preloader from '../../../../../../components/old/ui/Preloader/Preloader';

export interface ICartProps {
  price: number;
  buyClick: () => void;
  count: number;
  plus: () => void;
  minus: () => void;
  showButton: boolean;
  loading: boolean;
}

export const Cart: FC<ICartProps> = ({
  price,
  buyClick,
  count,
  plus,
  minus,
  showButton,
  loading,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.cart}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Text type={TextType.boldH4}>A$ {price}</Text>

          {!count ? (
            showButton && (
              <Button
                full
                style={{ marginTop: 16 }}
                onClick={buyClick}
                text={'Add to cart'}
                leftIcon={'Buy'}
              />
            )
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 16,
              }}
            >
              <span onClick={minus}>
                <Text type={TextType.semiboldH5}>-</Text>
              </span>
              <Text type={TextType.regularH4}>{count}</Text>
              <span onClick={plus}>
                <Text type={TextType.semiboldH5}>+</Text>
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
