import axios from 'axios';
import {
  deleteProductParamUrl, getProductParamByIdUrl,
  getProductParamsUrl,
  postProductParamUrl,
  putProductParamUrl
} from '../constants';
import { ITypeParameter } from '../interfaces/productType';

export const getProductParams = () => {
  return axios.get(getProductParamsUrl());
};
export const getProductParamById = (id: number) => {
  return axios.get(getProductParamByIdUrl(id));
};
export const putProductParam = (id: string, data: ITypeParameter) => {
  return axios.put(putProductParamUrl(id), data);
};
export const postProductParam = (data: ITypeParameter) => {
  return axios.post(postProductParamUrl(), data);
};
export const deleteProductParam = (id: number) => {
  return axios.delete(deleteProductParamUrl(id));
};
