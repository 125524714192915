import { colors } from '../../../../../constants/colors';

const menuItems = {
    ROLE_RECIPIENT: {},
    ROLE_VENDOR: {
        topSection: [{
                title: null,
                items: [
                    // {
                    //     text: 'Main Page',
                    //     icon: 'Home',
                    // },
                    {
                        text: 'Orders',
                        icon: 'Buy',
                        page: 'orders/list/full',
                    },
                    {
                        text: 'Products',
                        icon: 'Category',
                        page: 'products/catalog/all/{companyId}',
                        paramName: 'companyId'
                    },
                    {
                        text: 'Category',
                        icon: 'Category',
                        page: 'categories/list',
                    },
                    {
                        text: 'Reviews',
                        icon: 'Star',
                        page: "reviews/{companyId}",
                        paramName: 'companyId'
                    },
                    // {
                    //     text: 'My messages',
                    //     icon: 'Chat',
                    //     page: 'messages',
                    // },
                    // {
                    //     text: 'Finance',
                    //     icon: 'Wallet',
                    // },
                    // {
                    //     text: 'Store',
                    //     icon: 'Location',
                    //     page: 'stores/list'
                    // }
                ],
            },
            {
                title: 'My company',

                items: [{
                        text: 'Company settings',
                        icon: 'Setting',
                        page: 'companies/list'
                    }, {
                        text: 'Managers',
                        icon: 'TwoUsers',
                        page: 'companies/managers/list'
                    },
                    {
                        text: 'Store',
                        icon: 'Location',
                        page: 'stores/list'
                    }
                ]
            }
        ],
        bottomSection: [
            // {
            //     text: 'Support',
            //     icon: 'Paper',
            // },
            {
                text: 'Settings',
                icon: 'Setting',
                page: 'settings/profile',
            },
            // {
            //     text: 'Product params',
            //     icon: 'Setting',
            //     page: 'products/params/list',
            // },
            {
                text: 'Logout',
                icon: 'Logout',
                page: 'logout',
            },
        ],
    },
    ROLE_ADMIN: {
        topSection: [{
                title: null,
                items: [
                    // {
                    //     text: 'Main Page',
                    //     icon: 'Home',
                    // },
                    {
                        text: 'Orders',
                        icon: 'Buy',
                        page: 'orders/list/full',
                    }, {
                        text: 'Abandoned cart',
                        icon: 'Buy',
                        page: 'orders/list/abandoned',
                    },
                    {
                        text: 'Products',
                        icon: 'Category',
                        page: 'products/catalog/all',
                    },
                    {
                        text: 'Reviews',
                        icon: 'Star',
                        page: 'reviews',

                    },
                    {
                        text: 'Category',
                        icon: 'Category',
                        page: 'categories/list',
                    },
                    // {
                    //     text: 'Reviews',
                    //     icon: 'Star',
                    // },
                    // {
                    //     text: 'My messages',
                    //     icon: 'Chat',
                    // },
                    // {
                    //     text: 'Finance',
                    //     icon: 'Wallet',
                    // },
                    // {
                    //     text: 'Store',
                    //     icon: 'Location',
                    //     page: 'stores/list'
                    // }
                ],
            },
            {
                title: 'Your staff',

                items: [{
                        text: 'Users',
                        icon: 'TwoUsers',
                        page: 'users/buyers',
                    }, {
                        text: 'Gift recipients',
                        icon: "TicketStar",
                        page: 'users/recipients'
                    },
                    {
                        text: 'Companies',
                        icon: 'Home',
                        page: 'companies/list',
                    },
                    // {
                    //     text: 'Courier',
                    //     icon: 'People',
                    // }
                ],
            },
        ],
        bottomSection: [
            // {
            //     text: 'Support',
            //     icon: 'Paper',
            // },
            {
                text: 'Profile',
                icon: 'User',
                page: 'settings/profile',
            },
            {
                text: 'Legacy',
                icon: 'Document',
                page: 'documents/legacy'
            },
            {
                text: 'Privacy',
                icon: 'Document',
                page: 'documents/privacy'
            },
            {
                text: 'Product params',
                icon: 'Setting',
                page: 'products/params/list',
            },

            {
                text: 'Collections',
                icon: 'Setting',
                page: 'collections/list',
            },
            {
                text: 'Logout',
                icon: 'Logout',
                page: 'logout',
            },
        ],
    },
    ROLE_BUYER: {
        topSection: [{
            title: null,
            items: [{
                    text: 'Main page',
                    icon: 'Home',
                    page: 'main',
                },
                {
                    text: 'Products',
                    icon: 'Category',
                    page: 'products/catalog/all',
                },
                {
                    text: 'Orders',
                    icon: 'Bag2',
                    page: 'orders/list/full',
                },
                {
                    text: 'Cart',
                    icon: 'Buy',
                    page: 'cart',
                },
            ],
        }, ],
        bottomSection: [{
                text: 'Profile',
                icon: 'User',
                page: 'settings/profile',
            },
            {
                text: 'Logout',
                icon: 'Logout',
                page: 'logout',
            },
        ],
    },
    ROLE_GUEST: {
        topSection: [{
                title: null,
                items: [{
                    text: 'Main page',
                    icon: 'Home',
                    page: 'main',
                }, ],
            },
            {
                title: 'For clients',
                items: [{
                    text: 'Login / Register',
                    icon: 'User',
                }, ],
            },
            {
                title: 'For Partners',
                items: [{
                        text: 'Partner Login / Register',
                        icon: 'Work',
                        page: 'auth',
                    },
                    // {
                    //     text: 'Upload your goods',
                    //     icon: 'Scan',
                    // },
                ],
            },
        ],
    },
    ROLE_USER: {
        topSection: [{
                title: null,
                items: [{
                        text: 'Main page',
                        icon: 'Home',
                        page: 'main',
                    },
                    // {
                    //     text: 'Support',
                    //     icon: 'Chat',

                    // },
                    // {
                    //     text: 'Blog',
                    //     icon: 'Paper',

                    // },
                    // {
                    //     text: 'About company',
                    //     icon: 'Discovery',

                    // },
                    // {
                    //     text: 'Vacancies',
                    //     icon: 'AddUser',

                    // },
                ],
            },
            {
                title: 'Account',

                items: [{
                        text: 'Settings',
                        icon: 'Setting',
                        page: 'personal',
                    },
                    // {
                    //     text: 'Saved',
                    //     icon: 'Heart2',
                    // },
                    {
                        text: 'Orders',
                        icon: 'Bag2',
                        page: 'personal/orders/list/full',
                    },
                    // {
                    //     text: 'My events',
                    //     icon: 'Ticket',
                    // },
                    // {
                    //     text: 'Settings',
                    //     icon: 'Setting',
                    // },
                ],
            },
            // {
            //     title: 'For clients',

            //     items: [
            //         {
            //             text: 'Login / Register',
            //             icon: 'User',
            //         },
            //     ],
            // },
            {
                title: 'For Partners',

                items: [{
                        text: 'Partner Login',
                        icon: 'Work',
                        page: 'auth',
                    },
                    // {
                    //     text: 'Upload your goods',
                    //     icon: 'Scan',
                    // },
                ],
            },
        ],
        bottomSection: [{
            text: 'Logout',
            icon: 'Logout',
            page: 'logout',
            color: colors.primary.green,
        }, ],
    },
    ROLE_MANAGER: {},
};

export { menuItems };