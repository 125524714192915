import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { menuItems } from './MenuItems';
import { useStyles } from './hooks/useStyles';
import { Iconly } from 'react-iconly';
import { colors } from '../../../../../constants/colors';
import { SidebarPosition } from '../../../../../constants/index';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { IAppState } from '../../../../../store/rootDuck';
import { actions as profileActions } from '../../../../../store/ducks/profile.duck';
import { UserRoles } from '../../../../../interfaces/user';
import { useOutsideClicks } from '../../../../../hooks/useOutsideClicks';
import { Scrollbars } from 'react-custom-scrollbars';


interface IProps {
  onSwitch?: any;
  state?: boolean | false;
  position?: SidebarPosition;
  authorized?: boolean;
  isShown?: boolean;
  setIsShown?: (value: boolean) => void;

}

const Sidebar: React.FC<IProps & TPropsFromRedux> = ({
  me,
  fetchUser,
  userRole,
  onSwitch,
  state,
  isShown,
  setIsShown,
  position = SidebarPosition.RIGHT,
  // authorized,
}) => {
  const props = { companyId: me?.company?.id };
  const classes = useStyles();
  const [activeItem, setActiveItem] = useState<string>('');
  const [currentPath, setCurrentPath] = useState<string>('');
  const location = useLocation();
  const LEFT = position === SidebarPosition.LEFT;
  const positionStyles = makeStyles((theme) => ({
    leftStyle: {
      left: 0,
      height: '100vh',
    },
    rightStyle: {
      right: 0,
      position: 'fixed',
    },
    sideBar: {
      transform: `translateX(${LEFT ? '0%' : state ? '0%' : '100%'})`,
      position: LEFT ? 'static' : 'fixed',
      maxWidth: LEFT ? 265 : 350,
      padding: LEFT ? 15 : 32,
      boxShadow: state ? '-11px 22px 44px -12px rgba(124, 177, 66, 0.25)' : 'none',
    },
    logos: {
      textAlign: 'center',
      [theme.breakpoints.down(960)]: {
        display:"none",
      },
    },
    iconTablet :{
      display:"none",
      [theme.breakpoints.down(960)]: {
        display:"inline",
      },
    },
  }));
  const isActive = (title: string, page: string) => activeItem === title || currentPath === page;
  const menuRef = useRef<any>(null);

  useOutsideClicks(menuRef, () => {
    onSwitch(null);
  });

  const menu = useMemo(() => {
    let ret: any = {};
    if (userRole === undefined) {
      ret = menuItems.ROLE_GUEST;
    } else if (userRole[0] === UserRoles.ROLE_BUYER) {
      if (position === SidebarPosition.RIGHT) {
        ret = menuItems.ROLE_USER;
      } else {
        ret = menuItems.ROLE_BUYER;
      }
    } else {
      ret = menuItems[userRole[0]];
    }
    return ret;
  }, [menuItems, userRole, position]);

  const changeState = useCallback((menuItem: any) => {
    setActiveItem(menuItem.text);
    onSwitch(menuItem.text);
  }, []);

  useEffect(() => {
    setCurrentPath(() => location.pathname.substring(1));
  }, [location]);

  useEffect(() => {
    if (LEFT) fetchUser();
  }, []);

  return (
    <div
      ref={menuRef}
      className={`${classes.sidebar} ${
        LEFT ? positionStyles().leftStyle : positionStyles().rightStyle
      } ${positionStyles().sideBar}`}

    >
      {LEFT ? (
        <div>
          <div className={`${classes['close-btn']} ${positionStyles().iconTablet}`} onClick={() => setIsShown && setIsShown(!isShown)}>
            <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_119_73)">
                <path
                    d="M9.2608 7.49787L14.6306 2.14055C14.8658 1.90539 14.9979 1.58646 14.9979 1.2539C14.9979 0.921347 14.8658 0.602412 14.6306 0.36726C14.3955 0.132107 14.0765 0 13.744 0C13.4114 0 13.0925 0.132107 12.8573 0.36726L7.5 5.73707L2.14268 0.36726C1.90752 0.132107 1.58859 -2.47773e-09 1.25603 0C0.923478 2.47773e-09 0.604543 0.132107 0.369391 0.36726C0.134239 0.602412 0.00213119 0.921347 0.00213119 1.2539C0.00213119 1.58646 0.134239 1.90539 0.369391 2.14055L5.7392 7.49787L0.369391 12.8552C0.252343 12.9713 0.159441 13.1094 0.0960411 13.2616C0.0326416 13.4138 0 13.577 0 13.7418C0 13.9067 0.0326416 14.0699 0.0960411 14.2221C0.159441 14.3743 0.252343 14.5124 0.369391 14.6285C0.485482 14.7455 0.6236 14.8384 0.775777 14.9018C0.927954 14.9652 1.09118 14.9979 1.25603 14.9979C1.42089 14.9979 1.58411 14.9652 1.73629 14.9018C1.88847 14.8384 2.02659 14.7455 2.14268 14.6285L7.5 9.25867L12.8573 14.6285C12.9734 14.7455 13.1115 14.8384 13.2637 14.9018C13.4159 14.9652 13.5791 14.9979 13.744 14.9979C13.9088 14.9979 14.072 14.9652 14.2242 14.9018C14.3764 14.8384 14.5145 14.7455 14.6306 14.6285C14.7477 14.5124 14.8406 14.3743 14.904 14.2221C14.9674 14.0699 15 13.9067 15 13.7418C15 13.577 14.9674 13.4138 14.904 13.2616C14.8406 13.1094 14.7477 12.9713 14.6306 12.8552L9.2608 7.49787Z"
                    fill="#1A1A1A"
                />
              </g>
              <defs>
                <clipPath id="clip0_119_73">
                  <rect width="15" height="15" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={positionStyles().logos} >
            <img src={toAbsoluteUrl('/images/logoVector.svg')} width="187" alt="logo" />
          </div>
          <div className={classes.accountSection}>
            <img src={toAbsoluteUrl('/images/placeholder.png')} alt="Avatar" width="64" />
            <div>
              <h5>{me?.fio}</h5>
              <h6 style={{ textTransform: 'capitalize' }}>
                {me ? me?.roles[0]?.split('_')[1] : ''}
              </h6>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes['close-btn']} onClick={() => onSwitch(null)}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_119_73)">
              <path
                d="M9.2608 7.49787L14.6306 2.14055C14.8658 1.90539 14.9979 1.58646 14.9979 1.2539C14.9979 0.921347 14.8658 0.602412 14.6306 0.36726C14.3955 0.132107 14.0765 0 13.744 0C13.4114 0 13.0925 0.132107 12.8573 0.36726L7.5 5.73707L2.14268 0.36726C1.90752 0.132107 1.58859 -2.47773e-09 1.25603 0C0.923478 2.47773e-09 0.604543 0.132107 0.369391 0.36726C0.134239 0.602412 0.00213119 0.921347 0.00213119 1.2539C0.00213119 1.58646 0.134239 1.90539 0.369391 2.14055L5.7392 7.49787L0.369391 12.8552C0.252343 12.9713 0.159441 13.1094 0.0960411 13.2616C0.0326416 13.4138 0 13.577 0 13.7418C0 13.9067 0.0326416 14.0699 0.0960411 14.2221C0.159441 14.3743 0.252343 14.5124 0.369391 14.6285C0.485482 14.7455 0.6236 14.8384 0.775777 14.9018C0.927954 14.9652 1.09118 14.9979 1.25603 14.9979C1.42089 14.9979 1.58411 14.9652 1.73629 14.9018C1.88847 14.8384 2.02659 14.7455 2.14268 14.6285L7.5 9.25867L12.8573 14.6285C12.9734 14.7455 13.1115 14.8384 13.2637 14.9018C13.4159 14.9652 13.5791 14.9979 13.744 14.9979C13.9088 14.9979 14.072 14.9652 14.2242 14.9018C14.3764 14.8384 14.5145 14.7455 14.6306 14.6285C14.7477 14.5124 14.8406 14.3743 14.904 14.2221C14.9674 14.0699 15 13.9067 15 13.7418C15 13.577 14.9674 13.4138 14.904 13.2616C14.8406 13.1094 14.7477 12.9713 14.6306 12.8552L9.2608 7.49787Z"
                fill="#1A1A1A"
              />
            </g>
            <defs>
              <clipPath id="clip0_119_73">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}

      <Scrollbars className={classes.itemsWrapper} autoHide autoHideTimeout={2500}>
        <div>
          {menu?.topSection?.map((section: any, index: number) => {
            return (
              <div style={{ margin: '0 0 20px 0' }} key={index}>
                <div className={classes.itemsTitle}>{section?.title}</div>
                <div>
                  {section.items?.map((item: any, index: number) => {
                    const page = item.paramName
                      ? item?.page.replace(/\{.*\}/, props.companyId || '')
                      : item?.page;

                    return (
                      <Link
                        to={`/${page}`}
                        onClick={() => changeState(item)}
                        className={`${classes.menuItems} ${
                          LEFT && isActive(item.text, item.page) ? classes.active : ''
                        }`}
                        style={LEFT ? { paddingLeft: 15 } : {}}
                        key={index}
                      >
                        <Iconly
                          set="bold"
                          primaryColor={
                            LEFT && isActive(item.text, item.page)
                              ? colors.primary.white
                              : colors.primary.black
                          }
                          name={item.icon}
                        />
                        <span style={{ marginLeft: 12 }}> {item.text}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div>
          {menu?.bottomSection?.map((item: any, index: number) => {
            return (
              <Link
                to={`/${item?.page}`}
                onClick={() => changeState(item)}
                className={`${classes.menuItems} ${
                  LEFT && isActive(item.text, item.page) ? classes.active : ''
                }`}
                style={LEFT ? { paddingLeft: 15 } : {}}
                key={index}
              >
                <Iconly
                  set="bold"
                  primaryColor={
                    isActive(item.text, item.page)
                      ? colors.primary.white
                      : item?.color || colors.primary.black
                  }
                  name={item.icon}
                />
                <span style={{ marginLeft: 12, color: item?.color }}> {item.text}</span>
              </Link>
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRole: state.auth.user?.roles,
    me: state.profile.me,
  }),
  {
    fetchUser: profileActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Sidebar);
