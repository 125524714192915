import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { countries, phoneCountryCodes } from './phoneCountryCodes';
import { LoginType, UserRoles, UserRoleType } from '../../../interfaces/user';
import { SmsInput } from '../../../components/base/SmsInput';
import { Input } from '../../../components/base/Input';
import { Button } from '../../../components/base/Button';
import { Checkbox } from '../../../components/base/Checkbox';
import { colors } from '../../../constants/colors';

interface IProps {
  sendSms: ({
    phone,
    country_name,
    country_code,
    roles,
    email,
    fio,
  }: {
    phone: string;
    country_name: string;
    country_code: string;
    roles?: [UserRoleType];
    email?: string;
    fio?: string;
  }) => void;
  sendSmsLoading: boolean;
  sendSmsSuccess: boolean;

  login: ({
    phone,
    code,
    country_code,
    email,
    fio,
  }: {
    phone: string;
    code: string;
    country_code: string;
    email?: string;
    fio?: string;
  }) => void;
  loginLoading: boolean;
  backButton?: boolean;
  roles?: [UserRoleType];
  setOpenUserAgreement?: () => void;
  visibleAgreement?: boolean;
  backButtonFunction?: () => void;
  changeTab?: (id: number) => void;
  onlyBuyer?: boolean;
  newUser?: boolean;
  hideBack?: boolean;
  authData?: {
    value: string;
    type: LoginType;
    country_name?: string;
    country_code?: string;
  } | null;
}

enum LoginPhase {
  sendCode = 0,
  verifyCode = 1,
}

// const initialValues = {
//   phone: '',
//   code: '',
//   acceptTerms: false,
//   email: localStorage.getItem('partnerEmail') || '',
//   fio: '',
// };

const LoginByPhoneForm: React.FC<IProps> = ({
  sendSms,
  sendSmsLoading,
  login,
  loginLoading,
  sendSmsSuccess,
  backButton,
  roles,
  setOpenUserAgreement,
  visibleAgreement,
  backButtonFunction,
  changeTab,
  onlyBuyer,
  newUser,
  hideBack,
  authData,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [loginPhase, setLoginPhase] = useState<LoginPhase>(LoginPhase.sendCode);
  const [validationSchema, setValidationScheme] = useState<any>({
    phone:
      authData && authData.type === 'phone'
        ? undefined
        : Yup.string().required(intl.formatMessage({ id: 'AUTH.INPUT.PHONE_REQUIRED' })),
  });
  const [countryCode] = useState(countries[0].code);
  const [countryName] = useState(phoneCountryCodes[0]);

  const initialValues = {
    phone: '',
    code: '',
    acceptTerms: false,
    email: localStorage.getItem('partnerEmail') || '',
    fio: '',
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: (values) => {
      const phone =
        authData && authData.type === 'phone'
          ? authData.value.replace(`+${countryCode}`, '').replace(/ /g, '').trim()
          : values.phone.replace(`+${countryCode}`, '').replace(/ /g, '').trim();
      if (!!loginPhase) {
        login({
          phone,
          code: values.code,
          country_code: countryCode,
          email: values.email,
          fio: values.fio,
        });
      } else {
        sendSms({
          phone,
          country_name: countryName,
          country_code: countryCode,
          roles: onlyBuyer ? [UserRoles.ROLE_BUYER] : roles,
          email: values.email,
          fio: values.fio,
        });
      }
    },
  });

  useEffect(() => {
    if (sendSmsSuccess && !newUser) {
      setLoginPhase(LoginPhase.verifyCode);
      setValidationScheme({
        code: Yup.string().required(intl.formatMessage({ id: 'AUTH.INPUT.CODE_REQUIRED' })),
      });
    } else if (newUser) {
      setLoginPhase(LoginPhase.verifyCode);
    }
  }, [sendSmsSuccess, newUser]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('partnerEmail');
    };
  }, []);

  return (
    <>
      <form
        noValidate={true}
        autoComplete="off"
        className="kt-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        style={{ marginTop: 0 }}
      >
        <div className="form-group">
          {!!loginPhase ? (
            <>
              <SmsInput
                style={{ marginBottom: 16 }}
                name={'code'}
                value={values.code}
                onChange={handleChange}
              />

              {newUser && (
                <>
                  <Input
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'USER.EDIT.FIO',
                    })}
                    name="fio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fio}
                    error={touched.fio && errors.fio ? errors.fio.toString() : ''}
                  />

                  <Input
                    type="email"
                    placeholder={intl.formatMessage({
                      id: 'USER.EDIT.EMAIL',
                    })}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    error={touched.email && errors.email ? errors.email.toString() : ''}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {!Boolean(authData && authData.type === 'phone') && (
                <Input
                  phone
                  name={'phone'}
                  type={'phone'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  error={touched.phone && errors.phone ? errors.phone : ''}
                  placeholder={'+61'}
                />
              )}

              {backButton && (
                <>
                  <Input
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'USER.EDIT.FIO',
                    })}
                    name="fio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fio}
                    error={touched.fio && errors.fio ? errors.fio.toString() : ''}
                  />
                  <Input
                    type="email"
                    placeholder={intl.formatMessage({
                      id: 'USER.EDIT.EMAIL',
                    })}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    error={touched.email && errors.email ? errors.email.toString() : ''}
                  />
                </>
              )}
            </>
          )}
        </div>
        {visibleAgreement && setOpenUserAgreement && (
          <Checkbox
            Label={
              <>
                {intl.formatMessage({ id: 'AUTH.REGISTER.AGREE_TERM' })}{' '}
                <span
                  style={{
                    cursor: 'pointer',
                    color: colors.primary.green,
                    textDecoration: 'underline',
                  }}
                  onClick={() => setOpenUserAgreement()}
                >
                  {intl.formatMessage({ id: 'AUTH.GENERAL.LEGAL' })}
                </span>
              </>
            }
            onPress={() => setFieldValue('acceptTerms', !values.acceptTerms)}
            checked={values.acceptTerms}
            style={{ marginLeft: 8 }}
          />
        )}

        <div
          className="kt-login__actions"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingTop: 24,
          }}
        >
          {!hideBack && (
            <>
              <Button
                type={'button'}
                text={'Back'}
                onClick={() => history.goBack()}
                light
                leftIcon={'ChevronLeft'}
                full
              />
              &nbsp; &nbsp;
            </>
          )}
          <Button
            full
            type={'button'}
            onClick={() => {
              handleSubmit();
            }}
            text={'Next'}
            disabled={
              visibleAgreement && !values.acceptTerms
                ? true
                : loginPhase
                ? loginLoading
                : sendSmsLoading
            }
            loading={loginPhase ? loginLoading : sendSmsLoading}
          />
        </div>
      </form>
    </>
  );
};

export default LoginByPhoneForm;
