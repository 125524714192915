import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    sidebar: {
      zIndex: 197414,
      width: 350,
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      background: 'white',
      transformOrigin: 'top right',
      transition: 'transform  0.2s ease-out',
      padding: 32,
      right: 0,
      height: '100%',
      boxShadow: '-11px 22px 44px -12px rgba(124, 177, 66, 0.25);',
      '& a': {
        '& :active,:visit,:focus': {
          color: 'unset !default',
        },
      },
    },
    productsWrapper: {
      flex: '1',
      overflowY: 'auto',
    },
    cartWrapper: {
      marginTop: 15,
      display: 'flex',
    },
    cartItemImage: {
      width: 60,
      height: 60,
      objectFit: 'cover',
      borderRadius: 12,
    },
    cartInfo: {
      fontWeight: 400,
      flex: 1,
      height: 62,
      marginLeft: 8,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    changeProductCount: {
      width: 20,
      height: 20,
      borderRadius: 4,
      background: '#ded6d6',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 15,
      fontWeight: 400,
      userSelect: 'none',
    },
    emptyCartWrapper: {
      marginTop: 72,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& div': {
        marginTop: 40,
        fontSize: 16,
        fontWeight: 600,
      },
    },
  })
);
