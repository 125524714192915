import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import AddressSelect from './AddressSelect';

export const useStyles = makeStyles((theme) =>
  createStyles({
    deliveryInfoContainer: {
      display: 'flex',
      alignItems: 'center',
      ['@media (max-width:835px)']: {
        marginTop: '20px',
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      color: '#7CB142',
      marginRight: '15px',
      cursor: 'pointer',
      fontWeight: '600',
      minWidth: '120px',
      lineHeight: '16px',
    },
    mr15: {
      marginRight: '15px',
    },
  })
);

const DeliveryInfo: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.deliveryInfoContainer} >
      <div className={classes.addressSelect}>
        <AddressSelect classes={classes} />
      </div>
    </div>
  );
};

export default DeliveryInfo;
