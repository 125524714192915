import React from 'react';
import Popover from '@material-ui/core/Popover';
import { AddressEdit } from './AddressEdit';

export default function AddressSelect({ classes }) {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selected, setSelected] = React.useState(JSON.parse(localStorage.getItem('location')));

  React.useEffect(() => {
    // setTimeout(() => setSelected(JSON.parse(localStorage.getItem('location'))), 500)
  }, [])


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelected(JSON.parse(localStorage.getItem('location')));
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.popoverWrap}  >
      <div className={classes.row} aria-describedby={id} onClick={handleClick}>
        <span className={classes.mr15}>
          {selected?.address ? selected?.address : 'Enter new delivery address'}
        </span>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.76708 17.8624L15.9196 6.0265C16.417 5.38823 16.5938 4.65031 16.428 3.89895C16.2843 3.21589 15.8643 2.56643 15.2342 2.07373L13.6978 0.853191C12.3603 -0.210584 10.7022 -0.0986076 9.75159 1.12193L8.72359 2.45557C8.59095 2.62242 8.62411 2.86877 8.78991 3.00314C8.78991 3.00314 11.3875 5.0859 11.4428 5.13069C11.6197 5.29865 11.7523 5.5226 11.7855 5.79135C11.8407 6.31764 11.476 6.81033 10.9343 6.87752C10.6801 6.91111 10.4369 6.83273 10.2601 6.68716L7.52979 4.51482C7.39714 4.41516 7.19818 4.43643 7.08764 4.57081L0.599102 12.969C0.179061 13.4953 0.0353625 14.1784 0.179061 14.839L1.00809 18.4335C1.05231 18.6238 1.21811 18.7582 1.41708 18.7582L5.06481 18.7134C5.72803 18.7022 6.34704 18.3999 6.76708 17.8624ZM11.8747 16.743H17.8227C18.403 16.743 18.875 17.2211 18.875 17.809C18.875 18.398 18.403 18.875 17.8227 18.875H11.8747C11.2944 18.875 10.8224 18.398 10.8224 17.809C10.8224 17.2211 11.2944 16.743 11.8747 16.743Z"
            fill="#1A1A1A"
          />
        </svg>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{
          top: '20px',
        }}
      >

        <AddressEdit handleClose={handleClose} setSelected={setSelected} selected={selected} />
      </Popover>
    </div>
  );
}

// AddressSelect
