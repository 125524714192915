import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  createCollection,
  deleteCollection,
  editCollection,
  getCollection,
  getCollections,
  geteCollectionsByPlace,
} from '../../crud/collections.crud';
import { ICollection } from '../../interfaces/collections';

const CLEAR_FETCH = 'collections/CLEAR_FETCH';
const FETCH_REQUEST = 'collections/FETCH_REQUEST';
const FETCH_SUCCESS = 'collections/FETCH_SUCCESS';
const FETCH_FAIL = 'collections/FETCH_FAIL';
const CLEAR_COLLECTIONS = 'collections/CLEAR_COLLECTIONS';

const CLEAR_FETCH_BY_ID = 'collections/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'collections/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'collections/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'collections/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'collections/CLEAR_EDIT';
const ADD_REQUEST = 'collections/ADD_REQUEST';
const EDIT_REQUEST = 'collections/EDIT_REQUEST';
const EDIT_SUCCESS = 'collections/EDIT_SUCCESS';
const EDIT_FAIL = 'collections/EDIT_FAIL';

const CLEAR_DEL_COLLECTION = 'collections/CLEAR_DEL_COLLECTION';
const DEL_COLLECTION_REQUEST = 'collections/DEL_COLLECTION_REQUEST';
const DEL_COLLECTION_SUCCESS = 'collections/DEL_COLLECTION_SUCCESS';
const DEL_COLLECTION_FAIL = 'collections/DEL_COLLECTION_FAIL';

const FETCH_POPULAR_REQUEST = 'collections/FETCH_POPULAR_REQUEST';
const FETCH_POPULAR_SUCCESS = 'collections/FETCH_POPULAR_SUCCESS';
const FETCH_POPULAR_FAIL = 'collections/FETCH_POPULAR_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  collections: ICollection[];
  loading: boolean;
  success: boolean;
  error: string | null;

  collection: ICollection | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delCollectionLoading: boolean;
  delCollectionSuccess: boolean;
  delCollectionError: string | null;

  popularCollections: ICollection[];
  popularLoading: boolean;
  popularSuccess: boolean;
  popularError: string | null;
}

const defaultPaginatorProps = { page: 1, per_page: 20, total: 0 };

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  collections: [],
  loading: false,
  success: false,
  error: null,

  collection: null,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delCollectionLoading: false,
  delCollectionSuccess: false,
  delCollectionError: null,

  popularCollections: [],
  popularLoading: false,
  popularSuccess: false,
  popularError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'collections', whitelist: ['collections', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false };
      }

      case CLEAR_COLLECTIONS: {
        return { ...state, ...defaultPaginatorProps, collections: [] };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          ...defaultPaginatorProps,
          collections: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          collections: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return {
          ...state,
          collection: null,
          byIdLoading: false,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          collection: null,
          byIdLoading: true,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return { ...state, collection: action.payload.data, byIdLoading: false, byIdSuccess: true };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          collection: null,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, collection: action.payload };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_DEL_COLLECTION: {
        return {
          ...state,
          delCollectionLoading: false,
          delCollectionError: null,
          delCollectionSuccess: false,
        };
      }

      case DEL_COLLECTION_REQUEST: {
        return {
          ...state,
          delCollectionLoading: true,
          delCollectionError: null,
          delCollectionSuccess: false,
        };
      }

      case DEL_COLLECTION_SUCCESS: {
        return { ...state, delCollectionLoading: false, delCollectionSuccess: true };
      }

      case DEL_COLLECTION_FAIL: {
        return { ...state, delCollectionLoading: false, delCollectionError: action.payload };
      }

      case FETCH_POPULAR_REQUEST: {
        return {
          ...state,
          popularCollections: [],
          popularLoading: true,
          popularSuccess: false,
          popularError: null,
        };
      }

      case FETCH_POPULAR_SUCCESS: {
        return {
          ...state,
          popularCollections: action.payload.data,
          popularLoading: false,
          popularSuccess: true,
        };
      }

      case FETCH_POPULAR_FAIL: {
        return { ...state, popularLoading: false, popularError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: { page: number; perPage: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<ICollection[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  clearCollections: () => createAction(CLEAR_COLLECTIONS),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<ICollection>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: FormData) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number | string; data: FormData }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (data: ICollection) => createAction(EDIT_SUCCESS, data),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  clearDelCollection: () => createAction(CLEAR_DEL_COLLECTION),
  delCollectionRequest: (payload: number) => createAction(DEL_COLLECTION_REQUEST, payload),
  delCollectionSuccess: () => createAction(DEL_COLLECTION_SUCCESS),
  delCollectionFail: (payload: string) => createAction(DEL_COLLECTION_FAIL, payload),

  fetchPopularRequest: () => createAction(FETCH_POPULAR_REQUEST),
  fetchPopularSuccess: (payload: IServerResponse<ICollection[]>) =>
    createAction(FETCH_POPULAR_SUCCESS, payload),
  fetchPopularFail: (payload: string) => createAction(FETCH_POPULAR_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga() {
  try {
    const { data }: { data: IServerResponse<ICollection[]> } = yield call(() => getCollections());
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<ICollection> } = yield call(() =>
      getCollection(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: FormData }) {
  try {
    const { data }: { data: IServerResponse<ICollection> } = yield call(() =>
      createCollection(payload)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number | string; data: FormData } }) {
  try {
    const { data }: { data: IServerResponse<ICollection> } = yield call(() =>
      editCollection(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delCollectionSaga({ payload }: { payload: number }) {
  try {
    yield call(() => deleteCollection(payload));
    yield put(actions.delCollectionSuccess());
  } catch (e) {
    yield put(actions.delCollectionFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchPopularSaga() {
  try {
    const { data }: { data: IServerResponse<ICollection[]> } = yield call(() =>
      geteCollectionsByPlace('popular')
    );
    yield put(actions.fetchPopularSuccess(data));
  } catch (e) {
    yield put(actions.fetchPopularFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchPopularRequest>>(
    FETCH_POPULAR_REQUEST,
    fetchPopularSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.delCollectionRequest>>(
    DEL_COLLECTION_REQUEST,
    delCollectionSaga
  );
}
