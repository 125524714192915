import { createStyles, makeStyles } from '@material-ui/core';
import { colors } from '../../../../../../../constants/colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    reviews: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column-reverse',
        padding: '0 16px',
      },
    },
    reviewsList: {
      width: '70%',
      paddingRight: 32,
      [theme.breakpoints.down(500)]: {
        marginTop: 24,
        width: '100%',
        paddingRight: 0,
      },
    },
    statistics: {
      width: '30%',
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
    },
    statisticsHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colors.primary.lightGrey}`,

      marginBottom: 22,
      [theme.breakpoints.down(500)]: {
        alignItems: 'center',

        '& p': {
          fontSize: 23,
        },
      },
    },
    headerTitle: {
      fontSize: 47,
      padding: 0,
      margin: '65px 0 32px',
      fontWeight: 600,
      [theme.breakpoints.down(500)]: {
        fontSize: '23px !important',
        margin: '24px 0 !important',
        padding: '0 16px',
      },
    },
    avatar: {
      width: 50,
      height: 50,

      marginRight: 8,

      '& img': {
        borderRadius: '50%',
        width: '100%',
        objectFit: 'contain',
        height: '100%',
      },
    },
    feedButton: {
      margin: '16px 0',
    },
    statisticsList: {
      marginBottom: 8,
    },
    statisticsItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,
      whiteSpace: 'nowrap',

      '& .progress': {
        /* Primary colors/Green */
        background: colors.primary.lightGrey,
        borderRadius: 12,
        height: 5,
        flex: 1,
        overflow: 'hidden',
        margin: '0 16px',
        '& span': {
          display: 'inline-block',
          height: '100%',
          background: colors.primary.green,
        },
      },
      [theme.breakpoints.down(500)]: {
        '& p:first-child': {
          marginRight: `16px !important`,
          color: 'red',
        },
        '& p:last-child': {
          marginLeft: `16px !important`,
          color: 'red',
        },
      },
    },
  })
);
