import React, { FC } from 'react';
import { Text, TextType } from '../../../../../../components/base/Text';
import { useStyles } from '../../hooks/useStyles';

interface IHeaderProps {
  title: string;
}

export const Header: FC<IHeaderProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Text type={TextType.boldH4}>{props.title}</Text>
    </div>
  );
};
