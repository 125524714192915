import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../constants/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&:hover > div ': {
      backgroundColor: colors.complementary.green + 'AF',
    },

    '& > div': {
      width: 18,
      height: 18,
      border: `1px solid ${colors.primary.green}`,
      // marginRight: 8,
      position: 'relative',
      borderRadius: 2,
      overflow: 'hidden',
      '& > span': {
        position: 'absolute',
        zIndex: 2,
        left: 2,
        top: 2,
        width: 12,
        height: 12,
        borderRadius: 2,
        transition: '0.15s ease-in-out',
        transform: `scale(0.2)`,
      },

      '&.active > span': {
        backgroundColor: colors.primary.green,
        transform: `scale(1)`,
      },
    },
  },
}));
