import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { IEditWorkingTime, IStore } from '../../interfaces/store';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import {
  getStores,
  getStoreById,
  editStore,
  createStore,
  deleteStore,
  setWorkingTime,
  setDeliveryZone,
} from '../../crud/store.crud';
import { IServerResponse } from '../../interfaces/server';

const FETCH_REQUEST = 'stores/FETCH_REQUEST';
const FETCH_SUCCESS = 'stores/FETCH_SUCCESS';
const FETCH_FAIL = 'stores/FETCH_FAIL';
const SET_EMPTY = 'stores/SET_EMPTY';
const CLEAR_STORES = 'stores/CLEAR_STORES';

const FETCH_BY_ID_REQUEST = 'stores/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'stores/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'stores/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'stores/CLEAR_EDIT';
const ADD_REQUEST = 'stores/ADD_REQUEST';
const EDIT_REQUEST = 'stores/EDIT_REQUEST';
const EDIT_SUCCESS = 'stores/EDIT_SUCCESS';
const EDIT_FAIL = 'stores/EDIT_FAIL';

const DEL_REQUEST = 'stores/DEL_REQUEST';
const DEL_SUCCESS = 'stores/DEL_SUCCESS';
const DEL_FAIL = 'stores/DEL_FAIL';
const CLEAR_DEL = 'stores/CLEAR_DEL';

const SET_WORKING_TIME_REQUEST = 'stores/SET_WORKING_TIME_REQUEST';
const SET_WORKING_TIME_SUCCESS = 'stores/SET_WORKING_TIME_SUCCESS';
const SET_WORKING_TIME_FAIL = 'stores/SET_WORKING_TIME_FAIL';
const CLEAR_SET_WORKING_TIME = 'stores/CLEAR_SET_WORKING_TIME';

const SEND_DELIVERY_ZONE = 'stores/SEND_DELIVERY_ZONE';
const SEND_DELIVERY_ZONE_SUCCESS = 'stores/SEND_DELIVERY_ZONE_SUCCESS';
const SEND_DELIVERY_ZONE_ERR = 'stores/SEND_DELIVERY_ZONE_ERR';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  stores: IStore[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  store: IStore | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delLoading: boolean;
  delSuccess: boolean;
  delError: string | null;

  setWorkingTimeLoading: boolean;
  setWorkingTimeSuccess: boolean;
  setWorkingTimeError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  stores: undefined,
  loading: false,
  success: false,
  error: null,

  store: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delLoading: false,
  delSuccess: false,
  delError: null,

  setWorkingTimeLoading: false,
  setWorkingTimeSuccess: false,
  setWorkingTimeError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'stores', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, stores: [] };
      }

      case CLEAR_STORES: {
        return { ...state, stores: undefined };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          stores: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          stores: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          store: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          store: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          store: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
          delError: null,
        };
      }

      case DEL_REQUEST: {
        return { ...state, delLoading: true, delSuccess: false, delError: null };
      }

      case DEL_FAIL: {
        return { ...state, delError: action.payload };
      }

      case DEL_SUCCESS: {
        return { ...state, delSuccess: true, delLoading: false };
      }

      case CLEAR_DEL: {
        return { ...state, delLoading: false, delSuccess: false, delError: null };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, store: action.payload.data };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_SET_WORKING_TIME: {
        return {
          ...state,
          setWorkingTimeLoading: false,
          setWorkingTimeSuccess: false,
          setWorkingTimeError: null,
        };
      }

      case SET_WORKING_TIME_REQUEST: {
        return {
          ...state,
          setWorkingTimeLoading: true,
          setWorkingTimeSuccess: false,
          setWorkingTimeError: null,
        };
      }

      case SET_WORKING_TIME_SUCCESS: {
        return {
          ...state,
          setWorkingTimeLoading: false,
          setWorkingTimeSuccess: true,
          store: action.payload.data,
        };
      }

      case SET_WORKING_TIME_FAIL: {
        return { ...state, setWorkingTimeLoading: false, setWorkingTimeError: action.payload };
      }
      case SEND_DELIVERY_ZONE: {
        return { ...state, deliveryZoneLoading: true, deliveryZoneSuccess: false, deliveryZoneErr: false };
      }
      case SEND_DELIVERY_ZONE_SUCCESS: {
        return { ...state, deliveryZoneLoading: false, deliveryZoneSuccess: true, deliveryZoneErr: false, store: action.payload };
      }
      case SEND_DELIVERY_ZONE_ERR: {
        return { ...state, deliveryZoneLoading: false, deliveryZoneSuccess: false, deliveryZoneErr: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number; companyId?: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IStore[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  setEmpty: () => createAction(SET_EMPTY),
  clearStores: () => createAction(CLEAR_STORES),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IStore>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { data: IStore }) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IStore }) => createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: IServerResponse<IStore>) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  delRequest: (payload: { id: number; page: number; perPage: number; companyId?: number }) =>
    createAction(DEL_REQUEST, payload),
  delFail: (payload: string) => createAction(DEL_FAIL, payload),
  delSuccess: () => createAction(DEL_SUCCESS),
  clearDel: () => createAction(CLEAR_DEL),

  setWorkingTimeRequest: (payload: { id: number; data: IEditWorkingTime }) =>
    createAction(SET_WORKING_TIME_REQUEST, payload),
  setWorkingTimeFail: (payload: string) => createAction(SET_WORKING_TIME_FAIL, payload),
  setWorkingTimeSuccess: (payload: IServerResponse<IStore>) =>
    createAction(SET_WORKING_TIME_SUCCESS, payload),
  clearSetWorkingTime: () => createAction(CLEAR_SET_WORKING_TIME),

  sendDeliveryZone: (payload: { id: number; data: any }) => createAction(SEND_DELIVERY_ZONE, payload),
  setDeliveryZoneSuccess: (payload: IServerResponse<IStore>) => createAction(SEND_DELIVERY_ZONE_SUCCESS, payload),
  setDeliveryZoneErr: (payload: any) => createAction(SEND_DELIVERY_ZONE_ERR, payload),

};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: { page: number; perPage: number; companyId?: number };
}) {
  try {
    const { data }: { data: IServerResponse<IStore[]> } = yield call(() =>
      getStores(payload.page, payload.perPage, payload.companyId)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IStore> } = yield call(() => getStoreById(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: { data: IStore; company?: null | { id: number } } }) {
  try {
    const { data }: { data: IServerResponse<IStore> } = yield call(() => createStore(payload.data));
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IStore } }) {
  try {
    const { data }: { data: IServerResponse<IStore> } = yield call(() =>
      editStore(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* setWorkingTimeSaga({ payload }: { payload: { id: number; data: IEditWorkingTime } }) {
  try {
    const { data }: { data: IServerResponse<IStore> } = yield call(() =>
      setWorkingTime(payload.id, payload.data)
    );
    yield put(actions.setWorkingTimeSuccess(data));
  } catch (e) {
    yield put(actions.setWorkingTimeFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delSaga({
  payload,
}: {
  payload: { id: number; page: number; perPage: number; companyId?: number };
}) {
  try {
    yield call(() => deleteStore(payload.id));
    yield put(actions.delSuccess());
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        companyId: payload.companyId,
      })
    );
  } catch (e) {
    yield put(actions.delFail(e?.response?.data?.message || 'Network error'));
  }
}
function* deliveryZoneSaga({
  payload,
}: {
  payload: { id: number; data: any };
}) {
  try {
    const { data }: { data: any } = yield call(() =>
      setDeliveryZone(payload.id, payload.data)
    );
    yield put(actions.setDeliveryZoneSuccess(data.data));
  } catch (e) {
    yield put(actions.setDeliveryZoneErr(e?.response));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DEL_REQUEST, delSaga);
  yield takeLatest<ReturnType<typeof actions.setWorkingTimeRequest>>(
    SET_WORKING_TIME_REQUEST,
    setWorkingTimeSaga
  );
  yield takeLatest<ReturnType<typeof actions.sendDeliveryZone>>(SEND_DELIVERY_ZONE, deliveryZoneSaga);

}
