import { createStyles, makeStyles } from '@material-ui/core';
import { colors } from '../../../../constants/colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    tabs: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 24,

      '& p': {
        cursor: 'pointer',
        borderBottom: `2px solid ${colors.primary.white}`,
        transition: '0.3s ease-out',
        color: `${colors.primary.dark} !important`,

        '&.active': {
          color: colors.primary.black,
          borderBottomColor: colors.primary.black,
        },
      },
    },
    pageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    logo: {
      marginTop: 32,
      width: 250,
      height: 66,
    },
    image: {
      height: '70vh',
      marginRight: 128,
      [theme.breakpoints.down('lg')]: {
        height: '50vh',
        width: 'auto',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
        height: 542,
        width: 500,
      },
      [theme.breakpoints.down('xs')]: {
        height: 372,
        width: 343,
      },
    },
    routes: {
      width: 400,
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        padding: '0 16px',
        marginTop: 40,
      },
    },
  })
);
